import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatwegive',
  templateUrl: './whatwegive.component.html',
  styleUrls: ['./whatwegive.component.css']
})
export class WhatwegiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
