import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CourseService } from './../../../services/course.service';
import {AuthService } from './../../../services/auth.service';
import { MessageModle } from './../../../models/MessageModle';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

declare var $: any;
@Component({
  selector: 'message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css'],
})
export class MessageModalComponent implements OnInit {
  @Input() id;
  @Input() type;
  modelTitle = 'Send Message to class';
  messageType = ['SMS-Subscription', 'SMS-Gateway', 'Email', 'Both'];
  selectedType;

  form = new FormGroup({
    subject: new FormControl('', Validators.required),
    Message: new FormControl('', Validators.required),
  });
  constructor(
    private courseSer: CourseService,
    private authSer: AuthService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.selectedType = 'SMS-Subscription';
  }
  get subjectRef() {
    return this.form.get('subject');
  }
  get MessageRef() {
    return this.form.get('Message');
  }

  changeMessageType(item) {
    this.selectedType = item;
  }

  submitform() {
    this.spinnerService.show();
    const inputModle = new MessageModle();
    inputModle.via = this.selectedType;
    inputModle.subject = this.subjectRef.value;
    inputModle.message = this.MessageRef.value;
    if (this.type === 'class') {
      this.courseSer.sendMessage(this.id, inputModle).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.alertService.success('Success.');
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger('Error occurred while sending message');
        }
      );
    } else if (this.type === 'student') {
      this.authSer.sendMessage(this.id, inputModle).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.alertService.success('Success.');
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger('Error occurred while sending message');
        }
      );
      this.spinnerService.hide();
    }
    $('#messageModal').modal('hide');
  }
}
