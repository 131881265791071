export class NoticeModle {
  class_id: string;
  section_id: string;
  session_id: string;
  vertical_loc: number;
  horizontal_loc: number;
  show: boolean;
  name: string;

  constructor(classId, sectionId, sessionId, verticlaLoc) {
    this.class_id = classId;
    this.section_id = sectionId;
    this.session_id = sessionId;
    this.vertical_loc = verticlaLoc;
    this.horizontal_loc = 1;
    this.show = true;
  }
}
