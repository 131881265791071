import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ShowPipe",
})
export class ShowPipe implements PipeTransform {
  transform(components: any): any {
    if (!components) return components;
    return components.filter(function (component) {
      return component.show;
    });
  }
}
