import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { PaymentService } from '.././../../services/payment.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {url} from 'src/environments/url';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.css']
})
export class PaymentPageComponent implements OnInit {
  constructor(private paymentSer: PaymentService, private alertService: AlertService) { }

  get firstNameRef() {
    return this.form.get('firstName');
  }
  get lastNameRef() {
    return this.form.get('lastName');
  }
  get addressRef() {
    return this.form.get('address');
  }
  get cityRef() {
    return this.form.get('city');
  }
  get countryRef() {
    return this.form.get('country');
  }
  get emailRef() {
    return this.form.get('email');
  }
  get phoneRef() {
    return this.form.get('phone');
  }
  @ViewChild('paytmForm', { read: ElementRef }) paytmForm: ElementRef;
  @Input() classId: string;
  @Input() productId: string;
  @Input() userId: string;
  @Output() changeOrder = new EventEmitter();
  orderDetails = {
    items: '',
    return_url: '',
    cancel_url: '',
    notify_url: '',
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    phone: '',
    email: '',
    country: '',
    merchant_id: '',
    currency: '',
    amount: '',
    order_id: '',
  };
  base_url = 'https://metaspace.edu.lk';
  // base_url = 'http://localhost:4200';
  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
  });

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.productId) {
      this.paymentSer.placeOrder(this.classId, this.userId, this.productId).subscribe(success => {
        if (success['data']['payment']['paid_status']) {
          this.alertService.success('You have already paid for this product.\nIf you cannot access the course please contact the institute');
        } else {
          this.orderDetails.merchant_id = success['data']['merchant_id'];
          this.orderDetails.order_id = success['data']['payment']['_id'];
          this.orderDetails.items = success['data']['product']['name'];
          this.orderDetails.currency = success['data']['product']['currency'];
          this.orderDetails.amount = success['data']['product']['amount'];
          this.orderDetails.first_name = success['data']['customer']['first_name'];
          this.orderDetails.last_name = success['data']['customer']['last_name'];
          this.orderDetails.address = success['data']['customer']['address']['lines'];
          this.orderDetails.city = success['data']['customer']['address']['city'];
          this.orderDetails.country = 'Sri Lanka';
          this.orderDetails.phone = success['data']['customer']['telephone']['number'];
          this.orderDetails.cancel_url = this.base_url + '/studentDashboard/course/' + this.classId;
          this.orderDetails.notify_url = this.base_url + '/metapi/courses/paymentNotify';
          this.orderDetails.return_url = this.base_url + '/studentDashboard/course/' + this.classId;
          this.firstNameRef.setValue(this.orderDetails.first_name);
          this.lastNameRef.setValue(this.orderDetails.last_name);
          this.phoneRef.setValue(this.orderDetails.phone);
          this.emailRef.setValue(this.orderDetails.email);
          this.addressRef.setValue(this.orderDetails.address);
          this.cityRef.setValue(this.orderDetails.city);
          this.countryRef.setValue(this.orderDetails.country);
        }
        }, (error) => {
          this.alertService.danger(error.error.message);
        }
      );
    }
  }

  pay() {
    this.changeOrder.emit();
    console.log(this.orderDetails);
    this.paytmForm.nativeElement.submit().subscribe(success => {
      console.log(success);
    });
  }

}
