import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {url} from '../../environments/url';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  public get_header() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    });
    return headers;
  }

  public get_payhere_header(token) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + token
    });
    return headers;
  }

  getPayhereStatus(orderId, token) {
    return this.http.get('https://www.payhere.lk/merchant/v1/payment/search?order_id=' + orderId,
      { headers: this.get_payhere_header(token) }).pipe(map(res => res));
  }
  authenticatePayhere () {
    return this.http.post('https://www.payhere.lk/merchant/v1/oauth/token', {
      'grant_type': 'client_credentials'
    }, { headers: {
        'Authorization': 'Basic NE9WeDNDakFCRlk0SjlMZzZkZjVWbzNUWTo0OFZZRXpUVHF1QjRwNVY3WmQ3dTVCOExTVk5IZ3gxZ1Y0S0FXOGdYSGZVbw==',
        'Content-Type': 'application/x-www-form-urlencoded'
      } }).pipe(map(res => res));
  }
  getClassMonths(classId, userId) {
    return this.http
      .get(url + '/courses/getClassMonths/' + classId.toString() + '/' + userId.toString(), {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  public placeOrder(classId, userId, productId) {
    return this.http.post(url + '/courses/placeOrder', {class_id: classId, user_id: userId, product_id: productId}, {
      headers: this.get_header(),
    })
      .pipe(map(res => res));
  }

  public getPaymentStatus(orderId) {
    return this.http.get(url + '/courses/getPaymentStatus/' + orderId.toString(), {
      headers: this.get_header(),
    })
      .pipe(map(res => res));
  }

  public getPaymentDetails(classId, userId) {
    return this.http.get(url + '/courses/getPayments/' + classId.toString() + '/' + userId.toString(), {
      headers: this.get_header(),
    })
      .pipe(map(res => res));
  }
}
