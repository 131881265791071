export class ForumEditModle {
  component_id: string;
  vertical_loc: number;
  horizontal_loc: number;
  show: boolean;
  name: string;
  description: string;
  files: string[];

  constructor(componentId, verticlaLoc, horizontalLoc, show) {
    this.component_id = componentId;
    this.vertical_loc = verticlaLoc;
    this.horizontal_loc = horizontalLoc;
    this.show = show;
    this.files = [];
  }
}
