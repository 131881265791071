import { Component, OnInit } from '@angular/core';
import { ClassService } from 'src/app/services/class.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'ngx-alerts';
import { AuthService } from 'src/app/services/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {any} from 'codelyzer/util/function';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.scss']
})
export class AddStudentComponent implements OnInit {
  focus: any;
  selectedValue = 'bySub';
  searchTxt = '';
  username = '';
  role = '';
  profile_pic = 'add-student-avatar.jpg'; // default prof pic
  classes = [];
  batches = [];
  admissionDate = '';
  classes_ = [];
  /* data tabel for classes */
  rows = [];
  rows_ = [];
  selected = [];
  selected_ = [];
  selected__ = [];
  editing = {};
  /*************/

  personalInfo = { firstName: '', lastName: '', middleName: '', address: '', city: '', telephone: '', mobilePhone: true, birthday: '',
    email: '', gender: 'Male'};
  guardinaInfo = { name: '', mobile: '', land: ''};
  selected_batch = '';
  selected_stream = '';
  card_number = '';
  streams = [];
  // tslint:disable-next-line:max-line-length
  constructor(private classSer: ClassService, config: NgbModalConfig, private modalService: NgbModal, private alertService: AlertService, private auth: AuthService, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.getClasses();
    this.getBatch();
    this.getStreams();
  }
  getStreams() {
    this.streams = [];
    this.classSer.getStreams().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.streams.push(data['data'][i]);
      }
      console.log(this.streams);
    }, error => {
      console.log(error);
    });
  }
  open(content) {
    this.modalService.open(content);
  }
  openBatch(content) {
    this.modalService.open(content);
  }
  removeClass() {
    if (this.selected__['_id']) {
      for (let i = 0; i < this.classes_.length; i++) {
        if (this.classes_[i]['_id'] === this.selected__['_id']) {
          this.classes_.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i]['_id'] === this.selected__['_id']) {
          this.selected.splice(i, 1);
          break;
        }
      }
      this.rows_ = [];
      for (let i = 0; i < this.classes_.length; i++) {
        this.rows_.push(this.classes_[i]);
      }

    } else {
      this.alertService.warning('Please Select a Class to Remove');
    }
  }
  addClasses() {
    for (let i = 0; i < this.selected.length; i++) {
      let t = true;
      for (let j = 0; j < this.classes_.length; j++) {
        if (this.classes_[j]['_id'] === this.selected[i]['_id']) {
          t = false;
          break;
        }
      }
      if (t) {
        this.classes_.push(this.selected[i]);
      }
    }
    this.rows_ = [];
    let ele;
    for (let i = 0; i < this.classes_.length; i++) {
      ele = this.classes_[i];
      ele.fees_rate = 1;
      console.log(ele);
      this.rows_.push(ele);
    }
    this.modalService.dismissAll();
  }

  addStudent() {
    if (this.personalInfo.firstName === '' || this.personalInfo.lastName === '' || this.personalInfo.telephone === '' ||
      this.personalInfo.birthday === '' || this.personalInfo.address === '' || this.admissionDate  === '' || this.selected_batch === '' ||
      this.selected_stream === '') {
      this.alertService.danger('Fill All the Required Fields!');
    } else if (this.rows_.length === 0) {
      this.alertService.danger('Please Assign to at least One Class');
    } else if (this.card_number) {
      if (this.card_number.length !== 10) {
        this.alertService.danger('Invalid card number');
      }
    } else {
      this.spinnerService.show();
      // tslint:disable-next-line:max-line-length
      this.auth.addStudent(this.admissionDate, this.personalInfo, this.guardinaInfo, this.selected_batch, this.selected_stream, this.card_number, this.rows_)
        .subscribe(success => {
          this.personalInfo = { firstName: '', lastName: '', middleName: '', address: '', city: 'Matara', telephone: '', mobilePhone: true,
            birthday: '', email: '', gender: 'Male'};
          this.guardinaInfo = { name: '', mobile: '', land: '' };
          // this.selected_batch = '';
          // this.rows_ = [];
          // this.admissionDate = '';
          this.card_number = '';
          this.spinnerService.hide();
          this.alertService.success(success['message']);
        }, error => {
          console.log(error);
          this.spinnerService.hide();
          this.alertService.danger(error['error']['message']);
        });
    }
  }
  selectClass({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  selectClassAdded(t) {
    this.selected__ = t.selected[0];
  }

  /* guardian details select same*/
  // sameAddress() {
  //   if (this.personalInfo.address !== '') {
  //     this.guardinaInfo.address = this.personalInfo.address;
  //   } else {
  //     this.alertService.danger('Please Select a Address in Personal Information');
  //   }
  // }
  // sameCity() {
  //   if (this.personalInfo.city !== '') {
  //     this.guardinaInfo.city = this.personalInfo.city;
  //   } else {
  //     this.alertService.danger('Please Select a City in Personal Information');
  //   }
  // }
  sameTelephone() {
    if (this.personalInfo.telephone !== '') {
      this.guardinaInfo.mobile = this.personalInfo.telephone;
    } else {
      this.alertService.danger('Please Enter a Telephone in Personal Information');
    }
  }
  // sameEmergency() {
  //   if (this.guardinaInfo.telephone !== '') {
  //     this.guardinaInfo.emergency = this.guardinaInfo.telephone;
  //   } else {
  //     this.alertService.danger('Please Select a Telephone in Guardian Information');
  //   }
  // }
  /******************************/

  getClasses() {
    this.classes = [];
    this.selected = [];
    this.classSer.getClasses().subscribe(success => {
      console.log(success['data']);
      for (let i = 0; i < success['data'].length; i++) {
        this.classes.push(success['data'][i]);
      }
      this.rows = this.classes;
      console.log(this.classes);
    }, error => {
      console.log(error);
    });
  }
  getBatch() {
    this.batches = [];
    this.classSer.getBatches().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.batches.push(data['data'][i]);
      }
      console.log(this.batches);
    }, error => {
      console.log(error);
    });
  }
  search() {
    console.log(this.selectedValue);
    if (this.searchTxt !== '') {
      this.classes = [];
      this.selected = [];
      this.rows = [];
      if (this.selectedValue === 'bySub') {
        this.classSer.getClassesBySubject(this.searchTxt).subscribe(success => {
          for (let i = 0; i < success['data'].length; i++) {
            this.classes.push(success['data'][i]);
          }
          this.rows = this.classes;
        }, error => {
          console.log(error);
        });
      } else if (this.selectedValue === 'byTeacher') {
        this.classSer.getClassesByTeacher(this.searchTxt).subscribe(success => {
          for (let i = 0; i < success['data'].length; i++) {
            this.classes.push(success['data'][i]);
          }
          this.rows = this.classes;
        }, error => {
          console.log(error);
        });
      } else if (this.selectedValue === 'byBatch') {
        this.classSer.getClassesByBatch(this.searchTxt).subscribe(success => {
          for (let i = 0; i < success['data'].length; i++) {
            this.classes.push(success['data'][i]);
          }
          this.rows = this.classes;
        }, error => {
          console.log(error);
        });
      }
    } else {
      this.getClasses();
    }
  }

  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows_[rowIndex][cell] = event.target.value;
    this.rows_ = [...this.rows_];
  }
}
