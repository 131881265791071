import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  enable_register = false;
  enable_mobile = true;
  registered = false;
  username = '';
  message = 'Congratulations! You successfully registered as a student in Metaspace.';
  user = {first_name: '', last_name: '', gender: 'Male', password: '', retype: '', mobile: undefined, otp_code: '', otp_id: '',
    type: 'student'};
  recaptcha: any[];

  constructor(private router: Router, private auth: AuthService, private alertService: AlertService,
              private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
  }

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
  }

  login() {
    this.router.navigate(['login']);
  }
  onChangeItem(type) {
    console.log(this.user.type);
  }
  change_mobile() {
    this.enable_register = false;
    this.user = {first_name: this.user.first_name, last_name: this.user.last_name, gender: this.user.gender, password: '', retype: '',
      mobile: undefined, otp_code: '', otp_id: '', type: 'student'};
  }
  register() {
    this.spinnerService.show();
    if (this.user.password !== this.user.retype) {
      this.alertService.danger('Re-type password does not match');
      this.spinnerService.hide();
    } else {
      this.auth.register(this.user.first_name, this.user.last_name, this.user.password, '0' + this.user.mobile.toString(),
        this.user.otp_id, this.user.otp_code, this.user.type).subscribe(Sucess => {
        if (this.user.type === 'teacher') {
          this.message = 'Congratulations! You successfully registered as a teacher in Metaspace.';
        }
        this.user = {first_name: '', last_name: '', gender: 'Male', password: '', retype: '', mobile: undefined, otp_code: '', otp_id: '',
          type: 'student'};
        this.spinnerService.hide();
        this.alertService.success(Sucess['message'] + '\nYour Username is ' + Sucess['data']['username']);
        this.username = Sucess['data']['username'];
        this.registered = true;
      }, error => {
        this.user = {first_name: '', last_name: '', gender: 'Male', password: '', retype: '', mobile: undefined, otp_code: '', otp_id: '',
          type: 'student'};
        this.enable_register = false;
        this.enable_mobile = true;
        this.spinnerService.hide();
        this.alertService.danger(error['error']['message']);
        console.log(error);
      });
    }
  }

  send_otp() {
    if (this.recaptcha === undefined || this.recaptcha === null) {
      this.alertService.danger('Please select reCAPTCHA');
      return;
    }

    this.spinnerService.show();
    if (this.user.mobile && ('0' + this.user.mobile.toString()).length === 10) {
      this.auth.send_otp('0' + this.user.mobile.toString(), this.recaptcha, 'Registration').subscribe(sucess => {
        this.enable_mobile = false;
        console.log(sucess);
        this.user.otp_id = sucess['data']['otp_id'];
        this.enable_register = true;
        this.alertService.success(sucess['message']);
        this.spinnerService.hide();
      }, error => {
        this.enable_mobile = true;
        this.alertService.danger(error['error']['message']);
        this.spinnerService.hide();
      });
    } else {
      this.alertService.danger('Please enter a valid mobile number');
      this.spinnerService.hide();
    }
  }
}
