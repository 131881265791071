import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { NavbarComponent } from './navbar/navbar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardAdminComponent } from './dashboard/dashboard-admin/dashboard-admin.component';
import { CoursesComponent } from './courses/courses.component';
import { TeachersComponent } from './teachers/teachers.component';
import { FooterComponent } from './footer/footer.component';

// Moodle additions
import { AdminAndTeacherGuardService } from './services/admin-and-teacher-guard.service';
import { MessageLogPageComponent } from './dashboard/course-item/message-log-page/message-log-page.component';
import { AssignmentSubmissionComponent } from './dashboard/assignment/assignment-submission/assignment-submission.component';
import { ForumPageComponent } from './dashboard/forum/forum-page/forum-page.component';
import { GradeAssignmentComponent } from './dashboard/assignment/grade-assignment/grade-assignment.component';
import { ViewSubmissionComponent } from './dashboard/assignment/view-submission/view-submission.component';
import { AssignmentPageComponent } from './dashboard/assignment/assignment-page/assignment-page.component';
import { CourseStudentComponent } from './dashboard/course-student/course-student.component';
import { CourseComponent } from './dashboard/course/course.component';
import { CourseMeetingComponent} from './dashboard/course-meeting/course-meeting.component';
import { CourseQuizComponent} from './dashboard/course-quiz/course-quiz.component';


import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { EditProfileComponent } from './dashboard/edit-profile/edit-profile.component';
import { AddStudentComponent } from './dashboard/add-student/add-student.component';
import { SearchStudentComponent } from './dashboard/search-student/search-student.component';
import { ViewProfileStudentComponent } from './dashboard/view-profile-student/view-profile-student.component';
import { AddTeacherComponent } from './dashboard/add-teacher/add-teacher.component';
import { SearchTeacherComponent } from './dashboard/search-teacher/search-teacher.component';
import { ViewProfileTeacherComponent } from './dashboard/view-profile-teacher/view-profile-teacher.component';
import { AddAssistantComponent } from './dashboard/add-assistant/add-assistant.component';
import { SearchAssistantComponent } from './dashboard/search-assistant/search-assistant.component';
import { ViewProfileAssistantComponent } from './dashboard/view-profile-assistant/view-profile-assistant.component';
import { ViewClassesAssistantComponent } from './dashboard/view-classes-assistant/view-classes-assistant.component';
import { AddClassComponent } from './dashboard/add-class/add-class.component';
import { SearchClassComponent } from './dashboard/search-class/search-class.component';
import { EditClassComponent } from './dashboard/edit-class/edit-class.component';
import { EditStudentClassesComponent } from './dashboard/edit-student-classes/edit-student-classes.component';
import { AssignStudentClassesComponent } from './dashboard/assign-student-classes/assign-student-classes.component';
import { MarkAttendenceComponent } from './dashboard/mark-attendence/mark-attendence.component';
import { GenerateReportsComponent } from './dashboard/generate-reports/generate-reports.component';
import { FrontTestComponent } from './front-test/front-test.component';
import { StudentDashboardComponent } from './dashboard/student-dashboard/student-dashboard.component';
import { CoursePageComponent } from './dashboard/course-page/course-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PaymentComponent } from './dashboard/payment/payment.component';
import { PaymentResultComponent } from './dashboard/payments/payment-result/payment-result.component';
import { CourseSearchComponent } from './course-search/course-search.component';
// import { McqPageComponent } from './dashboard/mcq-page/mcq-page.component';
import { BasicInformationComponent} from './dashboard/basic-information/basic-information.component';

import { MCQSearchComponent } from './dashboard/MCQ/mcq-search/mcq-search.component';
import { McqPageComponent} from './dashboard/MCQ/mcq-page/mcq-page.component';


const routes: Routes = [{ path: '', component: HomepageComponent },
{ path: 'login', component: LoginComponent },
{ path: 'teachers', component: TeachersComponent },
{ path: 'courses/:condition', component: CourseSearchComponent },
{ path: 'footer', component: FooterComponent },
{ path: 'register', component: RegisterComponent },
{ path: 'dashboard', component: DashboardAdminComponent, canActivate: [AuthGuard] },
{ path: 'editprofile', component: EditProfileComponent, canActivate: [AuthGuard] },
{ path: 'addStudent', component: AddStudentComponent, canActivate: [AuthGuard] },
{ path: 'addTeacher', component: AddTeacherComponent, canActivate: [AuthGuard] },
{ path: 'addAssistant', component: AddAssistantComponent, canActivate: [AuthGuard] },
{ path: 'addClass', component: AddClassComponent, canActivate: [AuthGuard] },
{ path: 'searchTeacher', component: SearchTeacherComponent, canActivate: [AuthGuard] },
{ path: 'searchStudent', component: SearchStudentComponent, canActivate: [AuthGuard] },
{ path: 'searchAssistant', component: SearchAssistantComponent, canActivate: [AuthGuard] },
{ path: 'classes', component: SearchClassComponent, canActivate: [AuthGuard] },
{ path: 'viewProfileStudent/:student_id', component: ViewProfileStudentComponent, canActivate: [AuthGuard] },
{ path: 'viewProfileTeacher', component: ViewProfileTeacherComponent, canActivate: [AuthGuard] },
{ path: 'viewProfileAssistant', component: ViewProfileAssistantComponent, canActivate: [AuthGuard] },
{ path: 'viewClassesAssistant', component: ViewClassesAssistantComponent, canActivate: [AuthGuard] },
{ path: 'editClass', component: EditClassComponent, canActivate: [AuthGuard] },
{ path: 'editStudentClass/:student_id', component: EditStudentClassesComponent, canActivate: [AuthGuard] },
{ path: 'assignStudentClass', component: AssignStudentClassesComponent, canActivate: [AuthGuard] },
{ path: 'markAttendence', component: MarkAttendenceComponent, canActivate: [AuthGuard] },
{ path: 'generateReports', component: GenerateReportsComponent, canActivate: [AuthGuard] },
{ path: 'userInfo', component: BasicInformationComponent, canActivate: [AuthGuard] },
{ path: 'test', component: FrontTestComponent, canActivate: [AuthGuard] },
{ path: 'studentDashboard', component: StudentDashboardComponent, canActivate: [AuthGuard] },
// { path: 'studentDashboard/course/meeting/:class_id/:section_name/:session_name/:component_name/:component_id', component:
//   CoursePageComponent, canActivate: [AuthGuard]},
{
  path: 'studentDashboard/course/meeting', component:
    CourseMeetingComponent, canActivate: [AuthGuard]
},
{
  path: 'classes/course/meeting', component:
    CourseMeetingComponent, canActivate: [AuthGuard]
},
{
  path: 'classes/course/meetingPage', component:
    CoursePageComponent, canActivate: [AuthGuard]
},
{ path: 'resetPassword', component: ResetPasswordComponent },
{ path: 'makePayment', component: PaymentComponent },
{
  path: 'classes/course/:class_id',
  component: CourseComponent,
  canActivate: [AuthGuard, AdminAndTeacherGuardService],
},
{
  path: 'studentDashboard/course/:class_id',
  component: CourseStudentComponent,
  canActivate: [AuthGuard],
},
{
  path: 'studentDashboard/course/:class_id/:orderId',
  component: PaymentResultComponent,
  canActivate: [AuthGuard],
},
{
  path:
    'course/:class_id/:section_name/:session_name/:component_name/:component_id',
  component: AssignmentSubmissionComponent,
  canActivate: [AuthGuard],
},
{
  path:
    'course/assignment/:class_id/:section_name/:session_name/:component_name/:component_id',
  component: AssignmentPageComponent,
  canActivate: [AuthGuard, AdminAndTeacherGuardService],
},
{
  path:
    'submission/:submission_type/:class_id/:section_name/:session_name/:component_name/:component_id',
  component: ViewSubmissionComponent,
  canActivate: [AuthGuard, AdminAndTeacherGuardService],
},
{
  path: 'assignment/grade/:component_id/:submission_id/:student_id',
  component: GradeAssignmentComponent,
  canActivate: [AuthGuard, AdminAndTeacherGuardService],
},
{
  path: 'grade/:student_id/:component_id/:submission_type',
  component: GradeAssignmentComponent,
  canActivate: [AuthGuard, AdminAndTeacherGuardService],
},
{
  path:
    'course/forum/:class_id/:section_name/:session_name/:component_name/:component_id',
  component: ForumPageComponent,
  canActivate: [AuthGuard],
},
{
  path: 'course/messages/:class_id',
  component: MessageLogPageComponent,
  canActivate: [AuthGuard, AdminAndTeacherGuardService],
},
// {
//   path: 'mcqPage',
//   component: McqPageComponent,
//   canActivate: [AuthGuard],
// },
  { path: 'studentDashboard/course/meeting', component:
    CourseMeetingComponent, canActivate: [AuthGuard]},
  { path: 'studentDashboard/course/mcq/:class_id/:section_name/:session_name/:component_name/:component_id', component:
    CourseQuizComponent, canActivate: [AuthGuard]},
  { path: 'classes/course/meeting', component:
    CourseMeetingComponent, canActivate: [AuthGuard]},
  { path: 'classes/course/meetingPage', component:
    CoursePageComponent, canActivate: [AuthGuard]},
{ path: 'resetPassword', component: ResetPasswordComponent},
{ path: 'makePayment', component: PaymentComponent },
  {
    path: 'classes/course/:class_id',
    component: CourseComponent,
    canActivate: [AuthGuard, AdminAndTeacherGuardService],
  },
  {
    path: 'studentDashboard/course/:class_id',
    component: CourseStudentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'studentDashboard/course/:class_id/:orderId',
    component: PaymentResultComponent,
    canActivate: [AuthGuard],
  },
  {
    path:
      'course/:class_id/:section_name/:session_name/:component_name/:component_id',
    component: AssignmentSubmissionComponent,
    canActivate: [AuthGuard],
  },
  {
    path:
      'course/assignment/:class_id/:section_name/:session_name/:component_name/:component_id',
    component: AssignmentPageComponent,
    canActivate: [AuthGuard, AdminAndTeacherGuardService],
  },
  {
    path:
      'course/mcq/:class_id/:section_name/:session_name/:component_name/:component_id',
    component: CourseQuizComponent,
    canActivate: [AuthGuard],
  },
  {
    path:
      'submission/:submission_type/:class_id/:section_name/:session_name/:component_name/:component_id',
    component: ViewSubmissionComponent,
    canActivate: [AuthGuard, AdminAndTeacherGuardService],
  },
  {
    path: 'assignment/grade/:component_id/:submission_id/:student_id',
    component: GradeAssignmentComponent,
    canActivate: [AuthGuard, AdminAndTeacherGuardService],
  },
  {
    path: 'grade/:student_id/:component_id/:submission_type',
    component: GradeAssignmentComponent,
    canActivate: [AuthGuard, AdminAndTeacherGuardService],
  },
  {
    path:
      'course/forum/:class_id/:section_name/:session_name/:component_name/:component_id',
    component: ForumPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'course/messages/:class_id',
    component: MessageLogPageComponent,
    canActivate: [AuthGuard, AdminAndTeacherGuardService],
  },
  {
    path: 'mcq/search',
    component: MCQSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'mcq/:mcq_id',
    component: McqPageComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
