import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
@HostListener('window:scroll', ['$event'])
export class HomepageComponent implements OnInit {


  constructor() { }


  ngOnInit() {

  }

  onWindowScroll(e) {
    console.log('Scrolling');
    let element = document.querySelector('.navbar');
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }

}
