export class AddAssignmentSubModle {
  component_id: string;
  student_id: string;
  submission_type: string;
  content: string;
  files: string[];

  constructor() {
    this.files = [];
  }
}
