import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  @ViewChild('paytmForm', { read: ElementRef }) paytmForm: ElementRef;
  show = false;
  // pay_config = {
  //   merchant_id: '1214422',
  //   return_url: 'http://nawashalawa.com',
  //   cancel_url: 'http://nawashalawa.com/makePayment',
  //   notify_url: 'http://nawashalawa.com/courses/payment/notify',
  //   first_name: 'Thirasara',
  //   last_name: 'Ariyarathna',
  //   email: 'thirasara@cse.mrt.ac.lk',
  //   phone: '0714463167',
  //   address: '63/14 sri rathanapala road matara',
  //   city: 'Matara',
  //   country: 'Sri Lanka',
  //   order_id: '123456789',
  //   items: 'Information Technology Class',
  //   currency: 'LKR',
  //   amount: '500',
  // };
  class_id = '';
  pay_config = {
    merchant_id: '',
    return_url: '',
    cancel_url: '',
    notify_url: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    order_id: '',
    items: '',
    currency: '',
    amount: '',
  };

  constructor(private auth: AuthService, private alertService: AlertService,
              private spinnerService: Ng4LoadingSpinnerService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.auth.request_payment().subscribe(Sucess => {
      console.log(Sucess);
      this.pay_config = Sucess['data'];
      this.pay_config.merchant_id = Sucess['data']['merchant_id'];
      this.pay_config.return_url = Sucess['data']['return_url'];
      this.pay_config.cancel_url = Sucess['data']['cancel_url'];
      this.pay_config.notify_url = Sucess['data']['notify_url'];
      this.pay_config.first_name = Sucess['data']['first_name'];
      this.pay_config.last_name = Sucess['data'][''];
      this.pay_config.email = Sucess['data']['last_name'];
      this.pay_config.phone = Sucess['data']['phone'];
      this.pay_config.address = Sucess['data']['address'];
      this.pay_config.city = Sucess['data']['city'];
      this.pay_config.country = Sucess['data']['country'];
      this.pay_config.order_id = Sucess['data']['order_id'];
      this.pay_config.items = Sucess['data']['items'];
      this.pay_config.currency = Sucess['data']['currency'];
      this.pay_config.amount = Sucess['data']['amount'];
    }, error => {
      console.log(error);
      this.alertService.danger(error.error.message);
    });
  }

  // ngAfterContentInit() {
  //
  // }
  pay() {
    this.paytmForm.nativeElement.submit().subscribe(success => {
      console.log(success);
    });
  }

}
