export class SessionModle {
  class_id: string;
  section_id: string;
  _id: string;
  class_fee: number;
  session_fee: number;
  _for: string;
  name: string;
  vertical_loc: number;

  constructor(classId, sectionId, classFee, sessionFee, verticalLoc) {
    this.class_id = classId;
    this.section_id = sectionId;
    this.class_fee = classFee;
    this.session_fee = sessionFee;
    this.vertical_loc = verticalLoc;
  }
}
