import { Component, OnInit, ElementRef, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

@HostListener('window:scroll', ['$event'])

export class NavbarComponent implements OnInit {
  private toggleButton: any;
  private sidebarVisible: boolean;



  constructor(private router: Router, public location: Location, private element: ElementRef) {
    this.sidebarVisible = false;
  }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    // console.log(toggleButton, 'toggle');

    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  scrollToElement(el: string) {
    console.log("scroll to : ", el);
    var scroll_to = document.getElementById(el);
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      this.home();
      setTimeout(() => {
        var scroll_to = document.getElementById(el);
        scroll_to.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }, 500)
    }
    // window.scrollBy(0, -10)
  }

  onWindowScroll(e) {
    let element = document.querySelector('.navbar');
    console.log(window.pageYOffset);
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }

  home() {
    this.router.navigate(['']);
  }

  login() {
    this.router.navigate(['login']);
  }
  // home() {
  //   this.router.navigate(['']);
  // }
  register() {
    this.router.navigate(['register']);
  }
  // courses() {
  //   this.router.navigate(['courses']);
  // }
  // contactus() {
  //   this.router.navigate(['footer']);
  // }
  // teachers() {
  //   this.router.navigate(['teachers']);
  // }
}

