import { EditSubmissionModle } from "./../../../models/EditSubmissionModle";
import { AuthService } from "./../../../services/auth.service";
import { AddAssignmentSubModle } from "./../../../models/AddAssignmentSubModle";
import { FileSystemFileEntry } from "ngx-file-drop";
import { UploadEvent } from "ngx-file-drop";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { AlertService } from "ngx-alerts";
import { CourseService } from "./../../../services/course.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: "assignment-submission",
  templateUrl: "./assignment-submission.component.html",
  styleUrls: ["./assignment-submission.component.css"],
})
export class AssignmentSubmissionComponent implements OnInit {
  classId;
  componentId;
  subject = "Information Technology";
  batch = "2020";
  group = "Theory";
  section = "June";
  session = "June 1 - June 7";
  assignment = "Assignment 1";
  showspinner;
  files = [];
  dropFileStatus;
  assignmentVal;
  assignmentModle;
  description;
  uploadedFiles = [];
  submissionType;
  typeState;
  enabldrop;
  editState;
  student_id;
  remainingTime;
  marks;
  outOf;
  feedback;
  submissionId;
  allowState;

  form = new FormGroup({
    editor: new FormControl("", Validators.required),
  });
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseSer: CourseService,
    private auth: AuthService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _location: Location
  ) {}

  ngOnInit() {
    this.spinnerService.show();
    this.componentId = this.route.snapshot.paramMap.get("component_id");
    this.classId = this.route.snapshot.paramMap.get("class_id");
    this.section = this.route.snapshot.paramMap.get("section_name");
    this.session = this.route.snapshot.paramMap.get("session_name");
    this.assignment = this.route.snapshot.paramMap.get("component_name");
    this.typeState = true;
    this.dropFileStatus = false;
    this.enabldrop = true;
    this.editState = false;
    this.marks = this.outOf = 0;
    this.feedback = "";
    this.getClassInfo();
    this.getStudentProfile();
    this.getAssignment();
  }

  config = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      // remove formatting button
      ["link"], // link and image, video
    ],
  };
  get editorRef() {
    return this.form.get("editor");
  }
  getClassInfo() {
    this.courseSer.getClassInfo(this.classId).subscribe(
      (response) => {
        const responseData = response["data"];
        this.subject = responseData.subject_id.subject;
        this.batch = responseData.batch_id.year;
        this.group = responseData.group_id.group;
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger("Error occurred while getting class Info");
      }
    );
  }
  getStudentProfile() {
    let user_id = localStorage.getItem("user_id");
    this.auth.getStudentProfileByUser(user_id).subscribe(
      (success) => {
        this.student_id = success["data"]["_id"];
        this.getSubmissionById();
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger("Error occurred while getting student Data");
      }
    );
  }
  getAssignment() {
    this.courseSer.getAssignmentView(this.componentId).subscribe(
      (response) => {
        this.description = response["data"].assignment.description;
        response["data"].file_info.forEach((element) => {
          this.uploadedFiles.push(element.file_id);
        });
        this.submissionType = response["data"].assignment.submission_type;
        if (this.submissionType == "Type In") {
          this.enabldrop = false;
          this.dropFileStatus = true;
          this.typeState = true;
        } else if (this.submissionType == "Upload") {
          this.enabldrop = true;
          this.dropFileStatus = false;
          this.typeState = false;
          this.editorRef.disable();
        }
        // let allowDate = new Date(response["data"].assignment.allow_date);
        this.allowState =
          Date.parse(response["data"].assignment.allow_date) < Date.now();
        let dueDate = new Date(response["data"].assignment.due_date);
        this.claculateRemainingTime(new Date(), dueDate);
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger("Error occurred while getting Assignment");
      }
    );
  }
  getSubmissionById() {
    this.spinnerService.show();
    this.courseSer
      .getSubmissionById(this.componentId, this.student_id)
      .subscribe(
        (response) => {
          this.spinnerService.hide();
          let responseData = response["data"].submission;
          if (responseData) {
            this.dropFileStatus = true;
            this.editorRef.setValue(responseData.content);
            response["data"].file_info.forEach((element) => {
              this.files.push(element.file_id);
            });
            this.submissionId = responseData._id;
            this.editState = true;
            if (responseData.graded) {
              this.marks = responseData.marks;
              this.outOf = responseData.out_of;
              this.feedback = responseData.feedback;
            }
          }
        },
        (error) => {
          this.spinnerService.hide();

          this.alertService.danger("Error occurred while getting submission.");
        }
      );
  }
  claculateRemainingTime(today, dueDate) {
    let diffMs = dueDate - today; // milliseconds
    if (diffMs > 0) {
      let diffDays = Math.floor(diffMs / 86400000); // days
      let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      this.remainingTime =
        diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes";
    } else {
      this.remainingTime = "Late submission.";
    }
  }
  backClicked() {
    this._location.back();
  }

  public dropped(event: UploadEvent) {
    let fileCount = event.files.length;

    for (const droppedFile of event.files) {
      // Is it a file?
      this.showspinner = true;
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.courseSer.uploadFile(file).subscribe(
            (respons) => {
              this.files.push(respons["data"].file);

              fileCount -= 1;
              if (fileCount == 0) {
                this.dropFileStatus = true;
                this.showspinner = false;
              }
            },
            (error) => {
              this.showspinner = false;

              this.files = [];
              this.dropFileStatus = false;
              this.alertService.danger("Error occurred while uploading.");
            }
          );
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        this.showspinner = false;
        this.files = [];
        this.dropFileStatus = false;
        this.alertService.danger("empty directories are added.");
      }
    }
  }

  deleteItem(item) {
    this.spinnerService.show();
    this.courseSer.deleteFile(item._id).subscribe(
      (response) => {
        this.spinnerService.hide();

        this.files.splice(this.files.indexOf(item), 1);
        if (this.files.length == 0) {
          this.dropFileStatus = false;
        }
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while deleting.");
      }
    );
  }
  downloadFile(file) {
    this.spinnerService.show();
    this.courseSer.downloadFile(file._id).subscribe(
      (data) => {
        this.spinnerService.hide();
        importedSaveAs(data, file.file_original_name);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while downloading");
      },
      () => console.info("OK")
    );
  }
  saveChanges() {
    this.spinnerService.show();
    if (!this.editState) {
      let uploadModle = new AddAssignmentSubModle();
      uploadModle.component_id = this.componentId;
      uploadModle.student_id = this.student_id;
      uploadModle.submission_type = this.submissionType;
      uploadModle.content = this.editorRef.value;
      this.files.forEach((element) => {
        uploadModle.files.push(element._id);
      });

      this.courseSer.addSubmission(uploadModle).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.editState = true;
          this.submissionId = response["data"]._id;

          this.alertService.success("success.");
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger("Error occurred while submit assignment.");
        }
      );
    } else {
      let updateModle = new EditSubmissionModle();
      updateModle.content = this.editorRef.value;
      this.files.forEach((element) => {
        updateModle.files.push(element._id);
      });

      this.courseSer.updateSubmission(this.submissionId, updateModle).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.alertService.success("success.");
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger("Error occurred while submit assignment.");
        }
      );
    }
  }
  saveAndReturn() {
    this.spinnerService.show();
    if (!this.editState) {
      let uploadModle = new AddAssignmentSubModle();
      uploadModle.component_id = this.componentId;
      uploadModle.student_id = this.student_id;
      uploadModle.submission_type = this.submissionType;
      uploadModle.content = this.editorRef.value;
      this.files.forEach((element) => {
        uploadModle.files.push(element._id);
      });

      this.courseSer.addSubmission(uploadModle).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.editState = true;
          this.submissionId = response["data"]._id;

          this.alertService.success("success.");
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger("Error occurred while submit assignment.");
        }
      );
    } else {
      let updateModle = new EditSubmissionModle();
      updateModle.content = this.editorRef.value;
      this.files.forEach((element) => {
        updateModle.files.push(element._id);
      });

      this.courseSer.updateSubmission(this.submissionId, updateModle).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.alertService.success("success.");
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger("Error occurred while submit assignment.");
        }
      );
    }
    this.editState = false;
    this.router.navigate(["studentDashboard/course", this.classId]);
  }
}
