import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AlertService } from 'ngx-alerts';
import { PaymentService } from '../../../services/payment.service';

@Component({
  selector: 'app-pay-month',
  templateUrl: './pay-month.component.html',
  styleUrls: ['./pay-month.component.css']
})
export class PayMonthComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService, private alertService: AlertService, private paymentSer: PaymentService) { }
  @Input() classId: string;
  @Output() changeMonth = new EventEmitter();
  @Input() classMonths: [any];
  selectedProduct = '';
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  ngOnInit() {}
  getOrderDetails() {
    if (this.selectedProduct !== '') {
      this.changeMonth.emit(this.selectedProduct);
      console.log(this.selectedProduct);
    } else {
      this.alertService.warning('Select a Month');
    }
  }
}
