import { ComponentPath } from './../../../models/ComponentPath';
// import { DataService } from "./../../../services/data.service";
import { ReplyMole } from './../../../models/ReplyModle';
import { CourseService } from './../../../services/course.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';

declare var $: any;
@Component({
  selector: 'forum-page',
  templateUrl: './forum-page.component.html',
  styleUrls: ['./forum-page.component.css'],
})
export class ForumPageComponent implements OnInit {
  classId;
  subject = 'Information Technology';
  batch = '2020';
  group = 'Theory';
  section = 'June';
  session = 'June 1 - June 7';
  forum = 'Assignment 1';
  componentId;
  discussionTopics;
  posts;
  topicVal;
  topicModle;
  replyVal;
  replyModle;
  uploadedFiles = [];
  description;

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService // private data: DataService
  ) {}

  ngOnInit() {
    this.componentId = this.route.snapshot.paramMap.get('component_id');
    this.classId = this.route.snapshot.paramMap.get('class_id');
    this.section = this.route.snapshot.paramMap.get('section_name');
    this.session = this.route.snapshot.paramMap.get('session_name');
    this.forum = this.route.snapshot.paramMap.get('component_name');

    this.getClassInfo();
    this.getForumPosts();
    this.getForum();
  }

  backClicked() {
    this._location.back();
  }
  getClassInfo() {
    this.spinnerService.show();
    this.courseSer.getClassInfo(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        const responseData = response['data'];
        this.subject = responseData.subject_id.subject;
        this.batch = responseData.batch_id.year;
        this.group = responseData.group_id.group;
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting class Info');
      }
    );
  }
  getForum() {
    this.spinnerService.show();
    this.courseSer.getForum(this.componentId).subscribe(
      (response) => {
        this.spinnerService.hide();

        if (response['data'].file_info) {
          response['data'].file_info.forEach((element) => {
            this.uploadedFiles.push(element.file_id);
          });
        }
        this.description = response['data'].forum.description;
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while loading.');
      }
    );
  }

  getForumPosts() {
    this.spinnerService.show();
    this.courseSer.getForumPosts(this.componentId).subscribe(
      (response) => {
        this.discussionTopics = response['data'].discussions;
        this.posts = response['data'].posts;
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting posts.');
      }
    );
  }
  downloadFile(file) {
    this.spinnerService.show();
    this.courseSer.downloadFile(file._id).subscribe(
      (data) => {
        this.spinnerService.hide();
        importedSaveAs(data, file.file_original_name);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while downloading');
      },
      () => console.info('OK')
    );
  }

  addTopic() {
    this.topicVal = '';
    this.topicModle = new Object(this.componentId);

    $('#discussionTopic').modal('show');
  }

  changeTopic($event) {
    if (!this.topicVal) {
      if ($event != 'error') {
        this.getForumPosts();
      }
    } else {
    }
    $('#discussionTopic').modal('hide');
  }
  enableReply(topic) {
    this.replyVal = '';
    this.replyModle = new ReplyMole(this.componentId, topic._id);

    $('#reply').modal('show');
  }
  changeReply($event) {
    if (!this.replyVal) {
      if ($event != 'error') {
        this.getForumPosts();
      }
    } else {
    }
    $('#reply').modal('hide');
  }
}
