import { Component, OnInit } from '@angular/core';
import {AlertService} from 'ngx-alerts';
import {AuthService} from '../services/auth.service';
import {ClassService} from '../services/class.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-course-search',
  templateUrl: './course-search.component.html',
  styleUrls: ['./course-search.component.scss']
})
export class CourseSearchComponent implements OnInit {
  focus: any;
  batches = [];
  subjects = [];
  teachers = [];
  streams = [];
  stream_subjects = [];
  weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  options = [];
  classes = [];
  show_classes = [];
  condition = '';
  keyword = '';
  condition_key = '';
  selectedCategory = '';
  user_id = '';
  student_id = '';
  student;
  constructor(private alertService: AlertService,
              private auth: AuthService,
              private classSer: ClassService,
              private spinnerService: Ng4LoadingSpinnerService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.getClasses();
    this.getBatch();
    this.getStreams();
    this.getStreamSubjects();
    this.getTeachers();
    this.getSubjects();
    this.condition_key = this.route.snapshot.paramMap.get('condition');
    this.selectedCategory = this.route.snapshot.paramMap.get('stream_id');
  }

  getBatch() {
    this.batches = [];
    this.classSer.getVisibleBatches().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.batches.push({_id: data['data'][i]._id, value: data['data'][i].year + ' ' + data['data'][i].category});
      }
    }, error => {
      this.alertService.danger('Error occurred while getting Batches');
    });
  }
  getTeachers() {
    this.teachers = [];
    this.auth.getTeachers().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.teachers.push({_id: data['data'][i]._id, value: data['data'][i].first_name + ' ' + data['data'][i].last_name});
      }
    }, error => {
      this.alertService.danger('Error occurred while getting Teachers');
    });
  }
  getSubjects() {
    this.subjects = [];
    this.classSer.getVisibleSubjects().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.subjects.push({_id: data['data'][i]._id, value: data['data'][i].subject});
      }
    }, error => {
      this.alertService.danger('Error occurred while getting Subjects');
    });
  }
  getStreams() {
    this.streams = [];
    this.classSer.getStreams().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.streams.push({_id: data['data'][i]._id, value: data['data'][i].stream});
      }
    }, error => {
      this.alertService.danger('Error occurred while getting Subjects');
    });
  }
  getStreamSubjects() {
    this.stream_subjects = [];
    this.classSer.getStreamSubjects().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.stream_subjects.push(data['data'][i]);
      }
    }, error => {
      this.alertService.danger('Error occurred while getting Subjects');
    });
  }
  getClasses() {
    this.classes = [];
    this.classSer.getClasses().subscribe(success => {
      this.classes = success['data'];
      if (this.condition_key === 'stream') {
        this.condition = 'byStream';
        this.search();
      } else if (this.condition_key === 'All') {
        this.condition = 'all';
        this.search();
      }
    }, error => {
      this.alertService.danger('Error occurred while getting Classes');
    });
  }
  getOptions(item) {
    this.options = [];
    if (item === 'bySub') {
      for (let i = 0; i < this.subjects.length; i++) {
        this.options.push(this.subjects[i]);
      }
      this.condition = 'bySub';
    } else if (item === 'byTeacher') {
      for (let i = 0; i < this.teachers.length; i++) {
        this.options.push(this.teachers[i]);
      }
      this.condition = 'byTeacher';
    } else if (item === 'byBatch') {
      for (let i = 0; i < this.batches.length; i++) {
        this.options.push(this.batches[i]);
      }
      this.condition = 'byBatch';
    } else if (item === 'byStream') {
      for (let i = 0; i < this.streams.length; i++) {
        this.options.push(this.streams[i]);
      }
      this.condition = 'byStream';
      console.log(this.options);
    } else if (item === 'byEnrol') {
      this.condition = 'enrolled';
      this.search();
    // } else if (item === 'byRecommend') {
    //   this.condition = 'recommend';
    //   this.search();
    } else {
      this.condition = 'all';
      this.search();
    }
  }
  search() {
    this.show_classes = [];
    if (this.condition === 'all') {
      this.keyword = 'All';
      this.show_classes = this.classes;
    } else if (this.condition === 'enrolled') {
      this.keyword = 'Enrolled';
      for (let i = 0; i < this.classes.length; i++) {
        if (this.classes[i]['allow']) {
          this.show_classes.push(this.classes[i]);
        }
      }
    // } else if (this.condition === 'recommend') {
    //   this.keyword = 'Recommended';
    //   for (let i = 0; i < this.classes.length; i++) {
    //     if (this.classes[i]['recommended']) {
    //       this.show_classes.push(this.classes[i]);
    //     }
    //   }
    } else if (this.condition === 'bySub') {
      for (let i = 0; i < this.classes.length; i++) {
        if (this.classes[i]['subject_id']['_id'] === this.selectedCategory) {
          this.show_classes.push(this.classes[i]);
          this.keyword = this.classes[i]['subject_id']['subject'];
        }
      }
    } else if (this.condition === 'byTeacher') {
      for (let i = 0; i < this.classes.length; i++) {
        if (this.classes[i]['teacher_id']['_id'] === this.selectedCategory) {
          this.show_classes.push(this.classes[i]);
          this.keyword = this.classes[i]['teacher_id']['first_name'] + ' ' + this.classes[i]['teacher_id']['last_name'];
        }
      }
    } else if (this.condition === 'byBatch') {
      for (let i = 0; i < this.classes.length; i++) {
        if (this.classes[i]['batch_id']['_id'] === this.selectedCategory) {
          this.show_classes.push(this.classes[i]);
          this.keyword = this.classes[i]['batch_id']['category'] + ' ' + this.classes[i]['batch_id']['category'];
        }
      }
    } else if (this.condition === 'byStream') {
      let eli_subs = [];
      for (let i = 0; i < this.stream_subjects.length; i++) {
        if (this.stream_subjects[i]['stream_id'] === this.selectedCategory) {
          eli_subs.push(this.stream_subjects[i]['subject_id']);
        }
      }
      for (let i = 0; i < this.streams.length; i++) {
        if (this.streams[i]['_id'] === this.selectedCategory) {
          this.keyword = this.streams[i]['value'];
          break;
        }
      }
      console.log(this.stream_subjects);
      for (let i = 0; i < this.classes.length; i++) {
        if (eli_subs.includes(this.classes[i]['subject_id']['_id'])) {
          this.show_classes.push(this.classes[i]);
        }
      }
    }
    this.spinnerService.hide();
  }

}
