import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CourseService } from '../../../services/course.service';
import { AlertService } from 'ngx-alerts';
import {FormGroup, Validators} from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import {
  Component,
  OnInit,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FileSystemFileEntry, UploadEvent, UploadFile } from 'ngx-file-drop';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AnswerFormat, Answers, DateObject, QuizModle} from '../../../models/QuizModle';
declare var $: any;

@Component({
  selector: 'course-quiz-modal',
  templateUrl: './course-quiz-modal.component.html',
  styleUrls: ['./course-quiz-modal.component.css'],
})
export class CourseQuizModalComponent implements OnInit {
  @Input() quizVal: QuizModle;
  @Input() quizModle: QuizModle;
  @Output() changeQuiz = new EventEmitter();
  @Output() changeModalWidth = new EventEmitter();

  modelTitle;
  visibleTypes = ['Paid', 'Free', 'Paid for Class'];
  answerNumberingTypes = [
    {key: '1', value: '1, 2, 3, 4, ...'},
    {key: 'A', value: 'A, B, C, D, ...'},
    {key: 'a', value: 'a, b, c, d, ...'},
    {key: 'I', value: 'I, II, III, IV, ...'},
    {key: 'i', value: 'i, ii, iii, iv, ...'}
  ];
  showMarksTypes = [
    {key: 'answers', value: 'Marks and Answers'},
    {key: 'marks', value: 'Only Marks'},
    {key: 'none', value: 'Nothing'}
  ];
  answerTypes = [
    {key: 'single', value: 'Single Answer'},
    {key: 'each-multiple', value: 'Multiple Answers - Each correct'},
    {key: 'any-multiple', value: 'Multiple Answers - Any correct'}
  ];
  public files: UploadFile[] = [];
  fileRef;
  showSpinner = false;
  addButton = false;
  editButton = false;
  questionsArray = [];
  mcqFile: File;
  destroy$ = new Subject();
  issuesInAnswers = false;
  mcqResult: object;

  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private fb: FormBuilder
  ) {}

  @ViewChild('pdfViewer') public pdfViewer;
  isAnswerMode = false;
  mcqForm: FormGroup;

  ngOnInit() {
    this.mcqForm = this.fb.group({
      name: ['', [Validators.required]],
      enableDateCheck: [false],
      disableDateCheck: [false],
      availability: [false],
      enableDate: [],
      disableDate: [],
      visibleType: [this.visibleTypes[2]],
      amount: [1000, [Validators.min(0), Validators.max(100000)]],
      questionCount: [50, [Validators.required, Validators.min(1), Validators.max(200)]],
      answersPerQuestionCount: [5, [Validators.required, Validators.min(2), Validators.max(6)]],
      noOfAttempts: [3, [Validators.required, Validators.min(1), Validators.max(3)]],
      allowedTime: [{
        'hour': 2,
        'minute': 0
      }],
      answerNumbering: [this.answerNumberingTypes[0].key],
      showMarks: [this.showMarksTypes[2].key]
    });
    this.mcqForm.get('amount').disable();
    this.mcqForm.get('enableDate').disable();
    this.mcqForm.get('disableDate').disable();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!this.quizVal) {
      this.modelTitle = 'Add MCQ';
      this.clearData();
    } else {
      this.modelTitle = 'Edit MCQ';
      this.getMCQ(this.quizVal._id);
    }

    this.setAnswerMode(false);
    this.spinnerService.hide();
    this.mcqForm.get('visibleType').valueChanges.pipe(
        takeUntil(this.destroy$)
    ).subscribe((type: string) => {
      if (type !== this.visibleTypes[0]) {
        this.mcqForm.get('amount').disable();
      } else {
        this.mcqForm.get('amount').enable();
      }
    });
    this.mcqForm.get('enableDateCheck').valueChanges.pipe(
        takeUntil(this.destroy$)
    ).subscribe((value: boolean) => {
      if (!value) {
        this.mcqForm.get('enableDate').disable();
      } else {
        this.mcqForm.get('enableDate').enable();
      }
    });
    this.mcqForm.get('disableDateCheck').valueChanges.pipe(
        takeUntil(this.destroy$)
    ).subscribe((value: boolean) => {
      if (!value) {
        this.mcqForm.get('disableDate').disable();
      } else {
        this.mcqForm.get('disableDate').enable();
      }
    });
    this.mcqForm.get('questionCount').valueChanges.pipe(
        takeUntil(this.destroy$)
    ).subscribe((value: number) => {
      if (!this.quizVal || this.quizVal && value !== this.quizVal.no_of_questions) {
        this.questionsArray = [];
      }
    });
    this.mcqForm.get('answersPerQuestionCount').valueChanges.pipe(
        takeUntil(this.destroy$)
    ).subscribe((value: number) => {
      if (!this.quizVal || this.quizVal && value !== this.quizVal.answers_per_question) {
        this.questionsArray = [];
      }
    });
  }

  clearData() {
    this.mcqForm.get('name').setValue('');
    this.mcqForm.get('enableDateCheck').setValue(false);
    this.mcqForm.get('availability').setValue(false);
    this.mcqForm.get('enableDate').setValue('');
    this.mcqForm.get('disableDateCheck').setValue(false);
    this.mcqForm.get('availability').setValue(false);
    this.mcqForm.get('disableDate').setValue('');
    this.mcqForm.get('visibleType').setValue(this.visibleTypes[2]);
    this.mcqForm.get('amount').setValue(1000);
    this.mcqForm.get('questionCount').setValue(50);
    this.mcqForm.get('answersPerQuestionCount').setValue(5);
    this.mcqForm.get('noOfAttempts').setValue(3);
    this.mcqForm.get('allowedTime').setValue({'hour': 2, 'minute': 0});
    this.mcqForm.get('answerNumbering').setValue(this.answerNumberingTypes[0].key);
    this.mcqForm.get('showMarks').setValue(this.showMarksTypes[2].key);
    this.questionsArray = [];
    this.fileRef = null;
    this.mcqFile = null;
    this.mcqForm.get('amount').disable();
    this.mcqForm.get('enableDate').disable();
    this.mcqForm.get('disableDate').disable();
  }

  getMCQ(id) {
    this.spinnerService.show();
    this.courseSer.getMCQ(id).subscribe(
        (response) => {
          this.mcqResult = response['data'];
          const mcqData = this.mcqResult['mcq'];
          const componentData = this.mcqResult['component'];

          this.mcqForm.get('name').setValue(mcqData.name);
          this.mcqForm.get('availability').setValue(mcqData.availability);
          this.mcqForm.get('enableDateCheck').setValue(componentData.enable_from.active);
          if (componentData.enable_from.active) {
            this.mcqForm.get('enableDate').setValue(new Date(componentData.enable_from.date));
          }
          this.mcqForm.get('disableDateCheck').setValue(componentData.disable_from.active);
          if (componentData.disable_from.active) {
            this.mcqForm.get('disableDate').setValue(new Date(componentData.disable_from.date));
          }
          this.mcqForm.get('visibleType').setValue(componentData.visible_type);
          this.mcqForm.get('questionCount').setValue(mcqData.no_of_questions);
          this.mcqForm.get('answersPerQuestionCount').setValue(mcqData.answers_per_question);
          this.mcqForm.get('noOfAttempts').setValue(mcqData.no_of_attempts);
          this.mcqForm.get('allowedTime').setValue({'hour': Math.floor(mcqData.allowed_time / 60), 'minute': mcqData.allowed_time % 60});
          this.mcqForm.get('answerNumbering').setValue(mcqData.answer_numbering);
          this.mcqForm.get('showMarks').setValue(mcqData.display_type);
          this.questionsArray = mcqData.answers;
          this.fileRef = mcqData.file;
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger('Error during load.');
          $('#quizModal').modal('hide');
        }
    );
  }


  setAnswerMode(val) {
    this.isAnswerMode = val;
    this.changeModalWidth.emit(val);
    if (val) {
      if (this.mcqFile) {
        const reader = new FileReader();
        reader.onload = () => {
          const file: any = reader.result;
          this.pdfViewer.pdfSrc = new Blob([new Uint8Array(file)]);
          this.pdfViewer.refresh();
        };
        reader.readAsArrayBuffer(this.mcqFile);
      } else if (this.fileRef._id) {
        this.spinnerService.show();
        this.courseSer.downloadFile(this.fileRef._id).subscribe(
            (res) => {
              this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
              this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
              this.spinnerService.hide();
            },
            (error) => {
              this.spinnerService.hide();
              this.alertService.danger('Error occurred while downloading file.');
            }
        );
      }
      const questionCount = this.mcqForm.get('questionCount').value;
      const answersPerQuestionCount = this.mcqForm.get('answersPerQuestionCount').value;

      if (this.questionsArray.length === 0) {
        for (let i = 1; i <= questionCount; i++) {
          const answersPerQuestionCountArray = [];
          for (let j = 1; j <= answersPerQuestionCount; j++) {
            answersPerQuestionCountArray.push(new AnswerFormat(j, false));
          }
          this.questionsArray.push(new Answers(i, answersPerQuestionCountArray, this.answerTypes[0].key));
        }
      }
      this.validateAnswers();
    }
  }

  public validateAnswers() {
    for (const question of this.questionsArray) {
      const result = question['answerFormat'].filter(answer => answer['value']);
      question['valid'] = (result.length > 0);
    }
    this.issuesInAnswers = this.questionsArray.find(question => !question['valid']);
  }

  public onCheck(question: Answers, answer: AnswerFormat) {
    if ('single' === question.type && answer.value) {
      for (const qAnswer of question.answerFormat) {
        if (qAnswer.answer !== answer.answer) {
          qAnswer.value = false;
        }
      }
    }
    this.validateAnswers();
  }

  public onToggle(question: Answers) {
    if ('single' === question.type) {
      for (const qAnswer of question.answerFormat) {
        qAnswer.value = false;
      }
    }
    this.validateAnswers();
  }

  public dropped(event: UploadEvent) {
    this.files = event.files;
    if (this.files.length !== 1) {
      this.alertService.danger('Don\'t upload multiple files.');
      this.files = [];
      return;
    }
    this.spinnerService.show();
    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.courseSer.uploadFile(file).subscribe(
            (respons) => {
              this.spinnerService.hide();
              this.fileRef = respons['data'].file;
            },
            (error) => {
              this.spinnerService.hide();
              this.files = [];
              this.alertService.danger('Error occurred while uploading.');
            }
          );
          this.mcqFile = file;
        });
      } else {
        this.showSpinner = false;
        this.files = [];
        this.alertService.danger('empty directories are added.');
      }
    }
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  deleteItem() {
    this.files = [];
    this.spinnerService.show();
    this.courseSer.deleteFile(this.fileRef._id).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.fileRef = null;
          this.mcqFile = null;
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger(error['message']);
        }
    );
  }

  saveQuiz() {
    this.spinnerService.show();
    let data;
    let quizObject = this.quizModle;
    if (this.quizVal && this.mcqResult) {
      quizObject = new QuizModle('', '', '', this.mcqResult['component']['vertical_loc']);
      quizObject.component_id = this.mcqResult['component']['_id'];
      quizObject.mcq_id = this.mcqResult['mcq']['_id'];
    }

    quizObject.visible_type = this.mcqForm.get('visibleType').value;
    quizObject.name = this.mcqForm.get('name').value;
    quizObject.availability = this.mcqForm.get('availability').value;
    quizObject.file = this.fileRef._id;
    quizObject.no_of_questions = this.mcqForm.get('questionCount').value;
    quizObject.answers_per_question = this.mcqForm.get('answersPerQuestionCount').value;
    quizObject.answer_numbering = this.mcqForm.get('answerNumbering').value;
    const allowedTimeValue = this.mcqForm.get('allowedTime').value;
    quizObject.allowed_time = allowedTimeValue['hour'] * 60 + allowedTimeValue['minute'];
    quizObject.display_type = this.mcqForm.get('showMarks').value;
    quizObject.no_of_attempts = this.mcqForm.get('noOfAttempts').value;
    quizObject.amount = this.mcqForm.get('amount').value;
    if (this.mcqForm.get('enableDateCheck').value && this.mcqForm.get('enableDate').value) {
      const fromDateObject = new DateObject();
      fromDateObject.active = true;
      fromDateObject.date = (this.mcqForm.get('enableDate').value as Date).getTime();

      quizObject.enable_from = fromDateObject;
    }
    if (this.mcqForm.get('disableDateCheck').value && this.mcqForm.get('disableDate').value) {
      const toDateObject = new DateObject();
      toDateObject.active = true;
      toDateObject.date = (this.mcqForm.get('disableDate').value as Date).getTime();

      quizObject.disable_from = toDateObject;
    }

    if (quizObject.display_type !== this.showMarksTypes[2].key) {
      for (const question of this.questionsArray) {
        (question as Answers).answers = (question as Answers).answerFormat.filter(answer => answer.value).map(answer => answer.answer);
      }
      quizObject.answers = this.questionsArray;
    }

    this.courseSer.saveQuiz(quizObject).subscribe(
        (response) => {
          data = response['data'];
          this.spinnerService.hide();
          this.changeQuiz.emit(data);
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger('Error occurred while Saving Quiz');

          data = 'error';
          this.changeQuiz.emit(data);
        }
    );
  }
}
