export class SectionModle {
  class_id: string;
  _id: string;
  name: string;
  vertical_loc: number;

  constructor(classId, name, verticalLoc) {
    this.class_id = classId;
    this.name = name;
    this.vertical_loc = verticalLoc;
  }
}
