import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'ngx-alerts';
import { Router } from '@angular/router';
import {url} from 'src/environments/url';
import {ClassService} from '../../services/class.service';

@Component({
  selector: 'app-search-teacher',
  templateUrl: './search-teacher.component.html',
  styleUrls: ['./search-teacher.component.scss']
})
export class SearchTeacherComponent implements OnInit {
  focus: any;
  searchTxt = '';
  allTeachers = [];
  subjects = [];
  role = '';
  student = false;
  admin = false;
  teacher = false;
  assistant = false;
  key = 'Name';
  selectedSubject = '';
  /* data tabel for classes */
  rows = [];
  columns = [
    { prop: 'teacher_id', name: 'Teacher ID' },
    { prop: 'first_name', name: 'First Name' },
    { prop: 'last_name', name: 'Last Name' },
    { prop: 'email', name: 'Email' },
    { prop: 'telephone.mobile', name: 'Mobile No' }
  ];
  selected = [];
  /*************/
  teacher_ = { _id: '', teacher_id: '', first_name: '', last_name: '' };
  base_url = url;
  teachingSubjects: any;
  constructor(private modalService: NgbModal,
              private auth: AuthService,
              private alertService: AlertService,
              private classSer: ClassService,
              private router: Router) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    if (this.role === 'student') {
      this.student = true;
    } else if (this.role === 'teacher') {
      this.teacher = true;
    } else if (this.role === 'admin') {
      this.admin = true;
    } else if (this.role === 'assistant') {
      this.assistant = true;
    }
    this.getSubjects();
    this.getallteachers();
    this.getAllTeachingSubjects();
  }
  // selectTeacher(t) {
  //   this.teacher = t.selected[0];
  //   this.teacher._id = this.teacher['_id'];
  //   this.teacher_.teacher_id = this.teacher['teacher_id'];
  //   this.teacher_.first_name = this.teacher['first_name'];
  //   this.teacher_.last_name = this.teacher['last_name'];
  // }
  changeKey(value) {
    if (value === 'Name') {
      this.key = 'Name';
      this.rows = this.allTeachers;
    } else if (value === 'Subject') {
      this.key = 'Subject';
      this.rows = this.allTeachers;
    }
  }
  searchBySubject() {
    this.rows = [];
    for (let i = 0; i < this.allTeachers.length; i++) {
      if (this.allTeachers[i].subjects.includes(this.selectedSubject)) {
        this.rows.push(this.allTeachers[i]);
      }
    }
  }
  deleteProfile(content) {
    if (this.teacher_ != null) {
      this.modalService.open(content);
    } else {
      this.alertService.warning('Please Select a Teacher');
    }
  }
  getallteachers() {
    this.allTeachers = [];
    this.auth.getTeachers().subscribe(success => {
      this.rows = success['data'];
      this.allTeachers = success['data'];
      console.log(this.allTeachers);
    }, error => {
      console.log(error);
      this.alertService.danger('Error While Loading');
    });
  }
  getSubjects() {
    this.subjects = [];
    this.classSer.getVisibleSubjects().subscribe(data => {
      this.subjects = data['data'];
      console.log(this.subjects);
    }, error => {
      this.alertService.danger('Error occurred while getting Subjects');
    });
  }
  getAllTeachingSubjects() {
    this.teachingSubjects = {};
    this.auth.getAllTeachingSubjects().subscribe(success => {
      this.teachingSubjects = success['data'];
      console.log(this.teachingSubjects);
    }, error => {
      console.log(error);
      this.alertService.danger('Error While Loading');
    });
  }
  deleteTeacher() {
    this.auth.deleteTeacher(this.teacher_._id).subscribe(success => {
      this.getallteachers();
      this.alertService.success('Successfully Deleted');
      this.teacher_ = null;
      this.modalService.dismissAll();
    }, error => {
      this.alertService.danger('Error in Server');
      this.modalService.dismissAll();
    });
  }
  viewProfile() {
    if (this.selected.length !== 0) {
      this.auth.getTeacherProfile(this.selected[0]['_id']).subscribe(success => {
        localStorage.setItem('teacher', JSON.stringify(success['data']));
        this.router.navigate(['viewProfileTeacher']);
      }, error => {
        console.log(error);
      });
    } else {
      this.alertService.warning('Please Select a Teacher');
    }
  }
  search() {
    this.searchTxt.split(' ').join('');
    // this.searchTxt.toLowerCase();
    if (this.searchTxt === '') {
      this.rows = this.allTeachers;
    } else {
      this.rows = [];
      for (let i = 0; i < this.allTeachers.length; i++) {
        if (((this.allTeachers[i]['first_name']).toLowerCase()).includes(this.searchTxt.toLowerCase())) {
          this.rows.push(this.allTeachers[i]);
        } else if (((this.allTeachers[i]['last_name']).toLowerCase()).includes(this.searchTxt.toLowerCase())) {
          this.rows.push(this.allTeachers[i]);
        } else if (((this.allTeachers[i]['teacher_id']).toLowerCase()).includes(this.searchTxt.toLowerCase())) {
          this.rows.push(this.allTeachers[i]);
        }
      }
    }
  }

}
