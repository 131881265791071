import { NoticeModle } from './../../models/NoticeModle';
import { ForumModle } from './../../models/ForumModle';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignmentModle } from './../../models/AssignmentModle';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SessionModle } from './../../models/SessionModle';
import { DocumentModle } from './../../models/DocumentModle';
import { QuizModle } from './../../models/QuizModle';
import { SectionModle } from './../../models/SectionModle';
import { AlertService } from 'ngx-alerts';
import { CourseService } from './../../services/course.service';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import {MeetingModle} from '../../models/MeetingModle';
import {url} from 'src/environments/url';

declare var $: any;
@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css'],
})
export class CourseComponent implements OnInit {
  subject = '';
  batch = '';
  group = '';
  teacherName = '';

  messageDropDown = ['Send message', 'message log'];
  lessonDropDown = ['Edit Section', 'Delete Section'];
  sessionDropDown = ['Edit Session', 'Delete Session'];
  weekComponent = [
    'Upload Document',
    'Create Notice',
    'Create Assignment',
    'Create Quiz',
    'Create Forum',
    'Create Meeting'
  ];
  teacher_weekComponent = [
    'Upload Document',
    'Create Notice',
    'Create Assignment',
    'Create Forum',
  ];
  sectionVal;
  sectionModle;
  sessionVal;
  sessionModle;
  documentVal;
  documentModle;
  quizVal;
  quizModle;
  noticeVal;
  noticeModle;
  assignmentVal;
  assignmentModle;
  forumVal;
  forumModle;
  meetingVal;
  meetingModle;
  editItem;
  deleteTitle;
  deleteRef;
  nameOfDeleteItem;
  role;
  admin = false;
  teacher = false;
  student = false;
  assistant = false;

  classId;
  classFee: number;
  sessionFee: number;
  sections;
  sessions;
  components;
  base_url = 'https://metaspace.edu.lk';
  // base_url = 'http://localhost:4200';

  isMCQAnsMode = false;

  form = new FormGroup({
    sectionTitle: new FormControl('', Validators.required),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.classId = this.route.snapshot.paramMap.get('class_id');

    this.role = localStorage.getItem('role');
    if (this.role === 'admin') {
      this.admin = true;
    } else if (this.role === 'student') {
      this.student = true;
    } else if (this.role === 'teacher') {
      this.teacher = true;
    } else if (this.role === 'assistant') {
      this.assistant = true;
    }

    this.getClassInfo();
    this.getSections();
    this.getSessions();
    this.getComponents();
  }

  getSections() {
    this.spinnerService.show();
    this.courseSer.getSectionDetails(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.sections = response['data'];
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting sections');
      }
    );
  }
  getSessions() {
    this.spinnerService.show();
    this.courseSer.getSessions(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.sessions = response['data'];
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting sessions');
      }
    );
  }

  getComponents() {
    this.spinnerService.show();
    this.courseSer.getComponents(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.components = response['data'];
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting components');
      }
    );
  }
  getClassInfo() {
    this.spinnerService.show();
    this.courseSer.getClassInfo(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        const responseData = response['data'];
        this.subject = responseData.subject_id.subject;
        this.batch = responseData.batch_id.year + ' ' + responseData.batch_id.category.toUpperCase();
        this.group = responseData.group_id.group;
        this.teacherName =
          responseData.teacher_id.first_name +
          ' ' +
          responseData.teacher_id.last_name;

        this.classFee = responseData.fees;
        if (responseData.daily_fees) {
          this.sessionFee = responseData.fees;
        } else {
          this.sessionFee = responseData.fees / 4;
        }
        return true;
      },
      (error) => {
        this.spinnerService.hide();
        this.classFee = this.sessionFee = 0;
        this.alertService.danger('Error occurred while getting class Info');
        return false;
      }
    );
  }

  get sectionName() {
    return this.form.get('sectionTitle');
  }

  openMessage(item) {
    if (item == 'Send message') {
      $('#messageModal').modal('show');
    } else if ('message log') {
      this.router.navigate(['course/messages', this.classId]);
    }
  }
  openModel(value, head) {
    switch (value) {
      case 'Add Section':
        this.sectionVal = '';
        this.sectionModle = new SectionModle(
          this.classId,
          '',
          this.sections.length
        );
        $('#sectionModal').modal('show');
        break;
      case 'Edit Section':
        this.sectionVal = head;
        this.sectionModle = new Object();
        $('#sectionModal').modal('show');
        break;
      case 'Add Session':
        this.sessionVal = '';
        let session_cont;
        if (this.sessions[head._id]) {
          session_cont = this.sessions[head._id].length;
        } else {
          session_cont = 0;
        }
        this.sessionModle = new SessionModle(
          this.classId,
          head._id,
          this.classFee,
          this.sessionFee,
          session_cont
        );
        $('#sessionModal').modal('show');
        break;
      case 'Edit Session':
        this.sessionVal = head;
        this.sessionModle = new Object();
        $('#sessionModal').modal('show');
        break;
      case 'Upload Document':
        this.documentVal = '';
        let doc_cont;
        if (this.components[head._id]) {
          doc_cont = this.components[head._id].length;
        } else {
          doc_cont = 0;
        }
        this.documentModle = new DocumentModle(
          this.classId,
          head.section_id,
          head._id,
          doc_cont
        );
        $('#documentModal').modal('show');
        break;
      case 'Create Quiz':
        this.quizVal = '';
        let quizCount;
        if (this.components[head._id]) {
          quizCount = this.components[head._id].length;
        } else {
          quizCount = 0;
        }
        this.quizModle = new QuizModle(
          this.classId,
          head.section_id,
          head._id,
          quizCount
        );
        $('#quizModal').modal('show');
        break;
      case 'Create Assignment':
        this.assignmentVal = '';
        let assignment_cont;
        if (this.components[head._id]) {
          assignment_cont = this.components[head._id].length;
        } else {
          assignment_cont = 0;
        }
        this.assignmentModle = new AssignmentModle(
          this.classId,
          head.section_id,
          head._id,
          assignment_cont
        );
        $('#assignmentModal').modal('show');
        break;
      case 'Create Notice':
        this.noticeVal = '';
        let notice_cont;
        if (this.components[head._id]) {
          notice_cont = this.components[head._id].length;
        } else {
          notice_cont = 0;
        }
        this.noticeModle = new NoticeModle(
          this.classId,
          head.section_id,
          head._id,
          notice_cont
        );
        $('#noticeModal').modal('show');
        break;
      case 'Create Forum':
        this.forumVal = '';
        let forum_cont;
        if (this.components[head._id]) {
          forum_cont = this.components[head._id].length;
        } else {
          forum_cont = 0;
        }
        this.forumModle = new ForumModle(
          this.classId,
          head.section_id,
          head._id,
          forum_cont
        );
        $('#forumModal').modal('show');
        break;
      case 'Create Meeting':
        this.meetingVal = '';
        let meeting_cont;
        if (this.components[head._id]) {
          meeting_cont = this.components[head._id].length;
        } else {
          meeting_cont = 0;
        }
        this.meetingModle = new MeetingModle(
          this.classId,
          head.section_id,
          head._id,
          meeting_cont
        );
        $('#meetingModal').modal('show');
        break;
      // case "Delete Section":

      //   this.modalService.open("#contet");

      //   break;

      case 'Edit Component':
        if (head.type == 'Document') {
          this.documentVal = head;
          this.documentModle = new Object();
          $('#documentModal').modal('show');
        } else if (head.type == 'Assignment') {
          this.assignmentVal = head;
          this.assignmentModle = new Object();
          $('#assignmentModal').modal('show');
        } else if (head.type == 'MCQ') {
          this.quizVal = head;
          this.quizModle = new Object();
          $('#quizModal').modal('show');
        } else if (head.type == 'Forum') {
          this.forumVal = head;
          this.forumModle = new Object();
          $('#forumModal').modal('show');
        } else if (head.type == 'Label') {
          this.noticeVal = head;
          this.noticeModle = new Object();
          $('#noticeModal').modal('show');
        }else if (head.type == 'Meeting') {
          this.meetingVal = head;
          this.meetingModle = new Object();
          $('#meetingModal').modal('show');
        }
        break;
      default:
        break;
    }
  }
  deleteSection(content, section) {
    this.deleteRef = section;
    this.deleteTitle = 'section';
    this.nameOfDeleteItem = section.name;
    this.modalService.open(content);
  }
  deleteSession(content, session) {
    this.deleteRef = session;
    this.deleteTitle = 'session';
    this.nameOfDeleteItem = session.name;
    this.modalService.open(content);
  }
  deleteComponent(content, component) {
    this.deleteRef = component;
    this.deleteTitle = component.type;
    this.nameOfDeleteItem = component.name;
    this.modalService.open(content);
  }
  deleteItem() {
    switch (this.deleteTitle) {
      case 'section':
        this.spinnerService.show();
        this.courseSer.deleteSection(this.deleteRef._id).subscribe(
          (response) => {
            this.spinnerService.hide();

            const index = this.sections.indexOf(this.deleteRef);
            this.sections.splice(index, 1);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger('Error occurred while deleting section');
          }
        );
        break;
      case 'session':
        this.spinnerService.show();
        this.courseSer.deleteSession(this.deleteRef._id).subscribe(
          (response) => {
            this.spinnerService.hide();
            const sessionArray = this.sessions[this.deleteRef.section_id];
            const index = sessionArray.indexOf(this.deleteRef);
            sessionArray.splice(index, 1);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger('Error occurred while deleting session');
          }
        );
        break;
      case 'Document':
        this.spinnerService.show();
        this.courseSer.deleteDocument(this.deleteRef._id).subscribe(
          (response) => {
            this.spinnerService.hide();
            const componentArray = this.components[this.deleteRef.session_id];
            const index = componentArray.indexOf(this.deleteRef);
            componentArray.splice(index, 1);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger('Error occurred while deleting Document');
          }
        );
        break;
      case 'Assignment':
        this.spinnerService.show();
        this.courseSer.deleteAssignment(this.deleteRef._id).subscribe(
          (response) => {
            this.spinnerService.hide();
            const componentArray = this.components[this.deleteRef.session_id];
            const index = componentArray.indexOf(this.deleteRef);
            componentArray.splice(index, 1);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger(
              'Error occurred while deleting Assignment'
            );
          }
        );
        break;
      case 'MCQ':
        this.spinnerService.show();
        this.courseSer.deleteQuiz(this.deleteRef._id).subscribe(
          (response) => {
            this.spinnerService.hide();
            const componentArray = this.components[this.deleteRef.session_id];
            const index = componentArray.indexOf(this.deleteRef);
            componentArray.splice(index, 1);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger(error['error']['message']);
          }
        );
        break;
      case 'Forum':
        this.spinnerService.show();
        this.courseSer.deleteForum(this.deleteRef._id).subscribe(
          (response) => {
            this.spinnerService.hide();
            const componentArray = this.components[this.deleteRef.session_id];
            const index = componentArray.indexOf(this.deleteRef);
            componentArray.splice(index, 1);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger('Error occurred while deleting Form');
          }
        );
        break;
      case 'Label':
        this.spinnerService.show();

        this.courseSer.deleteNotice(this.deleteRef._id).subscribe(
          (response) => {
            this.spinnerService.hide();

            const componentArray = this.components[this.deleteRef.session_id];
            const index = componentArray.indexOf(this.deleteRef);
            componentArray.splice(index, 1);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger('Error occurred while deleting Lable');
          }
        );
        break;
      case 'Meeting':
        this.spinnerService.show();
        this.courseSer.deleteMeeting(this.deleteRef._id).subscribe(
          (response) => {
            this.spinnerService.hide();
            const componentArray = this.components[this.deleteRef.session_id];
            const index = componentArray.indexOf(this.deleteRef);
            componentArray.splice(index, 1);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger('Error occurred while deleting Form');
          }
        );
        break;
      default:
        break;
    }
    this.modalService.dismissAll();
  }

  changeSections($event) {
    if (!this.sectionVal) {
      // add section
      if ($event != 'error') {
        this.sections.splice(0, 0, $event);
      }
    } else {
      // update section
      if ($event != 'error') {
        const index = this.sections.indexOf(this.sectionVal);
        // this.sections.splice(index, 1, $event);
        this.sections[index].name = $event;
      }
    }

    $('#sectionModal').modal('hide');
  }

  changeSessions($event) {
    if (!this.sessionVal) {
      // add session
      if ($event != 'error') {
        if (this.sessions[$event.section_id]) {
          this.sessions[$event.section_id].push($event);
        } else {
          this.getSessions();
        }
      }
    } else {
      // update session
      if ($event != 'error') {
        const sessionArray = this.sessions[this.sessionVal.section_id];
        const index = sessionArray.indexOf(this.sessionVal);

        sessionArray[index].name = $event.name;
        sessionArray[index].class_session_id._for =
          $event.class_session_id._for;
      }
    }

    $('#sessionModal').modal('hide');
  }
  changeDocuments($event) {
    if (!this.documentVal) {
      // add document
      if ($event != 'error') {
        if (this.components[$event.session_id]) {
          this.components[$event.session_id].push($event);
        } else {
          this.getComponents();
        }
      }
    } else {
      // update document
      if ($event != 'error') {
        this.getComponents();
      }
    }

    $('#documentModal').modal('hide');
  }

  changeQuiz($event) {
    if (!this.quizVal) {
      // add quiz
      if ($event != 'error') {
        if (this.components[$event.session_id]) {
          this.components[$event.session_id].push($event);
        } else {
          this.getComponents();
        }
      }
    } else {
      // update document
      if ($event != 'error') {
        this.getComponents();
      }
    }

    $('#quizModal').modal('hide');
  }

  changeModalWidth($event) {
    this.isMCQAnsMode = $event;
  }

  changeAssignment($event) {
    if (!this.documentVal) {
      // add assignment
      if ($event != 'error') {
        this.getComponents();
      }
    } else {
      // update assignment
      if ($event != 'error') {
        this.getComponents();
      }
    }

    $('#assignmentModal').modal('hide');
  }
  changeForum($event) {
    if (!this.forumVal) {
      // add forum
      if ($event != 'error') {
        if (this.components[$event.session_id]) {
          this.components[$event.session_id].push($event);
        } else {
          this.getComponents();
        }
      }
    } else {
      if ($event != 'error') {
        this.getComponents();
      }
    }
    $('#forumModal').modal('hide');
  }
  changeNotice($event) {
    if (!this.noticeVal) {
      // add notice
      if ($event != 'error') {
        if (this.components[$event.session_id]) {
          this.components[$event.session_id].push($event);
        } else {
          this.getComponents();
        }
      }
    } else {
      // update notice
      if ($event != 'error') {
        this.getComponents();
      }
    }
    $('#noticeModal').modal('hide');
  }

  changeMeeting($event) {
    if (!this.meetingVal) {
      // add meeting
      if ($event != 'error') {
        this.getComponents();
      }
    } else {
      // update meeting
      if ($event != 'error') {
        this.getComponents();
      }
    }

    $('#meetingModal').modal('hide');
  }

  downloadDoc(component) {
    this.spinnerService.show();
    this.courseSer.downloadDocument(component._id).subscribe(
      (data) => {
        this.spinnerService.hide();
        importedSaveAs(data, component.name);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error during downloading');
      },
      () => console.info('OK')
    );
  }
  viewComponent(component, session, section) {
    switch (component.type) {
      case 'Document':
        this.downloadDoc(component);
        break;
      case 'Assignment':
        this.router.navigate([
          'course/assignment',
          this.classId,
          section.name,
          session.name,
          component.name,
          component._id,
        ]);
        break;
      case 'Forum':
        this.router.navigate([
          'course/forum',
          this.classId,
          section.name,
          session.name,
          component.name,
          component._id,
        ]);
        break;
      case 'MCQ':
        this.router.navigate([
          '/course/mcq',
          this.classId,
          section.name,
          session.name,
          component.name,
          component._id,
        ]);
        break;
      case 'Meeting':
        this.spinnerService.show();
        this.courseSer.registerMeeting(component._id).subscribe(
          (response) => {
            this.spinnerService.hide();
            console.log(response['data']);
            window.open(response['data'], '_blank');
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger(error['error']['message']);
          }
        );

        // window.open(this.base_url + '/classes/course/meeting?class_id=' + this.classId.toString() + '&section_name='
        //   + section.name.toString() + '&session_name=' + session.name.toString() + '&component_name=' + component.name.toString()
        //   + '&component_id=' + component._id.toString(),
        //   '_blank');

        // this.router.navigate([
        //   '/classes/course/meeting',
        //   this.classId,
        //   section.name,
        //   session.name,
        //   component.name,
        //   component._id,
        // ]);
        break;

      default:
        break;
    }
  }
  moveRight(component) {
    this.spinnerService.show();
    component.horizontal_loc += 1;
    const inputModle = {
      field: 'horizontal_loc',
      value: component.horizontal_loc,
    };

    this.courseSer.updateComponentByField(component._id, inputModle).subscribe(
      (response) => {
        this.spinnerService.hide();
        const componentArray = this.components[component.session_id];
        componentArray[componentArray.indexOf(component)].horizontal_loc =
          component.horizontal_loc;
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while updating');
      }
    );
  }
  moveLeft(component) {
    this.spinnerService.show();
    component.horizontal_loc -= 1;
    const inputModle = {
      field: 'horizontal_loc',
      value: component.horizontal_loc,
    };

    this.courseSer.updateComponentByField(component._id, inputModle).subscribe(
      (response) => {
        this.spinnerService.hide();
        const componentArray = this.components[component.session_id];
        componentArray[componentArray.indexOf(component)].horizontal_loc =
          component.horizontal_loc;
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while updating');
      }
    );
  }

  toggleShow(component) {
    this.spinnerService.show();
    component.show = !component.show;
    const inputModle = { field: 'show', value: component.show };

    this.courseSer.updateComponentByField(component._id, inputModle).subscribe(
      (response) => {
        this.spinnerService.hide();
        const componentArray = this.components[component.session_id];
        componentArray[componentArray.indexOf(component)].show = component.show;
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while updating');
      }
    );
  }
  section_up(section) {
    const index = this.sections.indexOf(section);
    const inputModle = {
      sections: [
        {
          section_id: section._id,
          field: 'vertical_loc',
          value: section.vertical_loc + 1,
        },
        {
          section_id: this.sections[index - 1]._id,
          field: 'vertical_loc',
          value: this.sections[index - 1].vertical_loc - 1,
        },
      ],
    };
    this.swapSection(inputModle);
  }
  section_down(section) {
    const index = this.sections.indexOf(section);
    const inputModle = {
      sections: [
        {
          section_id: section._id,
          field: 'vertical_loc',
          value: section.vertical_loc - 1,
        },
        {
          section_id: this.sections[index + 1]._id,
          field: 'vertical_loc',
          value: this.sections[index + 1].vertical_loc + 1,
        },
      ],
    };
    this.swapSection(inputModle);
  }
  swapSection(modle) {
    this.spinnerService.show();

    this.courseSer.swapSection(modle).subscribe(
      (response) => {
        this.getSections();
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while updating');
      }
    );
  }

  session_up(session) {
    const sessiontArray = this.sessions[session.section_id];
    const index = sessiontArray.indexOf(session);
    const inputModle = {
      sessions: [
        {
          session_id: session._id,
          field: 'vertical_loc',
          value: session.vertical_loc - 1,
        },
        {
          session_id: sessiontArray[index - 1]._id,
          field: 'vertical_loc',
          value: sessiontArray[index - 1].vertical_loc + 1,
        },
      ],
    };
    this.swapSession(inputModle);
  }

  session_down(session) {
    const sessiontArray = this.sessions[session.section_id];
    const index = sessiontArray.indexOf(session);
    const inputModle = {
      sessions: [
        {
          session_id: session._id,
          field: 'vertical_loc',
          value: session.vertical_loc + 1,
        },
        {
          session_id: sessiontArray[index + 1]._id,
          field: 'vertical_loc',
          value: sessiontArray[index + 1].vertical_loc - 1,
        },
      ],
    };
    this.swapSession(inputModle);
  }
  swapSession(modle) {
    this.spinnerService.show();

    this.courseSer.swapSession(modle).subscribe(
      (response) => {
        this.getSessions();
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while updating');
      }
    );
  }

  component_up(component) {
    const componentArray = this.components[component.session_id];
    const index = componentArray.indexOf(component);
    const inputModle = {
      components: [
        {
          component_id: component._id,
          field: 'vertical_loc',
          value: component.vertical_loc - 1,
        },
        {
          component_id: componentArray[index - 1]._id,
          field: 'vertical_loc',
          value: componentArray[index - 1].vertical_loc + 1,
        },
      ],
    };
    this.swapComponent(inputModle);
  }
  component_down(component) {
    const componentArray = this.components[component.session_id];

    const index = componentArray.indexOf(component);
    const inputModle = {
      components: [
        {
          component_id: component._id,
          field: 'vertical_loc',
          value: component.vertical_loc + 1,
        },
        {
          component_id: componentArray[index + 1]._id,
          field: 'vertical_loc',
          value: componentArray[index + 1].vertical_loc - 1,
        },
      ],
    };
    this.swapComponent(inputModle);
  }
  swapComponent(modle) {
    this.spinnerService.show();
    this.courseSer.swapComponent(modle).subscribe(
      (response) => {
        this.getComponents();
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while updating');
      }
    );
  }
}
