import { ComponentPath } from './../../models/ComponentPath';
import { PaymentService } from './../../services/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from './../../services/course.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {Component, ElementRef, OnInit, ViewChildren} from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
import {url} from 'src/environments/url';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'course-student',
  templateUrl: './course-student.component.html',
  styleUrls: ['./course-student.component.css'],
})
export class CourseStudentComponent implements OnInit {
  @ViewChildren('paytmForm') paytmForm: ElementRef;
  classId;
  sections;
  sessions;
  components;
  subject;
  batch;
  group;
  teacherName;
  daily_fees;
  classMonths = [];
  selectedProduct = '';
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  userId;
  orderId;
  paymentSuccess = false;
  payment_details = [];
  base_url = 'https://metaspace.edu.lk';
  // base_url = 'http://localhost:4200';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseSer: CourseService,
    private paymentSer: PaymentService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.classId = this.route.snapshot.paramMap.get('class_id');
    this.orderId = this.route.snapshot.queryParamMap.get('order_id');
    this.userId = localStorage.getItem('user_id');
    if (this.orderId) {
      this.spinnerService.show();
      this.paymentSer.getPaymentStatus(this.orderId).subscribe(success => {
        if (success['success']) {
          this.paymentSuccess = true;
        }
        this.spinnerService.hide();
      }, error => {
        console.log(error);
        this.alertService.danger('Error while getting payment status');
        this.spinnerService.hide();
      });
      // this.paymentSuccess = true;
      // this.paymentSer.authenticatePayhere().subscribe(success => {
      //   console.log(success);
      //   if (success) {
      //     this.paymentSer.getPayhereStatus(this.orderId, 'd64daba6-91cf-4fba-acef-2fe630a5ad6f').subscribe(response => {
      //       // this.paymentSer.getPaymentStatus(this.orderId).subscribe(success => {
      //       console.log(response);
      //       if (response['success']) {
      //         this.paymentSuccess = true;
      //       }
      //       this.spinnerService.hide();
      //     }, error => {
      //       console.log(error);
      //       this.alertService.danger('Error while getting payment status');
      //       this.spinnerService.hide();
      //     });
      //   }
      //   this.spinnerService.hide();
      // }, error => {
      //   console.log(error);
      //   this.alertService.danger('Error while getting payment status');
      //   this.spinnerService.hide();
      // });
    }
    this.getPaymentDetails();
    this.getClassMonths();
    this.getClassInfo();
    this.getSections();
    this.getSessions();
    this.getComponents();
  }

  getSections() {
    this.spinnerService.show();
    this.courseSer.getSectionDetails(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.sections = response['data'];
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting sections');
      }
    );
  }
  getSessions() {
    this.spinnerService.show();
    this.courseSer.getSessions(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.sessions = response['data'];
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting sessions');
      }
    );
  }

  getComponents() {
    this.spinnerService.show();
    this.courseSer.getComponents(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.components = response['data'];
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting components');
      }
    );
  }
  getClassInfo() {
    this.spinnerService.show();
    this.courseSer.getClassInfo(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        const responseData = response['data'];
        this.subject = responseData.subject_id.subject;
        this.batch = responseData.batch_id.year + ' ' + responseData.batch_id.category.toUpperCase();
        this.group = responseData.group_id.group;
        this.teacherName =
          responseData.teacher_id.first_name +
          ' ' +
          responseData.teacher_id.last_name;
        this.daily_fees = responseData.daily_fees;
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while getting class Info');
      }
    );
  }
  getClassMonths() {
    this.spinnerService.show();
    this.paymentSer.getClassMonths(this.classId, this.userId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.classMonths = response['data'];
        console.log(this.classMonths);
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error during load.');
      }
    );
  }
  getPaymentDetails() {
    this.spinnerService.show();
    this.paymentSer.getPaymentDetails(this.classId, this.userId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.payment_details = response['data'];
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error during load.');
      }
    );
  }
  downloadDoc(component) {
    this.spinnerService.show();
    this.courseSer.downloadDocument(component._id).subscribe(
      (data) => {
        this.spinnerService.hide();
        importedSaveAs(data, component.name);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while downloading');
      },
      () => console.info('OK')
    );
  }
  open() {
    $('#payMonth').modal('show');
  }

  viewComponent(component, session, section) {
    switch (component.type) {
      case 'Document':
        this.downloadDoc(component);
        break;
      case 'Assignment':
        this.router.navigate([
          'course',
          this.classId,
          section.name,
          session.name,
          component.name,
          component._id,
        ]);
        break;
      case 'Forum':
        this.router.navigate([
          'course/forum',
          this.classId,
          section.name,
          session.name,
          component.name,
          component._id,
        ]);
        break;
      case 'Meeting':

        this.spinnerService.show();
        this.courseSer.registerMeeting(component._id).subscribe(
          (response) => {
            this.spinnerService.hide();
            console.log(response['data']);
            window.open(response['data'], '_blank');
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger(error['error']['message']);
          }
        );

        // window.open(this.base_url + '/studentDashboard/course/meeting?class_id=' + this.classId.toString() + '&section_name='
        //   + section.name.toString() + '&session_name=' + session.name.toString() + '&component_name=' + component.name.toString()
        //   + '&component_id=' + component._id.toString(),
        //   '_blank');
        break;
      case 'MCQ':
        this.router.navigate([
          '/studentDashboard/course/mcq',
          this.classId,
          section.name,
          session.name,
          component.name,
          component._id,
        ]);
        break;
      default:
        break;
    }
  }
  changeOrder($event) {
    $('#orderDetailsModal').modal('hide');
  }
  changeMonth($event) {
    this.selectedProduct = $event;
    $('#payMonth').modal('hide');
    $('#orderDetailsModal').modal('show');
  }
  getProductDetails(productId) {
    this.selectedProduct = productId;
    $('#orderDetailsModal').modal('show');
  }
}

