import { Component, OnInit } from '@angular/core';
import {FormControl} from "@angular/forms";
import {AlertService} from "ngx-alerts";
import {ClassService} from "../services/class.service";
import {Ng4LoadingSpinnerService} from "ng4-loading-spinner";
import {AuthService} from "../services/auth.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AttendenceService} from "../services/attendence.service";

@Component({
  selector: 'app-front-test',
  templateUrl: './front-test.component.html',
  styleUrls: ['./front-test.component.css']
})
export class FrontTestComponent implements OnInit {
  results: any[] = [];
  queryField: FormControl = new FormControl();
  allstudents = [];
  constructor(private modalService: NgbModal, private classSer: ClassService
    , private auth: AuthService, private alertService: AlertService, private attSer: AttendenceService,
              private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.getallstudents();
    this.queryField.valueChanges.subscribe(result => {
      this.results = [];
      for (let i = 0; i < this.allstudents.length; i++) {
        if (((this.allstudents[i]['first_name']).toLowerCase()).includes(result)) {
          this.results.push(this.allstudents[i]);
        } else if (((this.allstudents[i]['last_name']).toLowerCase()).includes(result)) {
          this.results.push(this.allstudents[i]);
        } else if (((this.allstudents[i]['student_id']).toLowerCase()).includes(result)) {
          this.results.push(this.allstudents[i]);
        }
      }
    });
  }

  getallstudents() {
    this.allstudents = [];
    this.auth.getStudents().subscribe(success => {
      this.allstudents = success['data'];
    }, error => {
      console.log(error);
    });
  }

}
