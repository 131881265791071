import { AssignmentEditModle } from './../../../models/AssignmentEditModle';
import { AssignmentModle } from './../../../models/AssignmentModle';
import { CurrencyPipe } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AlertService } from 'ngx-alerts';
import { CourseService } from './../../../services/course.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import {
  Component,
  OnInit,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  UploadEvent,
  UploadFile,
} from 'ngx-file-drop';
// import { BsDatepickerConfig } from "ngx-bootstrap/datepicker/public_api";

declare var $: any;
@Component({
  selector: 'course-assignment-modal',
  templateUrl: './course-assignment-modal.component.html',
  styleUrls: ['./course-assignment-modal.component.css'],
})
export class CourseAssignmentModalComponent implements OnInit {

  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private currencyPipe: CurrencyPipe
  ) {}

  get assignmentTitleRef() {
    return this.form.get('assignmentTitle');
  }
  get editorRef() {
    return this.form.get('editor');
  }
  get amountRef() {
    return this.form.get('amount');
  }
  get enableDateRef() {
    return this.form.get('enableDate');
  }
  get enableTimeRef() {
    return this.form.get('enableTime');
  }
  get disableDateRef() {
    return this.form.get('disableDate');
  }
  get disableTimeRef() {
    return this.form.get('disableTime');
  }
  get allowDateRef() {
    return this.form.get('allowDate');
  }
  get allowTimeRef() {
    return this.form.get('allowTime');
  }
  get dueDateRef() {
    return this.form.get('dueDate');
  }
  get dueTimeRef() {
    return this.form.get('dueTime');
  }
  @Input() assignmentVal: AssignmentModle;
  @Input() assignmentModle: AssignmentModle;
  @Output() changeAssignment = new EventEmitter();

  modelTitle = 'Add assignment';
  files = [];
  submitTypes = ['All', 'Type In', 'Upload'];
  submissionTypeValue = 'All';
  visibleTypes = ['Paid', 'Free', 'Paid for Class'];
  visibleTypeValue = 'Paid for Class';
  enableStatus;
  disableStatus;
  dropFileStatus;
  showspinner;
  price;
  addButton;
  editButton;
  assignmentId;
  updateModle: AssignmentEditModle;

  form = new FormGroup({
    assignmentTitle: new FormControl('', Validators.required),
    editor: new FormControl('', Validators.required),
    amount: new FormControl('', Validators.required),
    enableDate: new FormControl('', Validators.required),
    enableTime: new FormControl('', Validators.required),
    disableDate: new FormControl('', Validators.required),
    disableTime: new FormControl('', Validators.required),
    allowDate: new FormControl('', Validators.required),
    allowTime: new FormControl('', Validators.required),
    dueDate: new FormControl('', Validators.required),
    dueTime: new FormControl('', Validators.required),
  });

  config = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      // remove formatting button
      ['link'], // link and image, video
    ],
  };

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.assignmentVal) {
      this.modelTitle = 'Add Assignment';
      this.assignmentTitleRef.setValue('');
      this.editorRef.setValue('');
      this.files = [];
      this.visibleTypeValue = 'Paid for Class';
      this.amountRef.disable();
      this.submissionTypeValue = 'All';
      this.enableStatus = false;
      this.disableStatus = false;
      this.enableDateRef.setValue('');
      this.enableTimeRef.setValue('');
      this.enableDateRef.disable();
      this.enableTimeRef.disable();
      this.disableDateRef.setValue('');
      this.disableTimeRef.setValue('');
      this.disableDateRef.disable();
      this.disableTimeRef.disable();
      this.allowDateRef.setValue('');
      this.dueTimeRef.setValue('');
      this.addButton = true;
      this.editButton = false;
    } else {
      this.modelTitle = 'Edit Assignment';

      this.files = [];
      this.amountRef.disable();
      this.updateModle = new AssignmentEditModle(
        this.assignmentVal._id,
        this.assignmentVal.vertical_loc,
        this.assignmentVal.horizontal_loc,
        this.assignmentVal.show
      );
      this.getAssignment(this.assignmentVal._id);
      this.assignmentTitleRef.setValue(this.assignmentVal.name);
      this.visibleTypeValue = this.assignmentVal.visible_type;
      this.enableStatus = this.assignmentVal.enable_from.active;

      if (this.enableStatus) {
        this.enableDateRef.setValue(
          new Date(this.assignmentVal.enable_from.date)
        );
        this.enableTimeRef.setValue(
          new Date(this.assignmentVal.enable_from.date)
        );
        this.enableDateRef.enable();
        this.enableTimeRef.enable();
      } else {
        this.enableDateRef.setValue('');
        this.enableTimeRef.setValue('');
        this.enableDateRef.disable();
        this.enableTimeRef.disable();
      }
      this.disableStatus = this.assignmentVal.disable_from.active;

      if (this.disableStatus) {
        this.disableDateRef.setValue(
          new Date(this.assignmentVal.disable_from.date)
        );
        this.disableTimeRef.setValue(
          new Date(this.assignmentVal.disable_from.date)
        );
        this.disableDateRef.enable();
        this.disableTimeRef.enable();
      } else {
        this.disableDateRef.setValue('');
        this.disableTimeRef.setValue('');
        this.disableDateRef.disable();
        this.disableTimeRef.disable();
      }
      this.dropFileStatus = true;
      this.addButton = false;
      this.editButton = true;
    }
  }

  getAssignment(id) {
    this.spinnerService.show();
    this.courseSer.getAssignment(id).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.assignmentId = response['data'].assignment._id;
        this.editorRef.setValue(response['data'].assignment.description);
        response['data'].file_info.forEach((element) => {
          this.files.push(element.file_id);
        });
        this.submissionTypeValue = response['data'].assignment.submission_type;
        if (this.visibleTypeValue.toLowerCase() == 'paid') {
          this.amountRef.setValue(response['data'].product_info.amount);
          this.amountRef.enable();
        }
        this.allowDateRef.setValue(
          new Date(response['data'].assignment.allow_date)
        );
        this.allowTimeRef.setValue(
          new Date(response['data'].assignment.allow_date)
        );
        this.dueDateRef.setValue(
          new Date(response['data'].assignment.due_date)
        );
        this.dueTimeRef.setValue(
          new Date(response['data'].assignment.due_date)
        );

        this.updateModle.prev_amount = response['data'].product_info.amount;
        this.updateModle.product_id = response['data'].product_info._id;
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error during load.');
        $('#assignmentModal').modal('hide');
      }
    );
  }

  public dropped(event: UploadEvent) {
    let fileCount = event.files.length;

    for (const droppedFile of event.files) {
      // Is it a file?
      this.showspinner = true;
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.courseSer.uploadFile(file).subscribe(
            (respons) => {
              this.files.push(respons['data'].file);

              fileCount -= 1;
              if (fileCount == 0) {
                this.dropFileStatus = true;
                this.showspinner = false;
              }
            },
            (error) => {
              this.showspinner = false;

              this.files = [];
              this.dropFileStatus = false;
              this.alertService.danger('Error occurred while uploading.');
            }
          );
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        this.showspinner = false;
        this.files = [];
        this.dropFileStatus = false;
        this.alertService.danger('empty directories are added.');
      }
    }
  }

  deleteItem(item) {
    this.spinnerService.show();
    this.courseSer.deleteFile(item._id).subscribe(
      (response) => {
        this.spinnerService.hide();

        this.files.splice(this.files.indexOf(item), 1);
        if (this.files.length == 0) {
          this.dropFileStatus = false;
        }
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while deleting.');
      }
    );
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  changeSubmissionType(item) {
    this.submissionTypeValue = item;
  }
  changeVisibleType(item) {
    this.visibleTypeValue = item;
    if (item.toLowerCase() == 'paid') {
      this.amountRef.enable();
      return true;
    } else {
      this.amountRef.setValue('');
      this.amountRef.disable();
      return false;
    }
  }

  clearAmount() {
    this.amountRef.setValue('');
  }
  transformAmount() {
    // /\d+/g
    if (this.amountRef.value.match(/^\d*\.?(?:\d*)?$/) == null) {
      this.amountRef.setValue('');
      this.price = null;
      return;
    }
    this.transform(this.amountRef.value);
  }
  transform(value) {
    this.price = value;
    const formattedAmount = this.currencyPipe.transform(value, 'Rs ');
    this.amountRef.setValue(formattedAmount);
  }
  togleEnableDate() {
    if (this.enableStatus) {
      this.enableDateRef.setValue('');
      this.enableTimeRef.setValue('');
      this.enableDateRef.disable();
      this.enableTimeRef.disable();
      this.enableStatus = !this.enableStatus;
    } else {
      this.enableDateRef.enable();
      this.enableTimeRef.enable();
      this.enableStatus = !this.enableStatus;
    }
  }
  togleDisableDate() {
    if (this.disableStatus) {
      this.disableDateRef.setValue('');
      this.disableTimeRef.setValue('');
      this.disableDateRef.disable();
      this.disableTimeRef.disable();
      this.disableStatus = !this.disableStatus;
    } else {
      this.disableDateRef.enable();
      this.disableTimeRef.enable();
      this.disableStatus = !this.disableStatus;
    }
  }

  submitForm() {
    this.spinnerService.show();
    let data;
    if (!this.assignmentVal) {
      this.assignmentModle.name = this.assignmentTitleRef.value;
      this.assignmentModle.description = this.editorRef.value;
      this.assignmentModle.submission_type = this.submissionTypeValue;
      this.assignmentModle.visible_type = this.visibleTypeValue;
      if (this.visibleTypeValue.toLowerCase() == 'paid') {
        this.assignmentModle.amount = this.price;
      }
      this.assignmentModle.vertical_loc += 1;
      if (this.enableStatus) {
        this.assignmentModle.enable_from.active = this.enableStatus;
        this.assignmentModle.enable_from.date = new Date(
          this.enableDateRef.value.toDateString() +
            ' ' +
            this.enableTimeRef.value.toLocaleTimeString() +
            ' GMT+0530'
        );
      }
      if (this.disableStatus) {
        this.assignmentModle.disable_from.active = this.disableStatus;
        this.assignmentModle.disable_from.date = new Date(
          this.disableDateRef.value.toDateString() +
            ' ' +
            this.disableTimeRef.value.toLocaleTimeString() +
            ' GMT+0530'
        );
      }
      this.assignmentModle.allow_date = new Date(
        this.allowDateRef.value.toDateString() +
          ' ' +
          this.allowTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
      );
      this.assignmentModle.due_date = new Date(
        this.dueDateRef.value.toDateString() +
          ' ' +
          this.dueTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
      );
      this.files.forEach((element) => {
        this.assignmentModle.files.push(element._id);
      });

      this.courseSer.AddAssignment(this.assignmentModle).subscribe(
        (response) => {
          data = response['data'];

          this.spinnerService.hide();
          this.changeAssignment.emit(data);
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger('Error occurred while Uploading Document');

          data = 'error';
          this.changeAssignment.emit(data);
        }
      );
    } else {
      // update assignment
      this.updateModle.name = this.assignmentTitleRef.value;
      this.updateModle.description = this.editorRef.value;
      this.files.forEach((element) => {
        this.updateModle.files.push(element._id);
      });
      this.updateModle.submission_type = this.submissionTypeValue;
      this.updateModle.visible_type = this.visibleTypeValue;
      if (this.visibleTypeValue.toLowerCase() == 'paid') {
        this.updateModle.amount = this.price;
      }

      if (this.enableStatus) {
        this.updateModle.enable_from.active = this.enableStatus;
        this.updateModle.enable_from.date = new Date(
          this.enableDateRef.value.toDateString() +
            ' ' +
            this.enableTimeRef.value.toLocaleTimeString() +
            ' GMT+0530'
        );
      } else {
        this.updateModle.enable_from.active = this.enableStatus;
        this.updateModle.enable_from.date = new Date();
      }

      if (this.disableStatus) {
        this.updateModle.disable_from.active = this.disableStatus;
        this.updateModle.disable_from.date = new Date(
          this.disableDateRef.value.toDateString() +
            ' ' +
            this.disableTimeRef.value.toLocaleTimeString() +
            ' GMT+0530'
        );
      } else {
        this.updateModle.disable_from.active = this.disableStatus;
        this.updateModle.disable_from.date = new Date();
      }

      this.updateModle.allow_date = new Date(
        this.allowDateRef.value.toDateString() +
          ' ' +
          this.allowTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
      );
      this.updateModle.due_date = new Date(
        this.dueDateRef.value.toDateString() +
          ' ' +
          this.dueTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
      );

      this.courseSer
        .updateAssignment(this.assignmentId, this.updateModle)
        .subscribe(
          (response) => {
            data = response['data'];
            this.spinnerService.hide();
            this.changeAssignment.emit(data);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger(
              'Error occurred while updating assignment'
            );

            data = 'error';
            this.changeAssignment.emit(data);
          }
        );
    }
  }
}
