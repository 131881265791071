import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClassService } from 'src/app/services/class.service';
import { AlertService } from 'ngx-alerts';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {url} from 'src/environments/url';

declare var $: any;

@Component({
  selector: 'app-view-profile-student',
  templateUrl: './view-profile-student.component.html',
  styleUrls: ['./view-profile-student.component.scss']
})
export class ViewProfileStudentComponent implements OnInit {
  focus: any;
  student_id = '';
  user_id = '';
  current_batch = {};
  base_url = url;
  student;
  // current_selected = this.current_batch
  username = '';
  role = '';
  profile_pic = 'edit-student-profile.png';
  studentDetails = {student_id: '', firstName: '', middleName: '', lastName: '', birthday: '', email: '', mobile_number: '', mobile: '',
    address: '', city: '', guardianName: '', guardian_mobile: '', guardian_land: '', admission_batch: '', current_batch: '', admission_date:
      '', stream: '', profile_pic: '', gender: '', password: ''};
  classes = [];
  batches = [];
  streams = [];
  /* data tabel for classes */
  rows = [];
  columns = [
    { prop: 'subject_id', name: 'Subject' },
    { prop: 'batch_id', name: 'Batch' },
    { prop: 'teacher_id', name: 'Teacher' },
    { prop: 'group_id', name: 'Group' },
    { prop: 'day_of_week', name: 'Day' },
    { prop: 'starting_time', name: 'Time' },
    { prop: 'fees', name: 'Fees' }
  ];
  selected = [];
  teachers = true;
  assistants = true;
  students = true;
  /*************/
  constructor(private auth: AuthService, private modalService: NgbModal, private classSer: ClassService,
    private alertService: AlertService, private route: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    if (this.role === 'teacher') {
      this.teachers = false;
    } else if (this.role === 'assistant') {
      this.assistants = false;
    } else if (this.role === 'student') {
      this.students = false;
    }
    this.student_id = this.route.snapshot.paramMap.get('student_id');
    this.getStudentDetails();
    this.getStreams();
    this.getBatch();
  }
  selectClass({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  open(content) {
    this.modalService.open(content);
    this.getClasses();
  }
  getClasses() {
    this.classes = [];
    this.selected = [];
    this.classSer.getClassesOfStudent(this.studentDetails['student_id']).subscribe(success => {
      for (let i = 0; i < success['data'].length; i++) {
        this.classes.push(success['data'][i]);
      }
      this.rows = this.classes;
    }, error => {
      console.log(error);
    });
  }
  openBatch(content) {
    this.modalService.open(content);
    this.getBatch();
  }
  getBatch() {
    this.batches = [];
    this.classSer.getBatches().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.batches.push(data['data'][i]);
      }
      console.log(this.batches);
    }, error => {
      console.log(error);
    });
  }
  updateStudent() {
    console.log(this.studentDetails);
    this.auth.updateStudent(this.studentDetails).subscribe(success => {
      console.log(success);
      this.alertService.success('Update Student Details Successfully');
    }, error => {
      console.log(error);
      this.alertService.danger('Error Update Student Details');
    });
  }
  getStudentDetails() {
    this.spinnerService.show();
    this.auth.getStudentProfile(this.student_id).subscribe(success => {
      this.student = success['data'];
      console.log(this.student);
      this.studentDetails.student_id = this.student.student_id;
      this.studentDetails.firstName = this.student.first_name;
      this.studentDetails.lastName = this.student.last_name;
      this.studentDetails.middleName = this.student.middle_name;
      this.studentDetails.birthday = this.student.birthday.split('T')[0];
      this.studentDetails.email = this.student.email;
      this.studentDetails.address = this.student.address.lines;
      this.studentDetails.city = this.student.address.city;
      this.studentDetails.gender = this.student.gender;
      this.studentDetails.stream = this.student.stream._id;
      this.studentDetails.profile_pic = this.student.profile_pic;
      this.studentDetails.guardianName = this.student.guardian.name;
      this.studentDetails.guardian_land = this.student.guardian.land;
      this.studentDetails.guardian_mobile = this.student.guardian.mobile;
      this.studentDetails.mobile_number = this.student.telephone.number;
      this.studentDetails.mobile = this.student.telephone.mobile;
      this.studentDetails.admission_batch = this.student.admission_batch._id;
      this.studentDetails.admission_date = this.student.admission_date.split('T')[0];
      this.studentDetails.password = this.student.user_id.string_pass;
      this.user_id = this.student.user_id._id;
      this.spinnerService.hide();
    }, error => {
      this.alertService.danger(error.message);
      this.spinnerService.hide();
      console.log(error);
    });
  }
  getStreams() {
    this.streams = [];
    this.classSer.getStreams().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.streams.push(data['data'][i]);
      }
      console.log(this.streams);
    }, error => {
      console.log(error);
    });
  }


  openMessage() {
    $('#messageModal').modal('show');
  }

}
