import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import {url} from '../../environments/url';
import {MessageModle} from '../models/MessageModle';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // headers = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   'Access-Control-Allow-Origin': '*',
  //   'Authorization': 'Bearer ' + localStorage.getItem('token')
  // });

  private jwtHelper = new JwtHelperService();
  constructor(private http: HttpClient) {
  }

  public get_header() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
    return headers;
  }
  // login and register;
  public login(username, password, recaptcha) {
    return this.http.post(url + '/user/login', { 'username': username, 'password': password, 'captcha': recaptcha }).pipe(map(res => res));
  }
  public register(first_name, last_name, password, mobile, otp_id, otp_code, type) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(url + '/user/register', { first_name: first_name, last_name: last_name, 'password': password, 'mobile': mobile, 'otp_id': otp_id, 'otp_code': otp_code,
    'type': type}).pipe(map(res => res));
  }

  public send_otp(mobile, recaptcha, type) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(url + '/user/sendOTP', { 'mobile': mobile, 'captcha': recaptcha, 'type': type }).pipe(map(res => res));
  }

  public get_user_by_username(username, recaptcha) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(url + '/user/getUserByUsername/' + username, {'captcha': recaptcha}).pipe(map(res => res));
  }

  public check_subscription() {
    return this.http.get(url + '/user/checkSubscription', { headers: this.get_header() }).pipe(map(res => res));
  }

  sendMessage(user_id, modle: MessageModle) {
    return this.http
      .post(url + '/user/sendSingleSMS/' + user_id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  public reset_password(username, password, otp_code, otp_id) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(url + '/user/resetPassword/' + username, { 'username': username, password: password, otp_id: otp_id,
    otp_code: otp_code}).pipe(map(res => res));
  }

  public request_payment() {
    return this.http.get(url + '/courses/requestPayment').pipe(map(res => res));
  }

  public payhere(data) {
    console.log(data);
    return this.http.post('https://sandbox.payhere.lk/pay/checkout', data);
  }
  public isAuthenticated(): boolean {
    if (localStorage.getItem('token') !== null) {
      const token = localStorage.getItem('token');
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }

  }
  public getUserProfile(id) {
    return this.http.get(url + '/user/' + id, { headers: this.get_header() }).pipe(map(res => res));
  }
  // get profile details
  public getStudentProfile(id) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(url + '/students/get/' + id, { headers: this.get_header() }).pipe(map(res => res));
  }
  public getBasicStudentProfile(id) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(url + '/students/getBasic/' + id, { headers: this.get_header() }).pipe(map(res => res));
  }
  public getStudentProfileByUser(id) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(url + '/students/getByUser/' + id, { headers: this.get_header() }).pipe(map(res => res));
  }
  public getAllAttendingClassIds(student_id) {
    return this.http.get(url + '/teachers/getAllTeachingClasses/' + student_id, { headers: this.get_header() }).pipe(map(res => res));
  }
  public getTeacherProfile(id) {
    // tslint:disable-next-line:max-line-lengthrun
    return this.http.get(url + '/teachers/get/' + id, { headers: this.get_header() }).pipe(map(res => res));
  }
  public getTeacherProfileByUser(id) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(url + '/teachers/getByUser/' + id, { headers: this.get_header() }).pipe(map(res => res));
  }
  public getAssistantProfile(id) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(url + '/assistants/get/' + id, { headers: this.get_header() }).pipe(map(res => res));
  }

  /*get people*/
  public getStudents() {
    return this.http.get(url + '/students/', { headers: this.get_header() }).pipe(map(res => res));
  }
  public getClassStudents(class_id) {
    return this.http.get(url + '/classes/get-assigned-students/' + class_id, { headers: this.get_header() }).pipe(map(res => res));
  }
  public getTeachers() {
    return this.http.get(url + '/teachers/', { headers: this.get_header() }).pipe(map(res => res));
  }
  public getAllTeachingClassIds(user_id) {
    return this.http.get(url + '/teachers/getAllTeachingClasses/' + user_id, { headers: this.get_header() }).pipe(map(res => res));
  }
  public getAllTeachingStudents() {
    return this.http.get(url + '/teachers/getAllStudents', { headers: this.get_header() }).pipe(map(res => res));
  }
  public getAllTeachingSubjects() {
    return this.http.get(url + '/teachers/getAllTeachingSubjects', { headers: this.get_header() }).pipe(map(res => res));
  }
  public getAssistants() {
    return this.http.get(url + '/assistants/', { headers: this.get_header() }).pipe(map(res => res));
  }

  public updateStudentByUserId(personal_info, class_info) {
    return this.http.post(url + '/students/updateInfo',
      {
        'first_name': personal_info.first_name,
        'last_name': personal_info.last_name,
        'birthday': personal_info.birthday,
        'gender': personal_info.gender,
        'email': personal_info.email,
        'address': personal_info.address,
        'admission_batch': class_info.admission_batch,
        'stream': class_info.stream
      }, { headers: this.get_header() }).pipe(map(res => res));
  }

  public updateTeacherByUserId(personal_info, teaching_info) {
    return this.http.post(url + '/teachers/updateInfo',
      {
        'first_name': personal_info.firstName,
        'last_name': personal_info.lastName,
        'birthday': personal_info.birthday,
        'gender': personal_info.gender,
        'email': personal_info.email,
        'address': personal_info.address,
        'nic': personal_info.nic,
        'bookable': teaching_info.bookable,
        'description': teaching_info.description,
        'mbt': teaching_info.mbt,
        'subjects': teaching_info.subjects,
      }, { headers: this.get_header() }).pipe(map(res => res));
  }

  /* add student */
  public addStudent(admissionDate, personalDetails, GuardianDetails, batchDetails, stream, card_number, Classes) {
    const class_info = [];
    const class_template = { 'class_id': '', 'fees_rate': 1 };
    for (let i = 0; i < Classes.length; i++) {
      class_template.class_id = Classes[i]['_id'];
      class_template.fees_rate = Classes[i]['fees_rate'];
      class_info.push(class_template);
    }
    return this.http.post(url + '/students/add',
      {
        'first_name': personalDetails.firstName,
        'middle_name': personalDetails.middle_name,
        'last_name': personalDetails.lastName,
        'address': { 'lines': personalDetails.address, 'city': personalDetails.city },
        'birthday': personalDetails.birthday,
        'email': personalDetails.email,
        'card_number': card_number,
        'telephone': { 'number': personalDetails.telephone, 'mobile': personalDetails.mobilePhone },
        'guardian': { 'name': GuardianDetails.name, 'mobile': GuardianDetails.mobile, 'land': GuardianDetails.land},
        'admission_date': new Date(admissionDate),
        'admission_batch': batchDetails,
        'current_batch': batchDetails,
        'stream': stream,
        'gender': personalDetails.gender,
        'class_info': class_info
      }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* update student */
  public updateStudent(personalDetails) {
    return this.http.post(url + '/students/update/' + personalDetails.student_id,
      {
        'first_name': personalDetails.first_name,
        'birthday': personalDetails.birthday,
        'last_name': personalDetails.last_name,
        'address': { 'lines': personalDetails.address.lines, 'city': personalDetails.address.city },
        'email': personalDetails.email,
        'telephone': { 'number': personalDetails.telephone, 'mobile': false },
        'guardian': { 'name': personalDetails.guardian.name, 'telephone': personalDetails.guardian.telephone },
        'emergency_contact': personalDetails.emergency_contact,
        'admission_date': '2019-03-20',
        'admission_batch': personalDetails.admission_batch,
        'current_batch': personalDetails.current_batch,
        'class_info': [{ 'class_id': '', 'fees_rate': 1 }]
      }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* Delete Student */
  deleteStudent(std_id) {
    return this.http.delete(url + '/students/delete/' + std_id, { headers: this.get_header() }).pipe(map(res => res));
  }

  /* add teacher */
  public addTeacher(personalDetails, teachingDetails) {
    return this.http.post(url + '/teachers/add',
      {
        'first_name': personalDetails.firstName,
        'last_name': personalDetails.lastName,
        'gender': personalDetails.gender,
        'address': personalDetails.address,
        'city': personalDetails.city,
        'email': personalDetails.email,
        'telephone': personalDetails.telephone,
        'nic': personalDetails.nic,
        'admission_date': personalDetails.admissiondate,
        'birthday': personalDetails.birthday,
        'description': teachingDetails.description,
        'bookable': teachingDetails.bookable,
        'mbt': teachingDetails.mbt,
        'subjects': teachingDetails.subjects
      }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* Edit Teacher */
  public editTeacher(personalDetails) {
    return this.http.post(url + '/teachers/update/' + personalDetails['teacher_id'],
      {
        'first_name': personalDetails['first_name'],
        'last_name': personalDetails['last_name'],
        'address': personalDetails['address']['lines'],
        'city': personalDetails['address']['city'],
        'email': personalDetails['email'],
        'telephone': personalDetails['telephone'],
        'nic': personalDetails['nic'],
        'admission_date': personalDetails['admission_date'],
        'birthday': personalDetails['birthday']
      }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* Delete teacher */
  deleteTeacher(tea_id) {
    return this.http.delete(url + '/teachers/delete/' + tea_id, { headers: this.get_header() }).pipe(map(res => res));
  }

  /* add assistant */
  public addAssistant(personalDetails) {
    return this.http.post(url + '/assistants/add',
      {
        'first_name': personalDetails.firstName,
        'last_name': personalDetails.lastName,
        'address': personalDetails.address,
        'city': personalDetails.city,
        'email': personalDetails.email,
        'telephone': personalDetails.telephone,
        'nic': personalDetails.nic,
        'admission_date': personalDetails.admissiondate,
        'birthday': personalDetails.birthday
      }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* Delete assistant */
  deleteAssistant(ass_id) {
    return this.http.delete(url + '/assistants/delete/' + ass_id, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* Edit Assistant */
  public editAssistant(personalDetails) {
    return this.http.post(url + '/assistants/update/' + personalDetails['assistant_id'],
      {
        'first_name': personalDetails['first_name'],
        'last_name': personalDetails['last_name'],
        'address': personalDetails['address']['lines'],
        'city': personalDetails['address']['city'],
        'email': personalDetails['email'],
        'telephone': personalDetails['telephone'],
        'nic': personalDetails['nic'],
        'admission_date': personalDetails['admission_date'],
        'birthday': personalDetails['birthday']
      }, { headers: this.get_header() }).pipe(map(res => res));
  }

  /* assign card number for a student */
  public assignCard(student_id, card_number) {
    return this.http.post(url + '/students/assignCard/' + student_id,
      {
        'card_number': card_number
      }, { headers: this.get_header() }).pipe(map(res => res));
  }
}


