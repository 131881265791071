import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {url} from '../../environments/url';

@Injectable({
  providedIn: 'root'
})
export class AttendenceService {
  // headers = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   'Access-Control-Allow-Origin': '*',
  //   'Authorization': 'Bearer ' + localStorage.getItem('token')
  // });

  constructor(private http: HttpClient) { }

  public get_header() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
    return headers;
  }

  search_student(keyword, std_id, enabled_classes) {
    return this.http.post(url + '/attendance/searchStudent',
      {keyword: keyword, student_id: std_id, enabled_classes: enabled_classes},
      { headers: this.get_header() }).pipe(map(res => res));
  }

  search_and_mark_student(keyword, std_id, enabled_classes, class_sessions) {
    return this.http.post(url + '/attendance/searchAndMarkStudent',
      {keyword: keyword, student_id: std_id, enabled_classes: enabled_classes, class_sessions: class_sessions},
      { headers: this.get_header() }).pipe(map(res => res));
  }
  public mark(student_id, class_id, class_session_id) {
    return this.http.post(url + '/attendance/mark',
      {
        'student_id': student_id,
        'class_id': class_id,
        'class_session_id': class_session_id
      }, { headers: this.get_header() }).pipe(map(res => res));
  }
  public top_up(user_id, class_id, product_id, amount) {
    return this.http.post(url + '/attendance/topup',
      {
        'user_id': user_id,
        'class_id': class_id,
        'product_id': product_id,
        'amount': amount
      }, { headers: this.get_header() }).pipe(map(res => res));
  }

  public mark_and_top_up(student_id, class_id, class_session_id, user_id, product_id, amount) {
    return this.http.post(url + '/attendance/markAndTopup',
      {
        'student_id': student_id,
        'class_session_id': class_session_id,
        'user_id': user_id,
        'class_id': class_id,
        'product_id': product_id,
        'amount': amount
      }, { headers: this.get_header() }).pipe(map(res => res));
  }

  public get_enabled_classes(date) {
    console.log(date);
    return this.http.get(url + '/attendance/getEnabledClasses', { params: {date: date},
      headers: this.get_header() }).pipe(map(res => res));
  }
}
