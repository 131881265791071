export class MeetingModle {
  _id;
  class_id: string;
  section_id: string;
  session_id: string;
  vertical_loc: number;
  horizontal_loc: number;
  visible_type: string;
  show: boolean;
  name: string;
  from: Date;
  to: Date;
  enable_from: DateObject;
  disable_from: DateObject;
  scheduled: boolean;
  meeting_id: string;
  zoom_id: number;
  zoom_password: string;
  zoom_link: string;

  constructor(classId, sectionId, sessionId, verticlaLoc) {
    this.class_id = classId;
    this.section_id = sectionId;
    this.session_id = sessionId;
    this.vertical_loc = verticlaLoc;
    this.horizontal_loc = 1;
    this.show = true;
    this.from = new Date();
    this.to = new Date();
    this.scheduled = false;
    this.meeting_id = '';
    this.enable_from = new DateObject();
    this.disable_from = new DateObject();
  }
}
class DateObject {
  active: boolean;
  date: Date;
  constructor() {
    this.active = false;
    this.date = new Date();
  }
}
