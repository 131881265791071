export class QuizModle {
    _id;
    class_id: string;
    section_id: string;
    session_id: string;
    visible_type: string;
    vertical_loc: number;
    horizontal_loc: number;
    show: boolean;
    enable_from: DateObject;
    disable_from: DateObject;
    name: string;
    availability: boolean;
    file: string;
    no_of_questions: number;
    answers_per_question: number;
    answer_numbering: string;
    allowed_time: number;
    no_of_attempts: number;
    display_type: string;
    answers: Answers[];
    allow_date: number;
    due_date: number;
    amount: number;
    component_id: string;
    mcq_id: string;

    constructor(classId, sectionId, sessionId, verticalLoc) {
        this.class_id = classId;
        this.section_id = sectionId;
        this.session_id = sessionId;
        this.vertical_loc = verticalLoc;
        this.amount = 0;
        this.horizontal_loc = 1;
        this.show = true;
        this.enable_from = new DateObject();
        this.disable_from = new DateObject();
        this.answers = [];
        this.allow_date = new Date().getTime();
        this.due_date = new Date().getTime();
    }
}

export class DateObject {
    active: boolean;
    date: number;

    constructor() {
        this.active = false;
        this.date = new Date().getTime();
    }
}

export class Answers {
    question: number;
    answers: number[];
    answerFormat: AnswerFormat[];
    type: string;
    valid: boolean;

    constructor(question, answerFormat, type) {
        this.question = question;
        this.answers = [];
        this.answerFormat = answerFormat;
        this.type = type;
        this.valid = false;
    }
}

export class AnswerFormat {
    answer: number;
    value: boolean;

    constructor(answer, value) {
        this.answer = answer;
        this.value = value;
    }
}
