import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'ngx-alerts';
import { ClassService } from 'src/app/services/class.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-student-classes',
  templateUrl: './edit-student-classes.component.html',
  styleUrls: ['./edit-student-classes.component.scss']
})
export class EditStudentClassesComponent implements OnInit {
  focus: any;
  searchTxt = '';
  student_id;
  classes = [];
  enrolled_ids = [];
  enrolled_classes = [];
  not_enrolled_classes = [];
  selectedValue = 'bySub';
  role = '';
  profile_pic = 'edit-student-profile.png';
  allClassesWorking = [];
  /* data tabel for classes working*/
  rows = [];
  rows_ = [];
  editing = {};
  columns_ = [
    { prop: 'subject_id.subject', name: 'Subject' },
    { prop: 'batch_id.year', name: 'Batch' },
    { prop: 'teacher_id.first_name', name: 'Teacher' },
    { prop: 'day_of_week', name: 'Day' },
    { prop: 'starting_time', name: 'Time' },
    { prop: 'fees', name: 'Fees' },
    { prop: '', name: 'Fees rate' }
  ];
  columns = [
    { prop: 'subject_id.subject', name: 'Subject' },
    { prop: 'batch_id.year', name: 'Batch' },
    { prop: 'teacher_id.first_name', name: 'Teacher' },
    { prop: 'day_of_week', name: 'Day' },
    { prop: 'starting_time', name: 'Time' },
    { prop: 'fees', name: 'Fees' },
    { prop: 'fees_rate', name: 'Fees rate' }
  ];
  selected = [];
  selected_ = [];
  student: any;
  constructor(private modalService: NgbModal,
    private auth: AuthService,
    private alertService: AlertService,
    private classSer: ClassService, private spinnerService: Ng4LoadingSpinnerService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.student_id = this.route.snapshot.paramMap.get('student_id');
    this.getStudentProfile();
    this.getClasses();
  }
  selectClass({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    console.log(this.selected);
  }
  selectClassNotIn({ selected }) {
    this.selected_.splice(0, this.selected_.length);
    this.selected_.push(...selected);
    console.log(this.selected_);
  }
  getClasses() {
    this.classes = [];
    this.enrolled_classes = [];
    this.not_enrolled_classes = [];
    this.selected_ = [];
    this.selected = [];
    this.classSer.getClasses().subscribe(success => {
      this.classes = success['data'];
      console.log(this.classes);
      this.getEnrolledClasses();
    }, error => {
      console.log(error);
      this.alertService.danger('Could not retrieve the classes');
    });
  }
  getEnrolledClasses() {
    this.classSer.getClassesOfStudent(this.student_id).subscribe(success => {
      this.enrolled_ids = success['data'];
      console.log(this.enrolled_ids);
      for (let j = 0; j < this.classes.length; j++) {
        let flag = true;
        for (let i = 0; i < success['data'].length; i++) {
          if (success['data'][i]['class_id']['_id'] === this.classes[j]['_id']) {
            flag = false;
            let ele = this.classes[j];
            ele.fees_rate = success['data'][i]['fees_rate'];
            this.enrolled_classes.push(ele);
            break;
          }
        }
        if (flag) {
          let ele = this.classes[j];
          ele.fees_rate = 1;
          this.not_enrolled_classes.push(ele);
        }
      }
      console.log(this.enrolled_classes);
      console.log(this.not_enrolled_classes);
      this.rows = this.enrolled_classes;
      this.rows_ = this.not_enrolled_classes;
    }, error => {
      console.log(error);
    });
  }
  getStudentProfile() {
    this.spinnerService.show();
    this.auth.getStudentProfile(this.student_id).subscribe(success => {
      this.spinnerService.hide();
      this.student = success['data'];
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while student Data');
    });
  }
  search() {
    if (this.searchTxt !== '') {
      this.classes = [];
      this.selected = [];
      this.rows_ = [];
      if (this.selectedValue === 'bySub') {
        this.classSer.getClassesBySubject(this.searchTxt).subscribe(success => {
          for (let i = 0; i < success['data'].length; i++) {
            for (let j = 0; j < this.allClassesWorking.length; j++) {
              if (this.allClassesWorking[j]['class_id'] === success['data'][i]['_id']) {
                break;
              } else if (j === (this.allClassesWorking.length - 1)) {
                this.classes.push(success['data'][i]);
              }
            }
          }
          this.rows_ = this.classes;
        }, error => {
          console.log(error);
        });
      } else if (this.selectedValue === 'byTeacher') {
        this.classSer.getClassesByTeacher(this.searchTxt).subscribe(success => {
          for (let i = 0; i < success['data'].length; i++) {
            for (let j = 0; j < this.allClassesWorking.length; j++) {
              if (this.allClassesWorking[j]['class_id'] === success['data'][i]['_id']) {
                break;
              } else if (j === (this.allClassesWorking.length - 1)) {
                this.classes.push(success['data'][i]);
              }
            }
          }
          this.rows_ = this.classes;
        }, error => {
          console.log(error);
        });
      } else if (this.selectedValue === 'byBatch') {
        this.classSer.getClassesByBatch(this.searchTxt).subscribe(success => {
          for (let i = 0; i < success['data'].length; i++) {
            for (let j = 0; j < this.allClassesWorking.length; j++) {
              if (this.allClassesWorking[j]['class_id'] === success['data'][i]['_id']) {
                break;
              } else if (j === (this.allClassesWorking.length - 1)) {
                this.classes.push(success['data'][i]);
              }
            }
          }
          this.rows_ = this.classes;
        }, error => {
          console.log(error);
        });
      } else {
        this.getEnrolledClasses();
      }
    } else {
      this.getEnrolledClasses();
    }
  }
  unenrollClass() {
    if (this.selected.length > 0) {
      this.spinnerService.show();
      this.classSer.deassignStudent(this.student_id, this.selected[0]).subscribe(success => {
        console.log(success);
        this.selected = [];
        this.getClasses();
        this.spinnerService.hide();
        this.alertService.success('Student Deassigend Successfully');
      }, error => {
        console.log(error);
        this.selected = [];
        this.spinnerService.hide();
        this.alertService.danger('Error While Deassigning Student');
      });
    } else {
      this.alertService.warning('Please Select a Class to Deassign');
    }
  }
  enroll() {
    if (this.selected_.length > 0) {
      this.spinnerService.show();
      this.classSer.assignStudent(this.student_id, this.selected_[0]).subscribe(success => {
        console.log(success);
        this.selected_ = [];
        this.getClasses();
        this.spinnerService.hide();
        this.alertService.success('Student Assigend Successfully');
      }, error => {
        console.log(error);
        this.selected_ = [];
        this.spinnerService.hide();
        this.alertService.danger('Error While Assigning Student');
      });
    } else {
      this.alertService.warning('Please Select a Class to Aassign');
    }
  }

  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows_[rowIndex][cell] = event.target.value;
    this.rows_ = [...this.rows_];
  }

}
