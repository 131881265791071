import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ClassService} from 'src/app/services/class.service';
import {AlertService} from 'ngx-alerts';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {url} from '../../../../environments/url';
import {CourseService} from '../../../services/course.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from 'src/app/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-mcq-page',
  templateUrl: './mcq-page.component.html',
  styleUrls: ['./mcq-page.component.css']
})
export class McqPageComponent implements OnInit {
  subject;
  batch;
  role;
  base_url = url;

  mcqMode = new BehaviorSubject(false);
  // TODO one should be deleted
  mcqMeta: any;
  submissions: any;

  selectedMCQ: number;
  mcq: boolean[][];
  @ViewChild('pdfViewer') public pdfViewer;

  mcqOpt = ['a', 'b', 'c', 'd', 'e', 'f'];
  numbers: number[][];
  started: boolean;
  quizId: string;
  currentAttempt: any;
  attempts: any;
  canContinue = false;
  student_id: any;
  isStudent = false;
  submissionCount: number;
  reviewMode = false;

  private countDownSub: Subscription;
  public deadline: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;
  allowedTime: any;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;

  isSubmit = false;
  submitting = new BehaviorSubject(false);

  readonly WARNING_THRESHOLD: number = 5;

  moment: any = moment;
  filterType: any;
  filterText: any;
  sort: any;
  filtered: any[];

  constructor(
    @Inject(DOCUMENT) document,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private classSer: ClassService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.quizId = this.route.snapshot.paramMap.get('mcq_id');
    this.role = localStorage.getItem('role');
    this.isStudent = this.role === 'student';
    this.started = false;
    if (this.isStudent) {
      this.getStudentProfile();
    }
    this.getMCQ(this.quizId);
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    this.filterType = 'all';
    this.filterText = '';
    this.sort = 'desc';
  }

  ngOnDestroy() {
    if (this.countDownSub) {
      this.countDownSub.unsubscribe();
    }
  }

  getMCQ(quizId: string) {
    this.spinnerService.show();
    this.courseService.getMCQById(quizId).subscribe(
      (response) => {
        this.mcqMeta = response.data.mcq;
        this.submissionCount = response.data.submission_count;
        this.spinnerService.hide();
        this.allowedTime = {'hour': this.addLeadingZero(Math.floor(this.mcqMeta.allowed_time / 60)), 'minute': this.addLeadingZero(this.mcqMeta.allowed_time % 60)};
        if (this.isStudent && this.student_id) {
          this.getAttempts();
        } else {
          this.getSubmissionInfo();
        }
        this.setUpMCQ(null);
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting MCQ Info');
      }
    );
  }

  getAttempts() {
    this.courseService.getMCQAttempts(this.mcqMeta._id, this.student_id).subscribe(
      (response) => {
        this.attempts = response['data'].attempts;
        if (this.attempts.length > 0 ) {
          let lastAttempt = this.attempts[this.attempts.length-1];
          let lastAttemptExpired =  moment(lastAttempt.started_on).add(this.mcqMeta['allowed_time'], 'm').isSameOrBefore(moment());
          this.canContinue = !lastAttempt.finished && !lastAttemptExpired;
        }
      },
      (error) => {
        this.alertService.danger('Error occurred while getting attempts');
      }
    )
  }

  setUpMCQ(answers: any) {
    this.selectedMCQ = 1;
    this.mcq = [];
    let numberRow= 0;
    this.numbers=[];
    this.numbers[numberRow] = [];
    let noPerRow = 10;
    for(let i=0; i < this.mcqMeta.no_of_questions; i++) {
      if (i%noPerRow == 0) {
        numberRow++;
        this.numbers[numberRow] = [];
      }
      this.numbers[numberRow][i%noPerRow] = i+1;
      this.mcq[i] = [];
      for(let j=0; j < this.mcqMeta.answers_per_question; j++) {
        if (answers) {
          this.mcq[i][j] = answers[i+1].indexOf(j+1) != -1
        } else {
          this.mcq[i][j] = false;
        }
      }
    }
  }

  addLeadingZero(value: number) {
    return (value < 10) ? '0' + value : value;
  }

  attempt() {
    // if last attempt is not finished and time remaining
    this.reviewMode = false;
    this.started = false;
    if (this.canContinue) {
      this.started = true;
      this.currentAttempt = this.attempts[this.attempts.length-1];
      this.setPreviousAnswers();
      this.mcqMode.next(true);
      this.downloadPdf();
    } else {
      this.courseService.attemptMCQ(this.mcqMeta._id, this.student_id).subscribe(
        (res) => {
          this.currentAttempt = res['data'];
          this.mcqMode.next(true);
          this.downloadPdf();
        }, error => {
          console.log(error);
          this.spinnerService.hide();
          this.alertService.danger(error['error']['message']);
        }
      );
    }
  }

  previewMCQ() {
    this.started = false;
    this.reviewMode = false;
    this.mcqMode.next(true);
    this.downloadPdf();
  }

  setPreviousAnswers() {
    this.setUpMCQ(this.currentAttempt.answers);
  }

  downloadPdf() {
    this.courseService.downloadFile(this.mcqMeta.file._id).subscribe(
      (res) => {
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        if (!this.reviewMode) {
          this.startCountDown();
        }
      }
    );
  }

  startCountDown() {
    const startedOn = (this.isStudent) ? this.currentAttempt.started_on : new Date();
    this.deadline = moment(startedOn).add(this.mcqMeta['allowed_time'], 'm').toDate();
    this.countDownSub = interval(1000)
      .subscribe(x => { this.getTimeDifference(); });
  }

  nextQ() {
    this.selectedMCQ++;
    this.cdr.detectChanges();
  }

  prevQ() {
    this.selectedMCQ--;
    this.cdr.detectChanges();
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: 'quick-navi'}).result.then((result) => {
      if (result != -1) {
        this.selectedMCQ = result;
      } else {
        this.alertService.success('Submitting Answers... Redirecting back');
        this.submitAnswers(true);
      }
    }, (reason) => {

    });
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  getStudentProfile() {
    let user_id = localStorage.getItem("user_id");
    this.auth.getStudentProfileByUser(user_id).subscribe(
      (success) => {
        this.student_id = success["data"]["_id"];

      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger(error['error']['message']);
      }
    );
  }

  getSubmissionInfo() {
    this.courseService.getMCQSubmissions(this.mcqMeta._id).subscribe(
      (response) => {
        this.submissions = response['data'];
        this.sortMarks('desc');
      },
      (error) => {
        this.alertService.danger('Error occurred while getting submission Info');
      }
    );
  }

  submitAnswers(finished: boolean) {
    if (!this.isStudent) {
      return;
    }
    this.submitting.next(true);
    let answers = {};
    for (let i=0; i< this.mcq.length; i++) {
      answers[i+1] = this.getTrueIndexesArray(i+1);
    }
    this.courseService.submitAnswers(this.mcqMeta._id, this.currentAttempt._id, answers, finished).subscribe(
      (success) => {
        this.submitting.next(false);
        if (finished) {
          this.getMCQ(this.quizId);
          this.mcqMode.next(false);
        }
      },
      (error) => {
        this.alertService.danger(error['error']['message']);
      }
    );
  }

  private getTimeDifference() {
    if (this.deadline) {
      this.timeDifference = this.deadline.getTime() - new Date().getTime();
      if (this.timeDifference > 0) {
        this.allocateTimeUnits(this.timeDifference);
      } else {
        if (this.isStudent) {
          this.alertService.success('Submitting Answers... Redirecting back');
          this.ngOnDestroy();
          this.submitAnswers(true);
        }
      }
    }
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = this.addLeadingZero(Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute));
    this.minutesToDday = this.addLeadingZero(Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute));
    this.hoursToDday = this.addLeadingZero(Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay));
  }

  getFormatedDateTime(datetime: string) {
    return moment(datetime).format('DD/MM/YYYY hh:mm a');
  }

  isMarked(mcqNo: number): boolean {
    return this.mcq[mcqNo-1].some(item => item);
  }


  calcTotalUnmarkedAnswers(): number {
    let count = 0;
    this.mcq.forEach(theMCQ => {
      if (!theMCQ.some(item => item)) {
        count++;
      }
    });
    return count;
  }

  review(theAttempt: any) {
    this.started = true;
    this.reviewMode = true;
    this.mcqMode.next(true);
    this.currentAttempt = theAttempt;
    this.downloadPdf();
    this.setUpMCQ(theAttempt.answers);
  }

  returnBack() {
    this.mcqMode.next(false);
  }

  isRightAns(questionIndex: number, choiceIndex: number): boolean {
    return this.mcqMeta.answers[questionIndex] && (this.mcqMeta.answers[questionIndex].answers.indexOf(choiceIndex+1) !== -1);
  }

  isCorrectAns (questionNo: number): boolean {
    return this.mcqMeta.answers[questionNo-1] && JSON.stringify(this.mcqMeta.answers[questionNo-1].answers) === JSON.stringify(this.getTrueIndexesArray(questionNo));
  }

  getTrueIndexesArray(questionNo) {
    return this.mcq[questionNo-1].reduce(
      (out, bool, index) => bool ? out.concat(index+1) : out,
      []
    );
  }

  isClosingSoon() {
    return  this.hoursToDday === '00' && (+this.minutesToDday < this.WARNING_THRESHOLD)
  }

  reviewSubmission(submission: any) {
    this.started = true;
    this.reviewMode = true;
    this.mcqMode.next(true);
    this.currentAttempt = submission;
    this.downloadPdf();
    this.setUpMCQ(submission.answers);
  }

  sortMarks(direction:string) {
    let x = direction == 'desc' ? -1: 1;
    if (this.submissions) {
      this.submissions = [...this.submissions.sort((a, b) => {
        if (a['marks'] > b['marks']) {
          return (1 * x);
        } else if (a['marks'] === b['marks']) {
          return 0;
        }
        return (-1 * x);
      })];
    }
  }

}
