import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  classes = [{batch: "2020 A/L", group: "Theory Group I", stream: "Science", teacher: "Mr. Ariyarathna", subject: "Chemistry", day: "Saturday", time: "1 pm - 5 pm", image: "../../assets/img/avatar/ariyarathna.jpg", background: "../../assets/img/courses/chemistry-class-1.png", location: "Hall C", fees: 1500},
    {batch: "2020 A/L", group: "Theory Group I", stream: "Science", teacher: "Mr. Dilan Mabharana", subject: "Physics", day: "Sunday", time: "8 am - 12 pm", image: "../../assets/img/avatar/question-male-1.jpg", background: "../../assets/img/courses/physics-class-1.jpg", location: "Hall D", fees: 1900},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Science", teacher: "Mr. Dilan Mabharana", subject: "Physics", day: "Sunday", time: "8 am - 12 pm", image: "../../assets/img/avatar/question-male-1.jpg", background: "../../assets/img/courses/physics-class-1.jpg", location: "Hall D", fees: 1300},
    {batch: "2020 A/L", group: "Revision", stream: "Science", teacher: "Mr. Dilan Mabharana", subject: "Physics", day: "Sunday", time: "8 am - 12 pm", image: "../../assets/img/avatar/question-male-1.jpg", background: "../../assets/img/courses/physics-class-1.jpg", location: "Hall D", fees: 1700},
    {batch: "2020 A/L", group: "Theory Group I", stream: "Maths", teacher: "Mr. Ravindra Krasinghearachchi", subject: "Applied Mathematics", day: "Saturday", time: "8 am - 12 pm", image: "../../assets/img/avatar/question-male-1.jpg", background: "../../assets/img/courses/maths-class-1.jpg", location: "Hall D", fees: 1300},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Maths", teacher: "Mr. Ravindra Krasinghearachchi", subject: "Applied Mathematics", day: "Saturday", time: "8 am - 12 pm", image: "../../assets/img/avatar/question-male-1.jpg", background: "../../assets/img/courses/maths-class-1.jpg", location: "Hall D", fees: 1300},
    {batch: "2020 A/L", group: "Theory Group I", stream: "Technology", teacher: "Mr. Janaka Wickramage", subject: "ICT", day: "", time: "", image: "../../assets/img/avatar/JanakaICT.jpg", background: "../../assets/img/courses/ICT-class-1.jpg", location: "Hall A", fees: 1800},
    {batch: "2020 A/L", group: "Revision", stream: "Technology", teacher: "Mr. Janaka Wickramage", subject: "ICT", day: "", time: "", background: "../../assets/img/courses/ICT-class-1.jpg", image: "../../assets/img/avatar/JanakaICT.jpg", location: "Hall A", fees: 1400},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Technology", teacher: "Mr. Janaka Wichramage", subject: "ICT", day: "", time: "", background: "../../assets/img/courses/ICT-class-1.jpg", image: "../../assets/img/avatar/JanakaICT.jpg", location: "Hall A", fees: 1400},
    {batch: "2020 A/L", group: "Theory Group I", stream: "Technology", teacher: "Mr. Pradeep Darshana", subject: "ET", day: "", time: "", background: "../../assets/img/courses/ET-class-1.jpg", image: "../../assets/img/avatar/PradeepDarshana.jpg", location: "Hall A", fees: 2000},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Technology", teacher: "Mr. Pradeep Darshana", subject: "ET", day: "", time: "", background: "../../assets/img/courses/ET-class-1.jpg", image: "../../assets/img/avatar/PradeepDarshana.jpg", location: "Hall A", fees: 1500},
    {batch: "2020 A/L", group: "Theory Group I", stream: "Technology", teacher: "Mr. Roshan Samarasinghe", subject: "BST", day: "", time: "", background: "../../assets/img/courses/BST-class-1.jpg", image: "../../assets/img/avatar/Roshan.jpg", location: "Hall D", fees: 1800},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Technology", teacher: "Mr. Roshan Samarasinghe", subject: "BST", day: "", time: "", background: "../../assets/img/courses/BST-class-1.jpg", image: "../../assets/img/avatar/Roshan.jpg", location: "Hall D", fees: 1300},
    {batch: "2020 A/L", group: "Theory Group I", stream: "Science", teacher: "Mr. Srimal", subject: "Physics", day: "", time: "", background: "../../assets/img/courses/physics-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 1800},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Science", teacher: "Mr. Srimal", subject: "Physics", day: "", time: "", background: "../../assets/img/courses/physics-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall B", fees: 1300},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Maths", teacher: "Mr. Sugith Shalika", subject: "Pure Mathematics", day: "", time: "", background: "../../assets/img/courses/maths-class-1.jpg", image: "../../assets/img/avatar/Sugith.jpg", location: "Hall E", fees: 1300},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Science", teacher: "Mr. Waruna Dhanushka", subject: "Physics", day: "", time: "", background: "../../assets/img/courses/physics-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall E", fees: 1500},
    {batch: "2020 A/L", group: "Theory Group I", stream: "Business", teacher: "Mrs. Chandra", subject: "Business Studies", day: "", time: "", background: "../../assets/img/courses/business-class-1.jpg", image: "../../assets/img/avatar/question-female.jpg", location: "Hall C", fees: 2000},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Business", teacher: "Mrs. Chandra", subject: "Business Studies", day: "", time: "", background: "../../assets/img/courses/business-class-1.jpg", image: "../../assets/img/avatar/question-female.jpg", location: "Hall C", fees: 1500},
    {batch: "2021 A/L", group: "Theory Group I", stream: "Business", teacher: "Mr. Shantha", subject: "Economics", day: "", time: "", background: "../../assets/img/courses/business-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall E", fees: 1500},
    {batch: "2021 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Kusal Ahalaarachchi", subject: "ICT", day: "", time: "", background: "../../assets/img/courses/ICT-class-2.jpg", image: "../../assets/img/avatar/Kusal.jpg", location: "Hall B", fees: 700},
    {batch: "2022 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Kusal Ahalaarachchi", subject: "ICT", day: "", time: "", background: "../../assets/img/courses/ICT-class-2.jpg", image: "../../assets/img/avatar/Kusal.jpg", location: "Hall B", fees: 700},
    {batch: "2021 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Jaanaka", subject: "Mathematics", day: "", time: "", background: "../../assets/img/courses/maths-class-2.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2022 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Jaanaka", subject: "Mathematics", day: "", time: "", background: "../../assets/img/courses/maths-class-2.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2021 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Aruna Malaweera", subject: "English", day: "", time: "", background: "../../assets/img/courses/english-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2022 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Aruna Malaweera", subject: "English", day: "", time: "", background: "../../assets/img/courses/english-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2021 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Yaanaka", subject: "History", day: "", time: "", background: "../../assets/img/courses/history-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2022 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Yaanaka", subject: "History", day: "", time: "", background: "../../assets/img/courses/history-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2021 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Harendra", subject: "Science", day: "", time: "", background: "../../assets/img/courses/science-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2022 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Harendra", subject: "Science", day: "", time: "", background: "../../assets/img/courses/science-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2021 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Priyantha", subject: "Tamil", day: "", time: "", background: "../../assets/img/courses/tamil-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2022 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Priyantha", subject: "Tamil", day: "", time: "", background: "../../assets/img/courses/tamil-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 700},
    {batch: "2021 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Srimal", subject: "English", day: "", time: "", background: "../../assets/img/courses/english-class-2.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 1000},
    {batch: "2022 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Srimal", subject: "English", day: "", time: "", background: "../../assets/img/courses/english-class-2.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 1000},
    {batch: "2021 O/L", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Chaminda Sudusinghe", subject: "Business Studies", day: "", time: "", background: "../../assets/img/courses/business-class-2.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall E", fees: 700},
    {batch: "Grade 7", group: "Theory Group I", stream: "Ordinary", teacher: "Mr. Ajith", subject: "Science", day: "", time: "", background: "../../assets/img/courses/science-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall 1", fees: 1000},
    {batch: "Grade 6", group: "Theory English Medium", stream: "Ordinary", teacher: "Mr. Tharika", subject: "Mathematics", day: "", time: "", background: "../../assets/img/courses/maths-class-2.jpg", image: "../../assets/img/avatar/question-female.jpg", location: "Hall 1", fees: 700},
    {batch: "Grade 7", group: "Theory English Medium", stream: "Ordinary", teacher: "Mrs. Tharika", subject: "Mathematics", day: "", time: "", background: "../../assets/img/courses/maths-class-2.jpg", image: "../../assets/img/avatar/question-female.jpg", location: "Hall 1", fees: 700},
    {batch: "Grade 6", group: "Theory English Medium", stream: "Ordinary", teacher: "Mrs. Wijayangani", subject: "Science", day: "", time: "", background: "../../assets/img/courses/science-class-1.jpg", image: "../../assets/img/avatar/question-female.jpg", location: "Hall 1", fees: 700},
    {batch: "Grade 7", group: "Theory English Medium", stream: "Ordinary", teacher: "Mrs. Wijayangani", subject: "Science", day: "", time: "", background: "../../assets/img/courses/science-class-1.jpg", image: "../../assets/img/avatar/question-female.jpg", location: "Hall 1", fees: 700},
    {batch: "Grade 5", group: "Theory Group I", stream: "Primary", teacher: "Mr. Suranga Halpage", subject: "Scholarship", day: "", time: "", background: "../../assets/img/courses/scholarship-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall B", fees: 1000},
    {batch: "Grade 5", group: "Paper Class", stream: "Primary", teacher: "Mr. Suranga Halpage", subject: "Scholarship", day: "", time: "", background: "../../assets/img/courses/scholarship-class-1.jpg", image: "../../assets/img/avatar/question-male-1.jpg", location: "Hall B", fees: 250}];

  panel1_classes = [];
  panel2_classes = [];

  stream_descriptions = [{stream: "Science", description: "", image: "", cart_title: "Advanced level Science"},
    {stream: "Maths", description: "", image: "", cart_title: "Advanced level Maths"},
    {stream: "Technology", description: "", image: "", cart_title: "Advanced level Technology"},
    {stream: "Business Studies", description: "", image: "", cart_title: "Advanced level Science"},
    {stream: "Ordinary", description: "Ordinary level classes for students who are in grade 6 to 11 are  held in our institution", image: "../../assets/img/courses/ol.jpg", cart_title: "Ordinary Level"},
    {stream: "Primary", description: "Primary classes for students from grade 3 to 5  held in our institution", image: "../../assets/img/courses/primary.jpg", cart_title: "Primary"}]

  isExpanded;
  panel_title;
  isExpanded_2;
  panel_title_2;
  prev = null;
  prev2 = null;
  sorted_classes = {};
  background = ["#9C9B99", "#CCCCCC"];
  constructor(private router: Router) {
  }

  ngOnInit() {
    this.isExpanded = false;
    this.panel_title = null;
    this.isExpanded_2 = false;
    this.panel_title_2 = null;

    let streams = this.classes.map(function (cls) {
      return cls.stream;
    });
    let unique_streams = streams.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });

    for(let i = 0; i < unique_streams.length; i++){
      this.sorted_classes[unique_streams[i]] = [];
      let considered_batches = [];
      let batch_classes = {};
      for(let j = 0; j < this.classes.length; j++){
        if(this.classes[j].stream == unique_streams[i]){
          if(!considered_batches.includes(this.classes[j].batch)){
            considered_batches.push(this.classes[j].batch);
            batch_classes[this.classes[j].batch] = [];
          }
          batch_classes[this.classes[j].batch].push(this.classes[j]);
        }
      }
      for(let j = 0;  j < considered_batches.length; j++)
        this.sorted_classes[unique_streams[i]].push({batch: considered_batches[j], classes:batch_classes[considered_batches[j]]});
    }
  }

  courses(title, stream) {
    this.isExpanded_2 = false;
    if(this.prev == stream){
      this.isExpanded = !this.isExpanded;
    }
    else{
      this.isExpanded = true;
    }

    if(this.isExpanded){
      this.panel_title = title;
      this.panel1_classes = this.sorted_classes[stream];
    }
    else{
      this.panel_title = null;
    }
    // if(stream == "science"){
    //   this.panel1_classes = this.sorted_classes[stream];
    // }
    // else if(stream == "maths"){
    //   this.panel1_classes = this.maths_classes;
    // }
    // else{
    //   this.panel1_classes = this.technology_classes;
    // }
    this.prev = stream;
    // this.router.navigate(['courses']);
  }
  login() {
    this.router.navigate(['login']);
  }

  panel(title, stream) {
    this.isExpanded = false;
    if(this.prev2 == stream){
      this.isExpanded_2 = !this.isExpanded_2;
    }
    else{
      this.isExpanded_2 = true;
    }

    if(this.isExpanded_2){
      this.panel_title_2 = title;
      this.panel2_classes = this.sorted_classes[stream];
    }
    else{
      this.panel_title_2 = null;
    }
    // if(stream == "business"){
    //   this.classes2 = this.business_classes;
    // }
    // else if(stream == "ordinary"){
    //   this.classes2 = this.ordinary_classes;
    // }
    // else{
    //   this.classes2 = this.primary_classes;
    // }
    this.prev2 = stream;
    // this.router.navigate(['courses']);
  }

}
