import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AlertService } from 'ngx-alerts';
import { PaymentService } from '../../../services/payment.service';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.css']
})
export class PaymentResultComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private spinnerService: Ng4LoadingSpinnerService,
              private alertService: AlertService,
              private paymentSer: PaymentService) { }
  classId;
  orderId;
  order_id;
  success = false;

  ngOnInit() {
    this.spinnerService.show();
    this.classId = this.route.snapshot.paramMap.get('class_id');
    this.orderId = this.route.snapshot.paramMap.get('orderId');
    this.order_id = this.route.snapshot.queryParamMap.get('order_id');
    this.paymentSer.getPaymentStatus(this.orderId).subscribe(success => {
      this.spinnerService.hide();
    }, error => {
      this.spinnerService.hide();
    });
    console.log(this.orderId);
    console.log(this.order_id);
  }

}
