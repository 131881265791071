import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AlertService } from 'ngx-alerts';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClassService } from 'src/app/services/class.service';
import { AttendenceService } from 'src/app/services/attendence.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {url} from 'src/environments/url';
import { inherits } from 'util';

@Component({
  selector: 'app-mark-attendence',
  templateUrl: './mark-attendence.component.html',
  styleUrls: ['./mark-attendence.component.scss']
})
export class MarkAttendenceComponent implements OnInit {
  focus: any;
  // @ViewChild('search_box', { read: ElementRef}) search_element: ElementRef;
  @ViewChild('search') searchElement: ElementRef;
  str_date = new Date().toDateString();
  date = new Date();
  students = [];
  enabled_classes = [];
  class_details = {};
  class_session_details = {};
  search_value = '';
  student: any;
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  this_month = undefined;
  prev_month = undefined;
  attendance_marked = false;
  this_month_paid = false;
  prev_month_paid = false;
  marking_mode = false;
  prev_str_date = '';
  // keydown = new KeyboardEvent('keydown', {
  //   "key": "Enter"
  // });

  results: any[] = [];
  show_component = false;
  selected: any;
  queryField: FormControl = new FormControl();
  student_id = '';
  student_info = [];
  classAttendence = [];
  tfArray = [];
  tfArray_ = [];
  thisMonth = [];
  lastMonth = [];
  payFor = 'Last Month';
  fees = 0;
  fullHalf = 'full';
  class_id_ = 0;
  attended_dates = [];
  allstudents = [];
  // months = {current: 'active', prev: ''};
  keyword = 'card_number';
  data = [];
  base_url = url;
  constructor(private modalService: NgbModal, private classSer: ClassService
    , private auth: AuthService, private alertService: AlertService, private attSer: AttendenceService,
    private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.get_classes();
    this.get_enabled_classes();
    this.this_month = this.date.getMonth();
    this.prev_month = new Date(new Date(this.date).setMonth(this.date.getMonth() - 1)).getMonth();
    this.searchElement.nativeElement.focus();
    // this.searchElement.nativeElement.select();
  }
  changeDate(date) {
    if (this.date !== new Date(date)) {
      this.date = new Date(date);
      this.str_date = this.date.toDateString();
      this.this_month = this.date.getMonth();
      this.prev_month = new Date(new Date(this.date).setMonth(this.date.getMonth() - 1)).getMonth();
      this.student = null;
      this.get_enabled_classes();
    }
  }
  selectEvent(item) {
    this.searchStudent(item.student_id);
  }
  enterEvent() {
    this.searchStudent(this.search_value);
  }
  searchStudent(student_id) {
    this.student = null;
    this.attendance_marked = false;
    this.prev_month_paid = false;
    this.this_month_paid = false;
    this.spinnerService.show();
    if (this.marking_mode) {
      this.attSer.search_and_mark_student(this.keyword, student_id, this.enabled_classes, this.class_session_details).subscribe(success => {
        this.modalService.dismissAll();
        if (success['data']) {
          this.student = success['data'];
          this.onClearedInput();
        } else {
          this.alertService.danger('No matching student found');
          this.student = null;
          this.onClearedInput();
        }
        this.spinnerService.hide();
      }, error => {
        console.log(error);
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while student search');
        this.modalService.dismissAll();
        this.onClearedInput();
      });
    } else {
      this.attSer.search_student(this.keyword, student_id, this.enabled_classes).subscribe(success => {
        this.modalService.dismissAll();
        if (success['data']) {
          this.student = success['data'];
          this.onClearedInput();
        } else {
          this.alertService.danger('No matching student found');
          this.student = null;
          this.onClearedInput();
        }
        this.spinnerService.hide();
      }, error => {
        console.log(error);
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while student search');
        this.modalService.dismissAll();
        this.onClearedInput();
      });
    }
  }
  onClearedInput() {
    this.search_value = '';
    // this.student = null;
    this.searchElement.nativeElement.focus();
  }

  onChangeSearch(val: string) {
    this.search_value = val;
    // const parentElement = this.search_element.nativeElement;
    // const inputElement = parentElement.querySelector('input');
    // console.log(inputElement.value);
    // inputElement.dispatchEvent(this.keydown);
    // fixture.detectChanges();
  }

  onFocused(e) {
    // console.log(e);
  }
  // changeTab(tab) {
  //   if (tab === 'Current') {
  //     this.months.prev = '';
  //     this.months.current = 'active';
  //   } else if (tab === 'Prev') {
  //     this.months.current = '';
  //     this.months.prev = 'active';
  //   }
  //   console.log(this.months.prev);
  // }
  changeKey(key) {
    console.log(key);
    this.keyword = key;
  }
  get_day(date) {
    return new Date(date).getDate();
  }
  changeAmount() {
    if (this.fullHalf === 'half') {
      this.fees = this.fees / 2;
    } else {
      this.fees = this.fees * 2;
    }
  }
  open1(content, id, fee, _for) {
    this.fees = fee;
    this.class_id_ = id;
    this.payFor = _for;
    this.modalService.open(content);
  }
  open2(content, id, fee, _for) {
    this.fees = fee;
    this.class_id_ = id;
    this.payFor = _for;
    this.modalService.open(content);
  }
  get_enabled_classes() {
    this.spinnerService.show();
    this.attSer.get_enabled_classes(this.date.getFullYear().toString() + '-' + (this.date.getMonth() + 1).toString() + '-' +
      (this.date.getDate()).toString()).subscribe(success => {
      this.modalService.dismissAll();
      console.log(success['data']);
      this.enabled_classes = success['data']['enabled_classes'];
      // this.students = success['data']['students'];
      this.class_session_details = success['data']['class_session_details'];
      this.spinnerService.hide();
    }, error => {
      console.log(error);
      this.spinnerService.hide();
      this.alertService.danger('Error While getting enabled classes');
      this.modalService.dismissAll();
    });
  }

  get_classes() {
    this.spinnerService.show();
    this.classSer.get_classes_as_map().subscribe(success => {
      this.modalService.dismissAll();
      console.log(success['data']);
      this.class_details = success['data'];
      this.spinnerService.hide();
    }, error => {
      console.log(error);
      this.spinnerService.hide();
      this.alertService.danger('Error While getting classes');
      this.modalService.dismissAll();
    });
  }
  topUp(user_id, class_id, product_id, amount, prev) {
    if (prev) {
      this.prev_month_paid = true;
    } else {
      this.this_month_paid = true;
    }
    this.spinnerService.show();
    this.attSer.top_up(user_id, class_id, product_id, amount).subscribe(success => {
      this.spinnerService.hide();
      this.onClearedInput();
      this.alertService.success('Top up for successful');
      this.modalService.dismissAll();
      this.onClearedInput();
    }, error => {
      if (prev) {
        this.prev_month_paid = false;
      } else {
        this.this_month_paid = false;
      }
      this.spinnerService.hide();
      console.log(error);
      this.alertService.danger('Error While Top Up');
      this.modalService.dismissAll();
      this.onClearedInput();
    });
  }
 markAttendance(student_id, class_id, class_session_id) {
   this.attendance_marked = true;
    this.spinnerService.show();
    this.attSer.mark(student_id, class_id, class_session_id).subscribe(success => {
      this.alertService.success('marked Attendance');
      this.modalService.dismissAll();
      this.spinnerService.hide();
      this.onClearedInput();
    }, error => {
      this.attendance_marked = false;
      this.spinnerService.hide();
      console.log(error);
      this.alertService.danger('Error While attendance marking');
      this.modalService.dismissAll();
      this.onClearedInput();
    });
 }

  markAndTopUp(student_id, class_id, class_session_id, user_id, product_id, amount) {
    this.attendance_marked = true;
    this.spinnerService.show();
    this.attSer.mark_and_top_up(student_id, class_id, class_session_id, user_id, product_id, amount).subscribe(success => {
      this.spinnerService.hide();
      this.onClearedInput();
      this.alertService.success('marked Attendance and top up successfully');
      this.modalService.dismissAll();
      this.onClearedInput();
    }, error => {
      this.attendance_marked = false;
      this.spinnerService.hide();
      console.log(error);
      this.alertService.danger('Error While attendance marking');
      this.modalService.dismissAll();
      this.onClearedInput();
    });
  }
}
