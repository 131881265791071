import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  focus;
  focus1;
  user = { username: '', password: '' };
  recaptcha: any[];

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
  }

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
  }

  login() {
    if (this.user.username !== '' && this.user.password !== '') {
      if (this.recaptcha === undefined || this.recaptcha === null) {
        console.error('reCAPTCHA not selected');
        return;
      }

      this.auth.login(this.user.username, this.user.password, this.recaptcha).subscribe(success => {
        localStorage.setItem('token', success['token']);
        localStorage.setItem('role', this.getDecodedAccessToken(success['token'])['role']); // save token in localstorage
        this.router.navigate(['dashboard']);
      }, error => {
        console.log(error);
      });
    } else {
      console.log('fill');
    }
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}
