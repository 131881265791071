export class NotSubmitedModle {
  student_id: string;
  component_id: string;
  submission_type: string;
  feedback: string;
  marks: number;
  out_of: number;
  graded_by: string;

  constructor(studentId, componentId, submissionType) {
    this.student_id = studentId;
    this.component_id = componentId;
    this.submission_type = submissionType;
  }
}
