import { NotSubmitedModle } from "./../../../models/NotSubmitedModle";
import { GradeModle } from "./../../../models/GradeModle";
import { CourseService } from "./../../../services/course.service";
import { AlertService } from "ngx-alerts";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: "grade-assignment",
  templateUrl: "./grade-assignment.component.html",
  styleUrls: ["./grade-assignment.component.css"],
})
export class GradeAssignmentComponent implements OnInit {
  subject;
  batch;
  group;
  section;
  session;
  assignment;
  submissionId;
  studentId;
  componentId;
  submissionType;
  files = [];
  answer;

  form = new FormGroup({
    editor: new FormControl("", Validators.required),
    mark: new FormControl("", Validators.required),
    outOf: new FormControl("", Validators.required),
  });
  constructor(
    private route: ActivatedRoute,
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _location: Location
  ) {}

  ngOnInit() {
    this.submissionId = this.route.snapshot.paramMap.get("submission_id");
    this.studentId = this.route.snapshot.paramMap.get("student_id");
    this.componentId = this.route.snapshot.paramMap.get("component_id");
    this.submissionType = this.route.snapshot.paramMap.get("submission_type");
    this.editerRef.setValue("");
    this.markRef.setValue("");
    this.outOfRef.setValue("");

    if (this.submissionId) {
      this.getSubmissionById();
    }
  }
  config = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      // remove formatting button
      ["link"], // link and image, video
    ],
  };
  get editerRef() {
    return this.form.get("editor");
  }
  get markRef() {
    return this.form.get("mark");
  }
  get outOfRef() {
    return this.form.get("outOf");
  }
  getSubmissionById() {
    this.spinnerService.show();
    this.courseSer
      .getSubmissionById(this.componentId, this.studentId)
      .subscribe(
        (response) => {
          this.spinnerService.hide();
          let responseData = response["data"].submission;
          if (responseData.graded) {
            this.editerRef.setValue(responseData.feedback);
            this.markRef.setValue(responseData.marks);
            this.outOfRef.setValue(responseData.out_of);
          }
          response["data"].file_info.forEach((element) => {
            this.files.push(element.file_id);
          });
          this.answer = responseData.content;
          console.log(response["data"]);
        },
        (error) => {
          this.spinnerService.hide();

          this.alertService.danger("Error occurred while getting submissions.");
        }
      );
  }

  backClicked() {
    this._location.back();
  }
  downloadFile(file) {
    this.spinnerService.show();
    this.courseSer.downloadFile(file._id).subscribe(
      (data) => {
        this.spinnerService.hide();
        importedSaveAs(data, file.file_original_name);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while downloading");
      },
      () => console.info("OK")
    );
  }
  submitForm() {
    this.spinnerService.show();
    if (this.submissionId) {
      let inputModle = new GradeModle();
      inputModle.feedback = this.editerRef.value;
      inputModle.marks = this.markRef.value;
      inputModle.out_of = this.outOfRef.value;
      inputModle.graded_by = localStorage.getItem("user_id");

      this.courseSer.gradeSubmission(this.submissionId, inputModle).subscribe(
        (response) => {
          this.spinnerService.hide();

          this.backClicked();
        },
        (error) => {
          this.spinnerService.hide();

          this.alertService.danger("Error occurred while submiting.");
        }
      );
    } else {
      let inputModle = new NotSubmitedModle(
        this.studentId,
        this.componentId,
        this.submissionType
      );
      inputModle.feedback = this.editerRef.value;
      inputModle.marks = this.markRef.value;
      inputModle.out_of = this.outOfRef.value;
      inputModle.graded_by = localStorage.getItem("user_id");
      this.courseSer.gradeNotSubmited(inputModle).subscribe(
        (response) => {
          this.spinnerService.hide();
          // this.students = response["data"];

          this.backClicked();
        },
        (error) => {
          this.spinnerService.hide();

          this.alertService.danger("Error occurred while submiting.");
        }
      );
    }
  }
}
