import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { AlertService } from "ngx-alerts";
import { CourseService } from "./../../../services/course.service";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "message-log-page",
  templateUrl: "./message-log-page.component.html",
  styleUrls: ["./message-log-page.component.css"],
})
export class MessageLogPageComponent implements OnInit {
  classId;
  subject;
  batch;
  group;
  messages;

  constructor(
    private route: ActivatedRoute,
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _location: Location
  ) {}

  ngOnInit() {
    this.classId = this.route.snapshot.paramMap.get("class_id");
    this.getClassInfo();
    this.getMessages();
  }

  getClassInfo() {
    this.spinnerService.show();
    this.courseSer.getClassInfo(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        const responseData = response["data"];
        this.subject = responseData.subject_id.subject;
        this.batch = responseData.batch_id.year;
        this.group = responseData.group_id.group;
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while getting class Info");
      }
    );
  }
  getMessages() {
    this.spinnerService.show();
    this.courseSer.getMessages(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.messages = response["data"];
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while getting class Info");
      }
    );
  }
  backClicked() {
    this._location.back();
  }
}
