import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent implements OnInit {
  images = [
    {image: '../../assets/img/avatar/JanakaICT.jpg', first_name: "Janaka", last_name: 'Wickrmage', description: "Description to be updated"},
    {image: '../../assets/img/avatar/Sugith.jpg', first_name: "Sugith", last_name: 'Shalika', description: "Description to be updated"},
    {image: '../../assets/img/avatar/Kusal.jpg', first_name: "Kusal", last_name: 'Ahalaarachchi', description: "Description to be updated"},
  ];
  slideConfig = {'slidesToShow': 4, 'slidesToScroll': 4, 'arrows': true};
  myCarouselOptions = {items: 4, dots: false, nav: true,responsiveClass:true,
    responsive: {
        0: {
            items: 1,
            nav: false,
            dots:true
        },
        600: {
            items: 3,
            nav: true
        },
        1000: {
            items: 4,
            nav: true
        }
    }};
  constructor() { }

  ngOnInit() {
  }

}
