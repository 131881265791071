import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'ngx-alerts';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {url} from 'src/environments/url';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-search-student',
  templateUrl: './search-student.component.html',
  styleUrls: ['./search-student.component.scss']
})
export class SearchStudentComponent implements OnInit {
  focus: any;
  searchTxt = '';
  role = '';
  /* data tabel for classes */
  rows = [];
  allstudents = [];
  limit = 10;
  allowDelete = false;
  allowEditClasses = false;
  allowView = false;
  student: any;
  base_url = url;
  constructor(private modalService: NgbModal, private auth: AuthService, private alertService: AlertService, private router: Router,
              private spinnerService: Ng4LoadingSpinnerService) { }
  ngOnInit() {
    this.role = localStorage.getItem('role');
    if (this.role === 'admin' || this.role === 'assistant') {
      this.allowView = true;
    }
    if (this.role === 'admin' || this.role === 'assistant') {
      this.allowEditClasses = true;
    }
    if (this.role === 'admin') {
      this.allowDelete = true;
    }
   this.getallstudents();
  }
  deleteProfile(content, student) {
    this.student = student;
    this.modalService.open(content);
  }
  getallstudents() {
    this.spinnerService.show();
    this.allstudents = [];
    if (this.role === 'admin') {
      this.auth.getStudents().subscribe(success => {
        this.allstudents = success['data'].sort((a,b) => b.student_id.localeCompare(a.student_id));
        for (let i = 0; i < this.allstudents.length; i++){
          this.rows.push(this.allstudents[i]);
        }
        this.spinnerService.hide();
      }, error => {
        this.alertService.danger(error.error.message);
        this.spinnerService.hide();
        console.log(error);
      });
    } else if (this.role === 'teacher') {
      this.auth.getAllTeachingStudents().subscribe(success => {
        this.allstudents = success['data'].sort((a,b) => b.student_id.localeCompare(a.student_id));
        for (let i = 0; i < this.allstudents.length; i++) {
          this.rows.push(this.allstudents[i]);
        }
        this.spinnerService.hide();
      }, error => {
        this.alertService.danger(error.error.message);
        this.spinnerService.hide();
        console.log(error);
      });
    }
  }
  search() {
    this.searchTxt.split(' ').join('');
    this.rows = [];
    // this.searchTxt.toLowerCase();
    if (this.searchTxt === '') {
      for (let i = 0; i < this.allstudents.length; i++){
        this.rows.push(this.allstudents[i]);
      }
    } else {
      for (let i = 0; i < this.allstudents.length; i++) {
        if (((this.allstudents[i]['first_name']).toLowerCase()).includes(this.searchTxt.toLowerCase())) {
          this.rows.push(this.allstudents[i]);
        } else if (((this.allstudents[i]['last_name']).toLowerCase()).includes(this.searchTxt.toLowerCase())) {
          this.rows.push(this.allstudents[i]);
        } else if (((this.allstudents[i]['student_id']).toLowerCase()).includes(this.searchTxt.toLowerCase())) {
          this.rows.push(this.allstudents[i]);
        }
      }
    }
  }
  deleteStudent() {
    this.spinnerService.show();
    this.auth.deleteStudent(this.student._id).subscribe(success => {
      this.getallstudents();
      this.alertService.success('Successfully Deleted');
      this.student = null;
      this.spinnerService.hide();
      this.modalService.dismissAll();
    }, error => {
      this.alertService.danger('Error in Server');
      this.spinnerService.hide();
      this.modalService.dismissAll();
    });
  }
  showAll() {
    this.limit = this.rows.length;
  }
}
