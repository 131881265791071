import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "DateProcess",
})
export class DateProcessPipe implements PipeTransform {
  transform(components: any): any {
    if (!components) return components;
    return components.filter(function (component) {
      if (component.enable_from.active && component.disable_from.active) {
        if (
          Date.parse(component.enable_from.date) < Date.now() &&
          Date.parse(component.disable_from.date) > Date.now()
        ) {
          return true;
        }
        return false;
      } else {
        if (
          component.enable_from.active &&
          Date.parse(component.enable_from.date) > Date.now()
        ) {
          return false;
        }
        if (
          component.disable_from.active &&
          Date.parse(component.disable_from.date) < Date.now()
        ) {
          return false;
        }
        return true;
      }

      //   return Date.parse(event.dateStart) < Date.now();

      //   return component.show;
    });
  }
}
