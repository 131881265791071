import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AdminAndTeacherGuardService {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): boolean {
    let role = localStorage.getItem("role");
    if (!(role == "admin" || role == "teacher")) {
      this.router.navigate(["login"]);
      return false;
    } else {
      return true;
    }
  }
}
