import { CourseService } from './../../../services/course.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SessionModle } from './../../../models/SessionModle';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

declare var $: any;
@Component({
  selector: 'course-session-modal',
  templateUrl: './course-session.component.html',
  styleUrls: ['./course-session.component.css'],
})
export class CourseSessionComponent implements OnInit {
  @Input() sessionVal;
  @Input() sessionModle: SessionModle;
  @Output() changeSessions = new EventEmitter();

  modelTitle;
  addButton = false;
  editButton = false;

  form = new FormGroup({
    sessionTitle: new FormControl('', Validators.required),
    classDate: new FormControl('', Validators.required),
  });

  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.sessionVal) {
      this.modelTitle = 'Add Session';
      this.sessionNameRef.setValue('');
      this.classDateRef.setValue('');
      this.addButton = true;
      this.editButton = false;
    } else {
      this.modelTitle = 'Edit Session';
      this.sessionNameRef.setValue(this.sessionVal.name);
      this.classDateRef.setValue(
        new Date(this.sessionVal.class_session_id._for)
      );
      this.addButton = false;
      this.editButton = true;
    }
  }

  get sessionNameRef() {
    return this.form.get('sessionTitle');
  }
  get classDateRef() {
    return this.form.get('classDate');
  }

  submitSession() {
    this.spinnerService.show();
    let data;
    if (!this.sessionVal) {
      this.sessionModle.name = this.sessionNameRef.value;
      this.sessionModle._for = this.classDateRef.value;
      this.sessionModle.vertical_loc += 1;

      this.courseSer.addSession(this.sessionModle).subscribe(
        (response) => {
          const obj = response['data'];
          data = {
            vertical_loc: obj.vertical_loc,
            _id: obj._id,
            name: obj.name,
            class_id: obj.class_id,
            class_session_id: {
              _for: obj._for,
            },
            section_id: obj.section_id,
          };
          this.spinnerService.hide();
          this.changeSessions.emit(data);
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger('Error occurred while adding session');
          data = 'error';
          this.changeSessions.emit(data);
        }
      );
    } else {
      this.sessionVal.name = this.sessionNameRef.value;
      this.sessionVal.class_session_id._for = this.classDateRef.value;
      this.courseSer.updateSession(this.sessionVal).subscribe(
        (response) => {
          data = response['data'];
          this.spinnerService.hide();
          this.changeSessions.emit(this.sessionVal);
        },
        (error) => {
          this.alertService.danger('Error occurred while updating session');

          data = 'error';
          this.spinnerService.hide();
          this.changeSessions.emit(data);
        }
      );
    }
  }
}
