import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { AuthService } from 'src/app/services/auth.service';
import { ClassService } from 'src/app/services/class.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-add-teacher',
  templateUrl: './add-teacher.component.html',
  styleUrls: ['./add-teacher.component.scss']
})
export class AddTeacherComponent implements OnInit {
  focus: any;
  role = '';
  profile_pic = 'add-teacher-avatar.png';
  personalInfo = {
    firstName: '',
    lastName: '',
    gender: 'MALE',
    address: {lines: '', city: ''},
    telephone: {land: '', mobile: ''},
    birthday: '', nic: '',
    admissiondate: ''
  };
  teaching_info = {
    description: '',
    bookable: true,
    mbt: undefined,
    subjects: []
  };
  subjectDetails = {subject: '', streams: [] };
  subjects = [];
  streams = [];
  constructor(private modalService: NgbModal, private alertService: AlertService, private auth: AuthService,
              private classSer: ClassService, private spinnerService: Ng4LoadingSpinnerService, private router: Router) { }

  ngOnInit() {
    this.getStreams();
    this.getSubjects();
  }

  changeSelection(type) {
    this.fetchCheckedIDs(type);
  }

  fetchCheckedIDs(type) {
    if (type === 'Subject') {
      this.teaching_info.subjects = [];
      this.subjects.forEach((value, index) => {
        if (value.isChecked) {
          this.teaching_info.subjects.push(value._id);
        }
      });
    } else if (type === 'Stream') {
      this.subjectDetails.streams = [];
      this.streams.forEach((value, index) => {
        if (value.isChecked) {
          this.subjectDetails.streams.push(value._id);
        }
      });
    }
  }
  open(content) {
    this.modalService.open(content);
  }
  addTeacher() {
    if ((this.personalInfo.firstName !== ''
      && this.personalInfo.lastName !== '' &&
      this.personalInfo.address.lines !== '' &&
      this.personalInfo.address.city !== '' &&
      this.personalInfo.telephone.land !== '' &&
      this.personalInfo.telephone.mobile !== '' &&
      this.personalInfo.birthday !== '' &&
      this.personalInfo.nic !== '' &&
      this.personalInfo.admissiondate !== '') &&
      ((this.teaching_info.bookable && this.teaching_info.mbt !== '') || (!this.teaching_info.bookable))) {
      this.spinnerService.show();
      this.auth.addTeacher(this.personalInfo, this.teaching_info).subscribe(success => {
        this.personalInfo = {
          firstName: '',
          lastName: '',
          gender: 'MALE',
          address: {lines: '', city: ''},
          telephone: {land: '', mobile: ''},
          birthday: '', nic: '',
          admissiondate: ''};
        this.teaching_info = {
          description: '',
          bookable: true,
          mbt: undefined,
          subjects: []};
        this.streams.forEach((value, index) => {
          value.isChecked = false;
        });
        this.subjects.forEach((value, index) => {
          value.isChecked = false;
        });
        this.spinnerService.hide();
        this.alertService.success('Teacher Added Successfully');
      }, error => {
        this.spinnerService.hide();
        if (error.status === 401) {
          this.router.navigate(['login']);
        }
        this.alertService.danger('Error adding Teacher');
      });
    } else {
      this.alertService.danger('Fill All the Data');
    }
  }

  getStreams() {
    this.streams = [];
    this.classSer.getStreams().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        data['data'][i]['isChecked'] = false;
        this.streams.push(data['data'][i]);
      }
      console.log(this.streams);
    }, error => {
      console.log(error);
    });
  }

  getSubjects() {
    this.subjects = [];
    this.classSer.getSubjects().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        data['data'][i].isChecked = false;
        this.subjects.push(data['data'][i]);
      }
    }, error => {
      console.log(error);
    });
  }
  addNewSubject() {
    if (this.subjectDetails.streams.length > 0 && this.subjectDetails.subject !== '') {
      this.spinnerService.show();
      this.classSer.addSubject(this.subjectDetails.streams, this.subjectDetails.subject).subscribe(success => {
        this.alertService.success('New Subject Created Successfully');
        console.log(success);
        this.getSubjects();
        this.streams.forEach((value, index) => {
          value.isChecked = false;
        });
        this.spinnerService.hide();
        this.modalService.dismissAll();
      }, error => {
        console.log(error);
        this.spinnerService.hide();
        this.alertService.danger('Error Creating New Subject');
      });
    } else {
      this.alertService.warning('Please Fill Details to add New Subject');
    }
  }
}
