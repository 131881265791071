import { Component, OnInit } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { AuthService } from 'src/app/services/auth.service';
import { ClassService } from 'src/app/services/class.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import * as jwt_decode from 'jwt-decode';

declare var $: any;
@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss']
})
export class StudentDashboardComponent implements OnInit {
  batches = [];
  subjects = [];
  teachers = [];
  streams = [];
  stream_subjects = [];
  weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  options = [];
  classes = [];
  show_classes = [];
  recommended = [];
  recom_subs = [];
  condition = '';
  keyword = '';
  selectedCategory = '';
  user_id = '';
  student_id = '';
  student;
  info_completed = true;
  student_name = '';
  personal_info = {
    first_name: '',
    last_name: '',
    birthday: '',
    gender: '',
    address: {lines: '', city: ''},
    email: ''
  };
  class_info = {
    admission_batch: '',
    stream: ''
  };
  class_code = '';
  selected_class = '';

  constructor(private alertService: AlertService,
              private auth: AuthService,
              private route: ActivatedRoute,
              private classSer: ClassService,
              private spinnerService: Ng4LoadingSpinnerService,
              private router: Router) { }

  ngOnInit() {
    if (!localStorage.getItem('registered')) {
      this.router.navigate(['userInfo']);
    }
    this.getBatch();
    this.getStreams();
    this.getStudentProfile();
    this.getTeachers();
    this.getSubjects();
    // $('#Advertisement').modal('show');

  }
  selectClass (type: string, class_id: string, code: string) {
    this.spinnerService.show();
    this.auth.check_subscription().subscribe(data => {
      this.spinnerService.hide();
      if (data['data']['subscribed']) {
        this.router.navigate(['course', class_id], { relativeTo: this.route });
      } else {
        if (type === 'Follow') {
          this.selected_class = class_id;
        } else if (type === 'Enroll') {
          this.selected_class = '';
        }
        $('#FollowModel').modal('show');
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while checking subscription status');
    });
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  getBatch() {
    this.batches = [];
    this.spinnerService.show();
    this.classSer.getVisibleBatches().subscribe(data => {
      this.spinnerService.hide();
      for (let i = 0; i < data['data'].length; i++) {
        this.batches.push({_id: data['data'][i]._id, value: data['data'][i].year + ' ' + data['data'][i].category});
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Batches');
    });
  }
  getTeachers() {
    this.teachers = [];
    this.spinnerService.show();
    this.auth.getTeachers().subscribe(data => {
      this.spinnerService.hide();
      for (let i = 0; i < data['data'].length; i++) {
        this.teachers.push({_id: data['data'][i]._id, value: data['data'][i].first_name + ' ' + data['data'][i].last_name});
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Teachers');
    });
  }
  getSubjects() {
    this.subjects = [];
    this.spinnerService.show();
    this.classSer.getVisibleSubjects().subscribe(data => {
      this.spinnerService.hide();
      for (let i = 0; i < data['data'].length; i++) {
        this.subjects.push({_id: data['data'][i]._id, value: data['data'][i].subject});
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Subjects');
    });
  }
  getStreams() {
    this.streams = [];
    this.spinnerService.show();
    this.classSer.getStreams().subscribe(data => {
      this.spinnerService.hide();
      for (let i = 0; i < data['data'].length; i++) {
        this.streams.push({_id: data['data'][i]._id, value: data['data'][i].stream});
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Subjects');
    });
  }
  getStreamSubjects() {
    this.stream_subjects = [];
    this.spinnerService.show();
    this.classSer.getStreamSubjects().subscribe(data => {
      this.spinnerService.hide();
      for (let i = 0; i < data['data'].length; i++) {
        this.stream_subjects.push(data['data'][i]);
      }
      for (let i = 0; i < this.stream_subjects.length; i++) {
        if (this.stream_subjects[i]['stream_id'] === this.student.stream) {
          this.recom_subs.push(this.stream_subjects[i]['subject_id']);
        }
      }
      console.log(this.recom_subs);
      this.getClasses();
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Subjects');
    });
  }
  getClasses() {
    this.classes = [];
    this.spinnerService.show();
    this.classSer.getClasses().subscribe(success => {
      this.spinnerService.hide();
      for (let i = 0; i < success['data'].length; i++) {
        success['data'][i]['allow'] = false;
        if (this.recom_subs.includes(success['data'][i]['subject_id']['_id']) &&
          this.student.admission_batch._id === success['data'][i]['batch_id']['_id']) {
          success['data'][i]['recommended'] = true;
          this.recommended.push(success['data'][i]);
        } else {
          success['data'][i]['recommended'] = false;
        }
        this.classes.push(success['data'][i]);
      }
      this.getEnrolledClasses();
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Classes');
    });
  }
  getOptions(item) {
    this.options = [];
    if (item === 'bySub') {
      for (let i = 0; i < this.subjects.length; i++) {
        this.options.push(this.subjects[i]);
      }
      this.condition = 'bySub';
    } else if (item === 'byTeacher') {
      for (let i = 0; i < this.teachers.length; i++) {
        this.options.push(this.teachers[i]);
      }
      this.condition = 'byTeacher';
    } else if (item === 'byBatch') {
      for (let i = 0; i < this.batches.length; i++) {
        this.options.push(this.batches[i]);
      }
      this.condition = 'byBatch';
    } else if (item === 'byStream') {
      for (let i = 0; i < this.streams.length; i++) {
        this.options.push(this.streams[i]);
      }
      this.condition = 'byStream';
      console.log(this.options);
    } else if (item === 'byEnrol') {
      this.condition = 'enrolled';
      this.search();
    } else if (item === 'byRecommend') {
      this.condition = 'recommend';
      this.search();
    } else {
      this.condition = 'all';
      this.search();
    }
  }
  search() {
    this.show_classes = [];
    if (this.condition === 'all') {
      this.keyword = 'All';
      // this.show_classes = this.classes;
    } else if (this.condition === 'enrolled') {
      this.keyword = 'Enrolled';
      for (let i = 0; i < this.classes.length; i++) {
        if (this.classes[i]['allow']) {
          this.show_classes.push(this.classes[i]);
        }
      }
    } else if (this.condition === 'recommend') {
      this.keyword = 'Recommended';
      for (let i = 0; i < this.classes.length; i++) {
        if (this.classes[i]['recommended']) {
          this.show_classes.push(this.classes[i]);
        }
      }
    } else if (this.condition === 'bySub') {
        for (let i = 0; i < this.classes.length; i++) {
          if (this.classes[i]['subject_id']['_id'] === this.selectedCategory) {
            this.show_classes.push(this.classes[i]);
            this.keyword = this.classes[i]['subject_id']['subject'];
          }
        }
      } else if (this.condition === 'byTeacher') {
        for (let i = 0; i < this.classes.length; i++) {
          if (this.classes[i]['teacher_id']['_id'] === this.selectedCategory) {
            this.show_classes.push(this.classes[i]);
            this.keyword = this.classes[i]['teacher_id']['first_name'] + ' ' + this.classes[i]['teacher_id']['last_name'];
          }
        }
      } else if (this.condition === 'byBatch') {
        for (let i = 0; i < this.classes.length; i++) {
          if (this.classes[i]['batch_id']['_id'] === this.selectedCategory) {
            this.show_classes.push(this.classes[i]);
            this.keyword = this.classes[i]['batch_id']['batch'];
          }
        }
      } else if (this.condition === 'byStream') {
        let eli_subs = [];
        for (let i = 0; i < this.stream_subjects.length; i++) {
          if (this.stream_subjects[i]['stream_id'] === this.selectedCategory) {
            eli_subs.push(this.stream_subjects[i]['subject_id']);
          }
        }
        for (let i = 0; i < this.streams.length; i++) {
          if (this.streams[i]['_id'] === this.selectedCategory) {
            this.keyword = this.streams[i]['value'];
            break;
          }
        }
        for (let i = 0; i < this.classes.length; i++) {
          if (eli_subs.includes(this.classes[i]['subject_id']['_id'])) {
            this.show_classes.push(this.classes[i]);
          }
        }
      }
      this.spinnerService.hide();
  }
  getStudentProfile() {
    this.spinnerService.show();
    this.user_id = localStorage.getItem('user_id');
    this.auth.getStudentProfileByUser(this.user_id).subscribe(success => {
      this.student = success['data'];
      this.student_id = success['data']['_id'];
      if (success['data']['admission_batch']) {
        this.info_completed = true;
        this.student_name = this.student.first_name;
      } else {
        this.info_completed = false;
      }
      this.getStreamSubjects();
    }, error => {
      this.alertService.danger('Error occurred while student Data');
    });
  }
  getEnrolledClasses() {
    this.spinnerService.show();
    this.classSer.getClassesOfStudent(this.student_id).subscribe(success => {
      this.spinnerService.hide();
      for (let i = 0; i < success['data'].length; i++) {
        for (let j = 0; i < this.classes.length; j++) {
          if (success['data'][i]['class_id']['_id'] === this.classes[j]['_id']) {
            this.classes[j]['allow'] = true;
            break;
          }
        }
      }
      this.getOptions('byEnrol');
      // this.getRecommendations();
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Enrolled Classes');
    });
  }
  enroll(code) {
    if (code) {
      this.class_code = code;
    }
    if (!this.class_code) {
      this.alertService.warning('Please enter a valid class code');
    } else {
      this.spinnerService.show();
      this.classSer.enrollByClassCode(this.class_code).subscribe(success => {
        this.spinnerService.hide();
        this.class_code = '';
        this.getEnrolledClasses();
        this.alertService.success('Successfully Enrolled to Class');
        this.class_code = '';
      }, error => {
        this.spinnerService.hide();
        this.class_code = '';
        this.alertService.danger(error.error.message);
      });
    }

  }
}
