import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {url} from '../../environments/url';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  // headers = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   'Access-Control-Allow-Origin': '*',
  //   'Authorization': 'Bearer ' + localStorage.getItem('token')
  // });

  constructor(private http: HttpClient) { }

  public get_header() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
    return headers;
  }

  /* get classes */
  getClasses() {
    return this.http.get(url + '/classes/', { headers: this.get_header() }).pipe(map(res => res));
  }
  getClassesForStaff() {
    return this.http.get(url + '/classes/getClasses', { headers: this.get_header() }).pipe(map(res => res));
  }
  getClassesMap() {
    return this.http.get(url + '/classes//classesMap', { headers: this.get_header() }).pipe(map(res => res));
  }
  getClassesByTeacher(teacher_id) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(url + '/classes/getClassesByTeacher/' + teacher_id, { headers: this.get_header() }).pipe(map(res => res));
  }
  getClassesBySubject(search_word) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(url + '/classes/get/by-subject', { 'search_word': search_word }, { headers: this.get_header() }).pipe(map(res => res));
  }
  getClassesByBatch(search_word) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(url + '/classes/get/by-batch', { 'search_word': search_word }, { headers: this.get_header() }).pipe(map(res => res));
  }
  getClassesByDay(search_word) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(url + '/classes/get/by-day', { 'search_word': search_word }, { headers: this.get_header() }).pipe(map(res => res));
  }
  addClass(subject_id, batch_id, group_id, teacher_id, starting_date, starting_time, ending_time, fees, daily_fees, medium) {
    if (daily_fees === 'yes') {
      daily_fees = true;
    } else {
      daily_fees = false;
    }
    return this.http.post(url + '/classes/add',
      {
        'subject_id': subject_id,
        'batch_id': batch_id,
        'group_id': group_id,
        'teacher_id': teacher_id,
        'starting_date': starting_date,
        'ending_time': ending_time,
        'starting_time': starting_time,
        'fees': fees,
        'daily_fees': daily_fees,
        'conduct_medium': medium,
      },
      { headers: this.get_header() }).pipe(map(res => res));
  }
  editClass(class_id, subject_id, batch_id, group_id, teacher_id, starting_date, starting_time, ending_time, fees, daily_fees) {
    if (daily_fees === 'yes') {
      daily_fees = true;
    } else {
      daily_fees = false;
    }
    return this.http.post(url + '/classes/update/' + class_id,
      {
        'subject_id': subject_id,
        'batch_id': batch_id,
        'group_id': group_id,
        'teacher_id': teacher_id,
        'starting_date': starting_date,
        'ending_time': ending_time,
        'starting_time': starting_time,
        'fees': fees,
        'daily_fees': daily_fees
      },
      { headers: this.get_header() }).pipe(map(res => res));
  }
  /* get batch */
  getBatches() {
    return this.http.get(url + '/classes/batches', { headers: this.get_header() }).pipe(map(res => res));
  }

  getBatchesMap() {
    return this.http.get(url + '/classes/batchesMap', { headers: this.get_header() }).pipe(map(res => res));
  }

  getVisibleBatches() {
    return this.http.get(url + '/classes/batches/visible', { headers: this.get_header() }).pipe(map(res => res));
  }
  addBatch(category, year) {
    return this.http.post(url + '/classes/add/batch', { 'category': category, 'year': year },
      { headers: this.get_header() }).pipe(map(res => res));
  }
  /* get groups */
  getGroups() {
    return this.http.get(url + '/classes/groups', { headers: this.get_header() }).pipe(map(res => res));
  }
  getVisibleGroups() {
    return this.http.get(url + '/classes/groups/visible', { headers: this.get_header() }).pipe(map(res => res));
  }
  addGroup(name) {
    return this.http.post(url + '/classes/add/group', { 'name': name },
      { headers: this.get_header() }).pipe(map(res => res));
  }
  /* get subjects */
  getSubjects() {
    return this.http.get(url + '/classes/subjects', { headers: this.get_header() }).pipe(map(res => res));
  }
  getVisibleSubjects() {
    return this.http.get(url + '/classes/subjects/visible', { headers: this.get_header() }).pipe(map(res => res));
  }
  getStreams() {
    return this.http.get(url + '/classes/getStreams').pipe(map(res => res));
  }
  getStreamSubjects() {
    return this.http.get(url + '/classes/getStreamSubjects').pipe(map(res => res));
  }
  addSubject(streams, subjectName) {
    return this.http.post(url + '/classes/add/subject', { 'streams': streams, 'subject': subjectName },
      { headers: this.get_header() }).pipe(map(res => res));
  }
  /* get classes of a  student */
  getClassesOfStudent(std_id) {
    return this.http.get(url + '/students/get/classes/' + std_id, { headers: this.get_header() }).pipe(map(res => res));
  }

  enrollByClassCode(code){
    return this.http.post(url + '/students/enroll', {code: code}, { headers: this.get_header() }).pipe(map(res => res));
  }

  /*get classes of a teacher */
  getClassesOfTeacher(tea_id) {
    return this.http.get(url + '/teachers/get/classes/' + tea_id, { headers: this.get_header() }).pipe(map(res => res));
  }
  /*get classes of an assistant */
  getClassesOfAssistant(ass_id) {
    return this.http.get(url + '/assistants/get/classes/' + ass_id, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* Deassign Assistant */
  deassignAssistant(ass_id, class_details) {
    return this.http.post(url + '/assistants/deassign/' + ass_id,
      { 'deassign_info': class_details }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* assign Assistant */
  assignAssistant(ass_id, class_details) {
    return this.http.post(url + '/assistants/assign/' + ass_id,
      { 'class_info': class_details }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* Deassign student */
  deassignStudent(std_id, class_details) {
    return this.http.post(url + '/students/deassign/' + std_id,
      { 'deassign_info': {class_id: class_details._id} }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* assign student */
  assignStudent(std_id, class_details) {
    console.log(class_details);
    return this.http.post(url + '/students/assign/' + std_id,
      { 'class_info': {class_id: class_details._id, fees_rate: class_details.fees_rate} },
      { headers: this.get_header() }).pipe(map(res => res));
  }

  assignStudents(class_id, students) {
    console.log(class_id);
    return this.http.post(url + '/classes/assign-to-class/' + class_id,
      { 'students': students, fees_rate: 1 }, { headers: this.get_header() }).pipe(map(res => res));
  }

  /* marking*/
  enableMarking(class_) {
    return this.http.post(url + '/classes/enable/' + class_.class_id,
      { 'name': class_.name, '_for': class_._for }, { headers: this.get_header() }).pipe(map(res => res));
  }

  disableMarking(class_id, class_) {
    return this.http.post(url + '/classes/disable/' + class_id,
      { 'class': class_ }, { headers: this.get_header() }).pipe(map(res => res));
  }
  /* delete class */
  deleteClass(class_id) {
    return this.http.delete(url + '/classes/delete/' + class_id, { headers: this.get_header() }).pipe(map(res => res));
  }

  generateReports(role, name, method, clas, from, to) {
    return this.http.post(url + '/classes/generateReports', {
      'select_role': role,
      'select_name': name,
      'select_method': method,
      'select_class': clas,
      'from': from,
      'to': to
    }, { headers: this.get_header() }).pipe(map(res => res));
  }
  getZoomSignature(meeting_number, role) {
    return this.http.post(url + '/classes/zoom/signature', {
      meetingNumber: meeting_number,
      role: role
    }, { headers: this.get_header() }).pipe(map(res => res));
  }
  public get_classes_as_map() {
    return this.http.get(url + '/classes/classesMap', { headers: this.get_header() }).pipe(map(res => res));
  }
}
