import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { AlertService } from "ngx-alerts";
import { CourseService } from "./../../../services/course.service";
import { SectionModle } from "./../../../models/SectionModle";
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
} from "@angular/core";
import { NgForm, FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";

declare var $: any;
@Component({
  selector: "course-section-modal",
  templateUrl: "./course-section-modal.component.html",
  styleUrls: ["./course-section-modal.component.css"],
})
export class CourseSectionModalComponent implements OnInit {
  @Input("sectionVal") sectionVal;
  @Input("sectionModle") sectionModle: SectionModle;
  @Output() changeSections = new EventEmitter();

  modelTitle;
  editItem;
  selectedSection;
  addButton = false;
  editButton = false;

  form = new FormGroup({
    sectionTitle: new FormControl("", Validators.required),
  });

  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}
  ngOnInit() {
    this.spinnerService.hide();
  }

  ngOnChanges(changes: SimpleChanges) {
    // let changeVal = changes.sectionObj.currentValue;

    if (!this.sectionVal) {
      this.modelTitle = "Add Section";
      this.sectionName.setValue("");
      this.addButton = true;
      this.editButton = false;
    } else {
      this.modelTitle = "Edit Section";
      this.sectionName.setValue(this.sectionVal.name);
      this.addButton = false;
      this.editButton = true;
    }
  }

  get sectionName() {
    return this.form.get("sectionTitle");
  }

  editSection() {
    let updateItem = this.sectionVal.find(
      this.findIndexToUpdate,
      this.editItem.id
    );
    let index = this.sectionVal.indexOf(this.editItem);

    this.sectionVal[index].sectionName = this.sectionName.value;
    // this.sectionEvent.emit(this.sectionObj);

    $("#editSectionModal").modal("hide");
  }

  findIndexToUpdate(newItem) {
    return newItem.id === this;
  }

  addSession() {
    this.spinnerService.show();
    let data;
    if (!this.sectionVal) {
      this.sectionModle.name = this.sectionName.value;
      this.sectionModle.vertical_loc += 1;
      this.courseSer.addSection(this.sectionModle).subscribe(
        (response) => {
          data = response["data"];
          this.spinnerService.hide();
          this.changeSections.emit(data);
        },
        (error) => {
          this.alertService.danger("Error occurred while adding section");

          data = "error";
          this.spinnerService.hide();
          this.changeSections.emit(data);
        }
      );
    } else {
      this.sectionVal.name = this.sectionName.value;
      this.courseSer.updateSection(this.sectionVal).subscribe(
        (response) => {
          data = response["data"];
          this.spinnerService.hide();
          this.changeSections.emit(this.sectionName.value);
        },
        (error) => {
          this.alertService.danger("Error occurred while updating section");

          data = "error";
          this.spinnerService.hide();
          this.changeSections.emit(data);
        }
      );
    }
  }
}
