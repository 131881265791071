import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'ngx-alerts';
import { AuthService } from 'src/app/services/auth.service';
import { ClassService } from 'src/app/services/class.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-add-class',
  templateUrl: './add-class.component.html',
  styleUrls: ['./add-class.component.scss']
})
export class AddClassComponent implements OnInit {
  focus: any;
  batches = []; // get batches
  selectedBatch = '';
  teachers = []; // get teachers
  selectedTeacher = '';
  groups = []; // get groups
  selectedGroup = '';
  subjects = []; // get Subjects
  fees = undefined; // fees
  selectDailyFee = 'no';
  selectedSubject = '';
  selectedMedium = 'Sinhala';
  endingTime = '';
  startingTime = '';
  startingDate = new Date();
  role = '';
  profile_pic = 'add-class-avatar.png';

  minYear = new Date().getFullYear();
  batchDetails = { name: '', year: new Date().getFullYear() };
  subjectDetails = {subject: '', streams: [] };
  groupDetails = {name: '' };
  streams = [];
  constructor(private modalService: NgbModal,
    private alertService: AlertService,
    private auth: AuthService,
    private classSer: ClassService,
    private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.getBatch();
    this.getTeachers();
    this.getGroups();
    this.getSubjects();
    this.getStreams();
  }

  changeSelection() {
    this.fetchCheckedIDs();
  }

  fetchCheckedIDs() {
    this.subjectDetails.streams = [];
    this.streams.forEach((value, index) => {
      if (value.isChecked) {
        this.subjectDetails.streams.push(value._id);
      }
    });
  }
  getBatch() {
    this.batches = [];
    this.classSer.getBatches().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.batches.push(data['data'][i]);
      }
    }, error => {
      console.log(error);
    });
  }
  getTeachers() {
    this.teachers = [];
    this.auth.getTeachers().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.teachers.push(data['data'][i]);
      }
    }, error => {
      console.log(error);
    });
  }
  getGroups() {
    this.groups = [];
    this.classSer.getGroups().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.groups.push(data['data'][i]);
      }
    }, error => {
      console.log(error);
    });
  }
  getStreams() {
    this.streams = [];
    this.classSer.getStreams().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        data['data'][i]['isChecked'] = false;
        this.streams.push(data['data'][i]);
      }
      console.log(this.streams);
    }, error => {
      console.log(error);
    });
  }
  getSubjects() {
    this.subjects = [];
    this.classSer.getSubjects().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        this.subjects.push(data['data'][i]);
      }
    }, error => {
      console.log(error);
    });
  }
  addClass() {
    if (this.selectedBatch !== '' && this.selectedTeacher !== '' && this.selectedGroup !== '' &&
      this.selectedSubject !== '' && this.endingTime !== '' && this.startingTime !== '') {
      this.spinnerService.show();
      this.classSer.addClass(this.selectedSubject,
        this.selectedBatch,
        this.selectedGroup,
        this.selectedTeacher,
        this.startingDate,
        this.startingTime,
        this.endingTime,
        this.fees,
        this.selectDailyFee,
        this.selectedMedium,).subscribe(success => {
          this.selectedSubject = '';
          this.selectedBatch = '';
          this.selectedGroup = '';
          this.selectedTeacher = '';
          this.startingDate = new Date();
          this.startingTime = '';
          this.endingTime = '';
          this.fees = undefined;
          this.selectDailyFee = 'no';
          this.selectedMedium = 'Sinhala';
        this.streams.forEach((value, index) => {
          value.isChecked = false;
        });
        this.spinnerService.hide();
        this.alertService.success('Class Added Successfully');
      }, error => {
          this.spinnerService.hide();
        this.alertService.danger('Error While Adding New Class');
      });
    } else {
      this.alertService.warning('Please Fill All The Details');
    }
  }
  open(content) {
    this.modalService.open(content);
  }
  addNewBatch() {
    if (this.batchDetails.year !== null && this.batchDetails.name !== '') {
      if (this.batchDetails.year >= this.minYear) {
        this.spinnerService.show();
        this.classSer.addBatch(this.batchDetails.name, this.batchDetails.year).subscribe(success => {
          this.alertService.success('New Batch Created Successfully');
          this.getBatch();
          this.spinnerService.hide();
          this.modalService.dismissAll();
        }, error => {
          console.log(error);
          this.spinnerService.hide();
          this.alertService.danger('Error Creating New Batch');
        });
      } else {
        this.alertService.warning('Error in Year');
      }
    } else {
      this.alertService.warning('Please Fill Details to add New Batch');
    }
  }
  addNewSubject() {
    if (this.subjectDetails.streams.length > 0 && this.subjectDetails.subject !== '') {
      this.spinnerService.show();
      this.classSer.addSubject(this.subjectDetails.streams, this.subjectDetails.subject).subscribe(success => {
        this.alertService.success('New Subject Created Successfully');
        console.log(success);
        this.getSubjects();
        this.streams.forEach((value, index) => {
          value.isChecked = false;
        });
        this.spinnerService.hide();
        this.modalService.dismissAll();
      }, error => {
        console.log(error);
        this.spinnerService.hide();
        this.alertService.danger('Error Creating New Subject');
      });
    } else {
      this.alertService.warning('Please Fill Details to add New Subject');
    }
  }
  addNewGroup() {
    if (this.groupDetails.name !== '') {
      this.spinnerService.show();
      this.classSer.addGroup(this.groupDetails.name).subscribe(success => {
        this.spinnerService.hide();
        this.alertService.success('New Group Created Successfully');
        console.log(success);
        this.getGroups();
        this.modalService.dismissAll();
      }, error => {
        this.spinnerService.hide();
        console.log(error);
        this.alertService.danger('Error Creating New Group');
      });
    } else {
      this.alertService.warning('Please Fill Details to add New Group');
    }
  }
}
