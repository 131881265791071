import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SlideshowModule } from 'ng-simple-slideshow';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountUpModule } from 'countup.js-angular2';
import { InViewportModule } from 'ng-in-viewport';
import { BarRatingModule } from 'ngx-bar-rating';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { OwlModule } from 'ngx-owl-carousel';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AlertModule } from 'ngx-alerts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { MatExpansionModule} from '@angular/material';
import {MatTabsModule} from '@angular/material/tabs';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { RecaptchaModule } from 'ng-recaptcha';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';

// Moodle additions
import { AdminAndTeacherGuardService } from './services/admin-and-teacher-guard.service';
import { ShowPipe } from './common/ShowPipe.pipe';
import { SearchPipe } from './common/SearchPipe.pipe';
import { DateProcessPipe } from './common/DateProcessPipe.pipe';
import { NoSanitizePipe } from './common/NoSanitizePipe.pipe';
import { CourseService } from './services/course.service';
import { FileDropModule } from 'ngx-file-drop';

import { PopoverModule } from 'ngx-bootstrap/popover';
import { QuillModule } from 'ngx-quill';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ColorPickerModule } from 'ngx-color-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { StatsComponent } from './stats/stats.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { CoursesComponent } from './courses/courses.component';
import { WhatwegiveComponent } from './whatwegive/whatwegive.component';
import { PricingComponent } from './pricing/pricing.component';
import { TeachersComponent } from './teachers/teachers.component';
import { LoginComponent } from './login/login.component';
import { InstructorComponent } from './instructor/instructor.component';
import { FooterComponent } from './footer/footer.component';
import { RegisterComponent } from './register/register.component';

import { AuthService } from './services/auth.service';
import { DashboardAdminComponent } from './dashboard/dashboard-admin/dashboard-admin.component';
import { DashboardNavbarComponent } from './dashboard/dashboard-navbar/dashboard-navbar.component';
import { AuthGuardService } from './services/auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { DashboardCardsComponent } from './dashboard/dashboard-cards/dashboard-cards.component';
import { EditProfileComponent } from './dashboard/edit-profile/edit-profile.component';
import { AddStudentComponent } from './dashboard/add-student/add-student.component';
import { ClassService } from './services/class.service';
import { SearchStudentComponent } from './dashboard/search-student/search-student.component';
import { ViewProfileStudentComponent } from './dashboard/view-profile-student/view-profile-student.component';
import { AddTeacherComponent } from './dashboard/add-teacher/add-teacher.component';
import { SearchTeacherComponent } from './dashboard/search-teacher/search-teacher.component';
import { ViewProfileTeacherComponent } from './dashboard/view-profile-teacher/view-profile-teacher.component';
import { AddAssistantComponent } from './dashboard/add-assistant/add-assistant.component';
import { SearchAssistantComponent } from './dashboard/search-assistant/search-assistant.component';
import { ViewProfileAssistantComponent } from './dashboard/view-profile-assistant/view-profile-assistant.component';
import { ViewClassesAssistantComponent } from './dashboard/view-classes-assistant/view-classes-assistant.component';
import { AddClassComponent } from './dashboard/add-class/add-class.component';
import { SearchClassComponent } from './dashboard/search-class/search-class.component';
import { EditClassComponent } from './dashboard/edit-class/edit-class.component';
import { EditStudentClassesComponent } from './dashboard/edit-student-classes/edit-student-classes.component';
import { AssignStudentClassesComponent } from './dashboard/assign-student-classes/assign-student-classes.component';
import { MarkAttendenceComponent } from './dashboard/mark-attendence/mark-attendence.component';
import { AttendenceService } from './services/attendence.service';
import { GenerateReportsComponent } from './dashboard/generate-reports/generate-reports.component';
import {HomepageService} from './services/homepage.service';
import { FrontTestComponent } from './front-test/front-test.component';
import { StudentDashboardComponent } from './dashboard/student-dashboard/student-dashboard.component';
import { CoursePageComponent } from './dashboard/course-page/course-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PaymentComponent } from './dashboard/payment/payment.component';
import { DetailsComponent } from './details/details.component';

// Moodle additions
import { CourseComponent } from './dashboard/course/course.component';
import { CourseSectionModalComponent } from './dashboard/course-item/course-section-modal/course-section-modal.component';
import { CourseSessionComponent } from './dashboard/course-item/course-session/course-session.component';
import { CourseDocumentModalComponent } from './dashboard/course-item/course-document-modal/course-document-modal.component';
import { CourseAssignmentModalComponent } from './dashboard/course-item/course-assignment-modal/course-assignment-modal.component';
import { CourseQuizModalComponent } from './dashboard/course-item/course-quiz-modal/course-quiz-modal.component';
import { CurrencyPipe } from '@angular/common';
import { CourseStudentComponent } from './dashboard/course-student/course-student.component';
import { CourseNoticeModalComponent } from './dashboard/course-item/course-notice-modal/course-notice-modal.component';
import { CourseForumModalComponent } from './dashboard/course-item/course-forum-modal/course-forum-modal.component';
import { AssignmentPageComponent } from './dashboard/assignment/assignment-page/assignment-page.component';
import { ViewSubmissionComponent } from './dashboard/assignment/view-submission/view-submission.component';
import { GradeAssignmentComponent } from './dashboard/assignment/grade-assignment/grade-assignment.component';
import { ForumPageComponent } from './dashboard/forum/forum-page/forum-page.component';
import { AssignmentSubmissionComponent } from './dashboard/assignment/assignment-submission/assignment-submission.component';
import { DiscussionTopicModalComponent } from './dashboard/forum/discussion-topic-modal/discussion-topic-modal.component';
import { ReplyModalComponent } from './dashboard/forum/reply-modal/reply-modal.component';
import { MessageModalComponent } from './dashboard/course-item/message-modal/message-modal.component';
import { MessageLogPageComponent } from './dashboard/course-item/message-log-page/message-log-page.component';
import { CourseMeetingModalComponent } from './dashboard/course-item/course-meeting-modal/course-meeting-modal.component';
import { CourseMeetingComponent } from './dashboard/course-meeting/course-meeting.component';
import { PaymentPageComponent } from './dashboard/payments/payment-page/payment-page.component';
import { PaymentResultComponent } from './dashboard/payments/payment-result/payment-result.component';
import { PayMonthComponent } from './dashboard/payments/pay-month/pay-month.component';
import { CourseSearchComponent } from './course-search/course-search.component';
import { BasicInformationComponent } from './dashboard/basic-information/basic-information.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CourseQuizComponent } from './dashboard/course-quiz/course-quiz.component';
import { MCQSearchComponent } from './dashboard/MCQ/mcq-search/mcq-search.component';
import { McqPageComponent } from './dashboard/MCQ/mcq-page/mcq-page.component';

export function tokenGetter() {
  return localStorage.getItem('token');
}
// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SlideshowComponent,
    HomepageComponent,
    AboutusComponent,
    StatsComponent,
    TestimonialsComponent,
    CoursesComponent,
    WhatwegiveComponent,
    PricingComponent,
    TeachersComponent,
    LoginComponent,
    InstructorComponent,
    FooterComponent,
    RegisterComponent,
    DashboardAdminComponent,
    DashboardNavbarComponent,
    DashboardCardsComponent,
    EditProfileComponent,
    AddStudentComponent,
    SearchStudentComponent,
    ViewProfileStudentComponent,
    AddTeacherComponent,
    SearchTeacherComponent,
    ViewProfileTeacherComponent,
    AddAssistantComponent,
    SearchAssistantComponent,
    ViewProfileAssistantComponent,
    ViewClassesAssistantComponent,
    AddClassComponent,
    SearchClassComponent,
    EditClassComponent,
    EditStudentClassesComponent,
    AssignStudentClassesComponent,
    MarkAttendenceComponent,
    GenerateReportsComponent,
    FrontTestComponent,
    StudentDashboardComponent,
    CoursePageComponent,
    ResetPasswordComponent,
    PaymentComponent,
    CourseComponent,
    CourseSectionModalComponent,
    CourseSessionComponent,
    CourseDocumentModalComponent,
    CourseAssignmentModalComponent,
    CourseStudentComponent,
    CourseNoticeModalComponent,
    CourseForumModalComponent,
    AssignmentPageComponent,
    ViewSubmissionComponent,
    GradeAssignmentComponent,
    NoSanitizePipe,
    ForumPageComponent,
    AssignmentSubmissionComponent,
    DiscussionTopicModalComponent,
    ReplyModalComponent,
    MessageModalComponent,
    MessageLogPageComponent,
    DateProcessPipe,
    ShowPipe,
    SearchPipe,
    CourseMeetingModalComponent,
    CourseMeetingComponent,
    CourseQuizComponent,
    CourseQuizModalComponent,
    PaymentPageComponent,
    PaymentResultComponent,
    PayMonthComponent,
    CourseSearchComponent,
    DetailsComponent,
    BasicInformationComponent,
    MCQSearchComponent,
    McqPageComponent,
  ],
  imports: [
    JwBootstrapSwitchNg2Module,
    NgbModule,
    Ng4LoadingSpinnerModule.forRoot(),
    AlertModule.forRoot({maxMessages: 5, timeout: 10000, position: 'left'}),
    InViewportModule,
    CountUpModule,
    BrowserAnimationsModule,
    SlideshowModule,
    BrowserModule,
    BarRatingModule,
    AppRoutingModule,
    SlickCarouselModule,
    OwlModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatExpansionModule,
    MatTabsModule,
    FileDropModule,
    PopoverModule.forRoot(),
    QuillModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ColorPickerModule,
    DragDropModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    RecaptchaModule,
    MatButtonModule,
    MatRadioModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    PdfJsViewerModule,
  ],
  providers: [AuthService,
    AuthGuardService,
    ClassService,
    AttendenceService,
    HomepageService,
    CourseService,
    CurrencyPipe,
    AdminAndTeacherGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
