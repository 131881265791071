import { MeetingEditModle } from '../../../models/MeetingEditModle';
import {MeetingModle} from '../../../models/MeetingModle';
import { CourseService } from './../../../services/course.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { UploadEvent, FileSystemFileEntry } from 'ngx-file-drop';
import {AssignmentEditModle} from '../../../models/AssignmentEditModle';
declare var $: any;
@Component({
  selector: 'app-course-meeting-modal',
  templateUrl: './course-meeting-modal.component.html',
  styleUrls: ['./course-meeting-modal.component.css']
})
export class CourseMeetingModalComponent implements OnInit {
  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  get meetingNameRef() {
    return this.form.get('meetingName');
  }
  get enableDateRef() {
    return this.form.get('enableDate');
  }
  get enableTimeRef() {
    return this.form.get('enableTime');
  }
  get disableDateRef() {
    return this.form.get('disableDate');
  }
  get disableTimeRef() {
    return this.form.get('disableTime');
  }
  get fromDateRef() {
    return this.form.get('fromDate');
  }
  get fromTimeRef() {
    return this.form.get('fromTime');
  }
  get toDateRef() {
    return this.form.get('toDate');
  }
  get toTimeRef() {
    return this.form.get('toTime');
  }
  get zoomIdRef() {
    return this.form.get('zoomId');
  }
  get zoomPasswordRef() {
    return this.form.get('zoomPassword');
  }
  get zoomLinkRef() {
    return this.form.get('zoomLink');
  }
  @Input() meetingVal: MeetingModle;
  @Input() meetingModel: MeetingModle;
  @Output() changeMeeting = new EventEmitter();

  modelTitle;
  showspinner;
  visibleTypes = ['Free', 'Paid for Class'];
  visibleTypeValue = 'Paid for Class';
  enableStatus;
  disableStatus;
  isNew;
  addButton;
  editButton;
  meetingId;
  updateModle: MeetingEditModle;

  form = new FormGroup({
    meetingName: new FormControl('', Validators.required),
    editor: new FormControl(),
    enableDate: new FormControl('', Validators.required),
    enableTime: new FormControl('', Validators.required),
    disableDate: new FormControl('', Validators.required),
    disableTime: new FormControl('', Validators.required),
    fromDate: new FormControl('', Validators.required),
    fromTime: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required),
    toTime: new FormControl('', Validators.required),
    zoomId: new FormControl('', Validators.required),
    zoomPassword: new FormControl('', Validators.required),
    zoomLink: new FormControl('', Validators.required)
  });
  ngOnInit() {}


  ngOnChanges (changes: SimpleChanges) {
    if (!this.meetingVal) {
      this.modelTitle = 'Add Meeting';
      this.meetingNameRef.setValue('');
      this.visibleTypeValue = 'Paid for Class';
      this.enableStatus = false;
      this.disableStatus = false;
      this.isNew = true;
      this.enableDateRef.setValue('');
      this.enableTimeRef.setValue('');
      this.enableDateRef.disable();
      this.enableTimeRef.disable();
      this.disableDateRef.setValue('');
      this.disableTimeRef.setValue('');
      this.disableDateRef.disable();
      this.disableTimeRef.disable();
      this.fromDateRef.setValue('');
      this.fromTimeRef.setValue('');
      this.toDateRef.setValue('');
      this.toTimeRef.setValue('');
      this.zoomIdRef.setValue('');
      this.zoomPasswordRef.setValue('');
      this.zoomLinkRef.setValue('');
      this.zoomIdRef.disable();
      this.zoomPasswordRef.disable();
      this.zoomLinkRef.disable();
      this.addButton = true;
      this.editButton = false;
    } else {
      this.modelTitle = 'Edit Meeting';
      this.getMeeting(this.meetingVal._id);
      this.updateModle = new MeetingEditModle(
        this.meetingVal._id,
        this.meetingVal.vertical_loc,
        this.meetingVal.horizontal_loc,
        this.meetingVal.show
      );
      this.visibleTypeValue = this.meetingVal.visible_type;
      this.enableStatus = this.meetingVal.enable_from.active;

      if (this.enableStatus) {
        this.enableDateRef.setValue(
          new Date(this.meetingVal.enable_from.date)
        );
        this.enableTimeRef.setValue(
          new Date(this.meetingVal.enable_from.date)
        );
        this.enableDateRef.enable();
        this.enableTimeRef.enable();
      } else {
        this.enableDateRef.setValue('');
        this.enableTimeRef.setValue('');
        this.enableDateRef.disable();
        this.enableTimeRef.disable();
      }
      this.disableStatus = this.meetingVal.disable_from.active;

      if (this.disableStatus) {
        this.disableDateRef.setValue(
          new Date(this.meetingVal.disable_from.date)
        );
        this.disableTimeRef.setValue(
          new Date(this.meetingVal.disable_from.date)
        );
        this.disableDateRef.enable();
        this.disableTimeRef.enable();
      } else {
        this.disableDateRef.setValue('');
        this.disableTimeRef.setValue('');
        this.disableDateRef.disable();
        this.disableTimeRef.disable();
      }

      this.isNew = false;
      this.zoomIdRef.enable();
      this.zoomPasswordRef.enable();
      this.zoomLinkRef.enable();
      this.addButton = false;
      this.editButton = true;
    }
  }

  getMeeting(componentId) {
    this.spinnerService.show();
    this.courseSer.getMeeting(componentId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.meetingId = response['data']._id;
        this.meetingNameRef.setValue(this.meetingVal.name);
        this.zoomIdRef.setValue(
          response['data'].meeting_id.meeting_id
        );
        this.zoomPasswordRef.setValue(
          response['data'].meeting_id.password
        );
        this.zoomLinkRef.setValue(
          response['data'].meeting_id.link
        );
        this.fromDateRef.setValue(
          new Date(response['data'].from)
        );
        this.fromTimeRef.setValue(
          new Date(response['data'].from)
        );
        this.toDateRef.setValue(
          new Date(response['data'].to)
        );
        this.toTimeRef.setValue(
          new Date(response['data'].to)
        );
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error during load.');
        $('#meetingModal').modal('hide');
      }
    );
  }

  togleEnableDate() {
    if (this.enableStatus) {
      this.enableDateRef.setValue('');
      this.enableTimeRef.setValue('');
      this.enableDateRef.disable();
      this.enableTimeRef.disable();
      this.enableStatus = !this.enableStatus;
    } else {
      this.enableDateRef.enable();
      this.enableTimeRef.enable();
      this.enableStatus = !this.enableStatus;
    }
  }
  togleDisableDate() {
    if (this.disableStatus) {
      this.disableDateRef.setValue('');
      this.disableTimeRef.setValue('');
      this.disableDateRef.disable();
      this.disableTimeRef.disable();
      this.disableStatus = !this.disableStatus;
    } else {
      this.disableDateRef.enable();
      this.disableTimeRef.enable();
      this.disableStatus = !this.disableStatus;
    }
  }
  togleIsNew() {
    if (this.isNew) {
      this.zoomIdRef.enable();
      this.zoomPasswordRef.enable();
      this.zoomLinkRef.enable();
      this.fromDateRef.setValue('');
      this.toDateRef.setValue('');
      this.fromTimeRef.setValue('');
      this.toTimeRef.setValue('');
      this.fromDateRef.disable();
      this.toDateRef.disable();
      this.fromTimeRef.disable();
      this.toTimeRef.disable();
      this.isNew = false;
    } else {
      this.zoomIdRef.setValue('');
      this.zoomPasswordRef.setValue('');
      this.zoomLinkRef.setValue('');
      this.zoomIdRef.disable();
      this.zoomPasswordRef.disable();
      this.zoomLinkRef.disable();
      this.fromDateRef.enable();
      this.toDateRef.enable();
      this.fromTimeRef.enable();
      this.toTimeRef.enable();
      this.isNew = true;
    }
  }
  changeVisibleType(item) {
    this.visibleTypeValue = item;
  }
  submitForm() {
    this.spinnerService.show();
    let data;
    if (!this.meetingVal) {
      this.meetingModel.name = this.meetingNameRef.value;
      this.meetingModel.zoom_id = this.zoomIdRef.value;
      this.meetingModel.zoom_password = this.zoomPasswordRef.value;
      this.meetingModel.zoom_link = this.zoomLinkRef.value;
      this.meetingModel.visible_type = this.visibleTypeValue;
      this.meetingModel.vertical_loc += 1;
      if (this.enableStatus) {
        this.meetingModel.enable_from.active = this.enableStatus;
        this.meetingModel.enable_from.date = new Date(
          this.enableDateRef.value.toDateString() +
          ' ' +
          this.enableTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
        );
      }
      if (this.disableStatus) {
        this.meetingModel.disable_from.active = this.disableStatus;
        this.meetingModel.disable_from.date = new Date(
          this.disableDateRef.value.toDateString() +
          ' ' +
          this.disableTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
        );
      }
      if (this.isNew) {
        this.meetingModel.from = new Date(
          this.fromDateRef.value.toDateString() +
          ' ' +
          this.fromTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
        );
        this.meetingModel.to = new Date(
          this.toDateRef.value.toDateString() +
          ' ' +
          this.toTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
        );
      }
      this.courseSer.addMeeting(this.meetingModel).subscribe (
        (response) => {
          data = response['data'];

          this.spinnerService.hide();
          this.changeMeeting.emit(data);
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger(error.error.message);

          data = 'error';
          this.changeMeeting.emit(data);
        }
      );
    } else {
      this.updateModle.name = this.meetingNameRef.value;
      this.meetingModel.zoom_id = this.zoomIdRef.value;
      this.meetingModel.zoom_password = this.zoomPasswordRef.value;
      this.meetingModel.zoom_link = this.zoomLinkRef.value;
      this.updateModle.visible_type = this.visibleTypeValue;
      if (this.enableStatus) {
        this.updateModle.enable_from.active = this.enableStatus;
        this.updateModle.enable_from.date = new Date(
          this.enableDateRef.value.toDateString() +
          ' ' +
          this.enableTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
        );
      } else {
        this.updateModle.enable_from.active = this.enableStatus;
        this.updateModle.enable_from.date = new Date();
      }

      if (this.disableStatus) {
        this.updateModle.disable_from.active = this.disableStatus;
        this.updateModle.disable_from.date = new Date(
          this.disableDateRef.value.toDateString() +
          ' ' +
          this.disableTimeRef.value.toLocaleTimeString() +
          ' GMT+0530'
        );
      } else {
        this.updateModle.disable_from.active = this.disableStatus;
        this.updateModle.disable_from.date = new Date();
      }

      // this.updateModle.from = new Date(
      //   this.fromDateRef.value.toDateString() +
      //   ' ' +
      //   this.fromTimeRef.value.toLocaleTimeString() +
      //   ' GMT+0530'
      // );
      // this.updateModle.to = new Date(
      //   this.toDateRef.value.toDateString() +
      //   ' ' +
      //   this.toTimeRef.value.toLocaleTimeString() +
      //   ' GMT+0530'
      // );

      this.courseSer
        .updateMeeting(this.meetingId, this.updateModle)
        .subscribe(
          (response) => {
            data = response['data'];
            this.spinnerService.hide();
            this.changeMeeting.emit(data);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger(
              'Error occurred while updating assignment'
            );

            data = 'error';
            this.changeMeeting.emit(data);
          }
        );
    }
  }

}
