import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { AssignmentModle } from "./../../../models/AssignmentModle";
import { FormGroup, FormControl } from "@angular/forms";
import { FileSystemFileEntry } from "ngx-file-drop";
import { UploadEvent } from "ngx-file-drop";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { AlertService } from "ngx-alerts";
import { CourseService } from "./../../../services/course.service";
import { Component, OnInit } from "@angular/core";
import { saveAs as importedSaveAs } from "file-saver";

declare var $: any;
@Component({
  selector: "assignment-page",
  templateUrl: "./assignment-page.component.html",
  styleUrls: ["./assignment-page.component.css"],
})
export class AssignmentPageComponent implements OnInit {
  classId;
  componentId;
  subject = "Information Technology";
  batch = "2020";
  group = "Theory";
  section = "June";
  session = "June 1 - June 7";
  assignment = "Assignment 1";
  showspinner;
  files = [];
  dropFileStatus;
  assignmentVal;
  assignmentModle;
  description;
  uploadedFiles = [];
  submissionType;
  remainingTime;

  form = new FormGroup({
    editor: new FormControl(),
  });
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _location: Location
  ) {}

  ngOnInit() {
    this.componentId = this.route.snapshot.paramMap.get("component_id");
    this.classId = this.route.snapshot.paramMap.get("class_id");
    this.section = this.route.snapshot.paramMap.get("section_name");
    this.session = this.route.snapshot.paramMap.get("session_name");
    this.assignment = this.route.snapshot.paramMap.get("component_name");
    this.getClassInfo();
    this.getAssignment();
  }

  config = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      // remove formatting button
      ["link"], // link and image, video
    ],
  };
  getClassInfo() {
    this.spinnerService.show();
    this.courseSer.getClassInfo(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        const responseData = response["data"];
        this.subject = responseData.subject_id.subject;
        this.batch = responseData.batch_id.year;
        this.group = responseData.group_id.group;
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger("Error occurred while getting class Info");
      }
    );
  }
  getAssignment() {
    this.spinnerService.show();
    this.courseSer.getAssignment(this.componentId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.description = response["data"].assignment.description;
        response["data"].file_info.forEach((element) => {
          this.uploadedFiles.push(element.file_id);
        });
        this.submissionType = response["data"].assignment.submission_type;
        let dueDate = new Date(response["data"].assignment.due_date);
        this.claculateRemainingTime(new Date(), dueDate);
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger("Error occurred while getting assignment.");
      }
    );
  }
  claculateRemainingTime(today, dueDate) {
    let diffMs = dueDate - today; // milliseconds
    if (diffMs > 0) {
      let diffDays = Math.floor(diffMs / 86400000); // days
      let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      this.remainingTime =
        diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes";
    } else {
      this.remainingTime = "Late submission.";
    }
  }
  backClicked() {
    this._location.back();
  }

  downloadFile(file) {
    this.spinnerService.show();
    this.courseSer.downloadFile(file._id).subscribe(
      (data) => {
        this.spinnerService.hide();
        importedSaveAs(data, file.file_original_name);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while downloading");
      },
      () => console.info("OK")
    );
  }

  openModel() {
    this.assignmentVal = new AssignmentModle(
      "classId",
      "head.section_id",
      "head._id",
      5
    );
    this.assignmentModle = new Object();
    $("#assignmentModal").modal("show");
  }
  loadSubmissionPage() {
    this.router.navigate([
      "submission",
      this.submissionType,
      this.classId,
      this.section,
      this.session,
      this.assignment,
      this.componentId,
    ]);
  }
}
