import { NoticeEditModle } from "./../../../models/NoticeEditModle";
import { NoticeModle } from "./../../../models/NoticeModle";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { AlertService } from "ngx-alerts";
import { CourseService } from "./../../../services/course.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  // ViewChild,
  // ElementRef,
} from "@angular/core";

@Component({
  selector: "course-notice-modal",
  templateUrl: "./course-notice-modal.component.html",
  styleUrls: ["./course-notice-modal.component.css"],
})
export class CourseNoticeModalComponent implements OnInit {
  @Input() noticeVal;
  @Input() noticeModle: NoticeModle;
  @Output() changeNotice = new EventEmitter();

  modelTitle;
  addButton = false;
  editButton = false;
  public color1: string = "#ffffff";
  backgroundColor;
  background_style;
  htmlContent;

  config = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      // remove formatting button
      ["link"], // link and image, video
    ],
  };

  form = new FormGroup({
    // noticeTitle: new FormControl(),
    editor: new FormControl("", Validators.required),
  });
  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.background_style = { height: "120px" };
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!this.noticeVal) {
      this.modelTitle = "Add Notice";
      this.color1 = "#e2e4ef";
      this.backgroundColor = this.color1;
      this.background_style = {
        "background-color": this.backgroundColor,
        height: "120px",
      };
      this.editorRef.setValue("");
      this.addButton = true;
      this.editButton = false;
    } else {
      this.modelTitle = "Edit Notice";
      this.getColor(this.noticeVal.name);
      this.editorRef.setValue(this.noticeVal.name);
      this.color1 = this.backgroundColor;
      this.background_style = {
        "background-color": this.backgroundColor,
        height: "120px",
      };
      this.addButton = false;
      this.editButton = true;
    }
  }

  get editorRef() {
    return this.form.get("editor");
  }

  getColor(str: string) {
    this.backgroundColor = str.substring(30, 37);
    // let removeData = " style='background-color: " + this.backgroundColor + ";'";
    // return str.replace(removeData, "");
  }
  setColor(data: any) {
    this.backgroundColor = data;
    this.background_style = { "background-color": data, height: "120px" };
  }

  setBackground() {
    let descripsion = this.editorRef.value;
    if (descripsion.substring(1, 4) != "div") {
      this.htmlContent =
        "<div style='background-color: " +
        this.backgroundColor +
        ";'>" +
        descripsion +
        "</div>";
    } else {
      let firstPart = descripsion.substring(0, 30);
      let secondPart = descripsion.substring(37);
      this.htmlContent = firstPart.concat(this.backgroundColor + secondPart);
    }
  }

  submitNotice() {
    this.spinnerService.show();
    let data;
    this.setBackground();
    if (!this.noticeVal) {
      this.noticeModle.name = this.htmlContent;
      this.noticeModle.vertical_loc += 1;

      this.courseSer.addNotice(this.noticeModle).subscribe(
        (response) => {
          data = response["data"];

          this.spinnerService.hide();
          this.changeNotice.emit(data);
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger("Error occurred while adding notice");

          data = "error";
          this.changeNotice.emit(data);
        }
      );
    } else {
      let noticeUpdateModle = new NoticeEditModle(
        this.noticeVal.vertical_loc,
        this.noticeVal.horizontal_loc,
        this.noticeVal.show
      );
      noticeUpdateModle.name = this.htmlContent;

      this.courseSer
        .updateNotice(this.noticeVal._id, noticeUpdateModle)
        .subscribe(
          (response) => {
            data = response["data"];

            this.spinnerService.hide();
            this.changeNotice.emit(data);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger("Error occurred while adding notice");

            data = "error";
            this.changeNotice.emit(data);
          }
        );
    }
  }
}
