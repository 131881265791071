import { ForumEditModle } from './../../../models/ForumEditModle';
import { ForumModle } from './../../../models/ForumModle';
import { CourseService } from './../../../services/course.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { UploadEvent, FileSystemFileEntry } from 'ngx-file-drop';

declare var $: any;
@Component({
  selector: 'course-forum-modal',
  templateUrl: './course-forum-modal.component.html',
  styleUrls: ['./course-forum-modal.component.css'],
})
export class CourseForumModalComponent implements OnInit {
  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  get forumNameRef() {
    return this.form.get('forumName');
  }
  get editorRef() {
    return this.form.get('editor');
  }
  @Input() forumVal;
  @Input() forumModle: ForumModle;
  @Output() changeForum = new EventEmitter();

  modelTitle;
  showspinner;

  files = [];
  addButton;
  editButton;
  forumId;

  form = new FormGroup({
    forumName: new FormControl('', Validators.required),
    editor: new FormControl(),
  });

  config = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      // remove formatting button
      ['link'], // link and image, video
    ],
  };

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.forumVal) {
      this.modelTitle = 'Add Forum';
      this.forumNameRef.setValue('');
      this.editorRef.setValue('');
      this.files = [];

      this.addButton = true;
      this.editButton = false;
    } else {
      this.modelTitle = 'Edit Forum';
      this.files = [];
      this.getForum(this.forumVal._id);

      this.forumNameRef.setValue(this.forumVal.name);
      this.addButton = false;
      this.editButton = true;
    }
  }

  getForum(componentId) {
    this.spinnerService.show();
    this.courseSer.getForum(componentId).subscribe(
      (response) => {
        this.spinnerService.hide();

        this.forumId = response['data'].forum._id;
        if (response['data'].file_info) {
          response['data'].file_info.forEach((element) => {
            this.files.push(element.file_id);
          });
        }
        this.editorRef.setValue(response['data'].forum.description);
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error during load.');
        $('#forumModal').modal('hide');
      }
    );
  }

  public dropped(event: UploadEvent) {
    let fileCount = event.files.length;

    for (const droppedFile of event.files) {
      this.showspinner = true;

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.courseSer.uploadFile(file).subscribe(
            (respons) => {
              this.files.push(respons['data'].file);

              fileCount -= 1;
              if (fileCount == 0) {
                this.showspinner = false;
              }
            },
            (error) => {
              this.showspinner = false;

              this.files = [];

              this.alertService.danger('Error occurred while uploading.');
            }
          );
        });
      } else {
        this.showspinner = false;
        this.files = [];

        this.alertService.danger('empty directories are added.');
      }
    }
  }

  deleteItem(item) {
    this.spinnerService.show();
    this.courseSer.deleteFile(item._id).subscribe(
      (response) => {
        this.spinnerService.hide();

        this.files.splice(this.files.indexOf(item), 1);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger('Error occurred while deleting.');
      }
    );
  }
  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  submitForum() {
    this.spinnerService.show();
    let data;
    if (!this.forumVal) {
      this.forumModle.name = this.forumNameRef.value;
      this.forumModle.description = this.editorRef.value;
      this.files.forEach((element) => {
        this.forumModle.files.push(element._id);
      });
      this.forumModle.vertical_loc += 1;

      this.courseSer.addForum(this.forumModle).subscribe(
        (response) => {
          data = response['data'];

          this.spinnerService.hide();
          this.changeForum.emit(data);
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger('Error occurred while adding forum');

          data = 'error';
          this.changeForum.emit(data);
        }
      );
    } else {
      const updateModle = new ForumEditModle(
        this.forumVal._id,
        this.forumVal.vertical_loc,
        this.forumVal.horizontal_loc,
        this.forumVal.show
      );

      updateModle.name = this.forumNameRef.value;
      updateModle.description = this.editorRef.value;
      this.files.forEach((element) => {
        updateModle.files.push(element._id);
      });

      this.courseSer.updateForum(this.forumId, updateModle).subscribe(
        (response) => {
          data = response['data'];

          this.spinnerService.hide();
          this.changeForum.emit(data);
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger('Error occurred while adding forum');

          data = 'error';
          this.changeForum.emit(data);
        }
      );
    }
  }
}
