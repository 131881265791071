import { EditSubmissionModle } from './../models/EditSubmissionModle';
import { MessageModle } from './../models/MessageModle';
import { GradeModle } from './../models/GradeModle';
import { NotSubmitedModle } from './../models/NotSubmitedModle';
import { ReplyMole } from './../models/ReplyModle';
import { AddAssignmentSubModle } from './../models/AddAssignmentSubModle';
import { AssignmentEditModle } from './../models/AssignmentEditModle';
import { NoticeEditModle } from './../models/NoticeEditModle';
import { ForumEditModle } from './../models/ForumEditModle';
import { NoticeModle } from './../models/NoticeModle';
import { ForumModle } from './../models/ForumModle';
import { DocumentEditModle } from './../models/DocumentEditModle';
import { AssignmentModle } from './../models/AssignmentModle';
import { SessionModle } from './../models/SessionModle';
import { DocumentModle } from './../models/DocumentModle';
import { SectionModle } from './../models/SectionModle';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { url } from '../../environments/url';
import {MeetingModle} from '../models/MeetingModle';
import {MeetingEditModle} from '../models/MeetingEditModle';
import {QuizModle} from '../models/QuizModle';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private http: HttpClient) {}

  public get_header() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    });
    return headers;
  }

  getSectionDetails(classId) {
    return this.http
      .get(url + '/courses/getSectionsByClass/' + classId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  getSessions(classId) {
    return this.http
      .get(url + '/courses/getSessionsByClass/' + classId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  getComponents(classId) {
    return this.http
      .get(url + '/courses/getComponentsByClass/' + classId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  getClassInfo(classId) {
    return this.http
      .get(url + '/classes/get/' + classId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  addSection(modle: SectionModle) {
    return this.http
      .post(url + '/courses/addSection', modle, { headers: this.get_header() })
      .pipe(map((res) => res));
  }

  updateSection(modle: SectionModle) {
    return this.http
      .post(
        url + '/courses/update/section/' + modle._id,
        { field: 'name', value: modle.name },
        { headers: this.get_header() }
      )
      .pipe(map((res) => res));
  }
  deleteSection(id) {
    return this.http
      .delete(url + '/courses/delete/section/' + id, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  swapSection(modle) {
    return this.http
      .post(url + '/courses/section/swapVerticalLoc', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  // session
  addSession(modle: SessionModle) {
    return this.http
      .post(url + '/courses/addSession', modle, { headers: this.get_header() })
      .pipe(map((res) => res));
  }

  updateSession(modle) {
    return this.http
      .post(
        url + '/courses/update/session/' + modle._id,
        { field: 'name', value: modle.name },
        { headers: this.get_header() }
      )
      .pipe(map((res) => res));
  }
  deleteSession(id) {
    return this.http
      .delete(url + '/courses/delete/session/' + id, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  swapSession(modle) {
    return this.http
      .post(url + '/courses/session/swapVerticalLoc', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  // files
  uploadFile(_file) {
    const formData = new FormData();
    formData.append('file', _file, _file.relativePath);

    // Headers
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    });

    return this.http
      .post(url + '/courses/uploadFiles', formData, {
        headers: headers,
      })
      .pipe(map((res) => res));
  }
  deleteFile(fileId) {
    return this.http
      .delete(url + '/courses/delete/file/' + fileId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  downloadFile(fileId) {
    return this.http
      .get(url + '/courses/get/file/' + fileId, {
        headers: this.get_header(),
        responseType: 'blob',
      })
      .pipe(map((res) => res));
  }

  uploadDocument(modle: DocumentModle) {
    return this.http
      .post(url + '/courses/addDocument', modle, { headers: this.get_header() })
      .pipe(map((res) => res));
  }

  getDocument(componentId) {
    return this.http
      .get(url + '/courses/getDocumentByComponentId/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  updateDocument(id, modle: DocumentEditModle) {
    return this.http
      .post(url + '/courses/update/document/' + id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  downloadDocument(componentId) {
    console.log('download', componentId);
    return this.http
      .get(url + '/courses/get/document/' + componentId, {
        headers: this.get_header(),
        responseType: 'blob',
      })
      .pipe(map((res) => res));
  }
  deleteDocument(componentId) {
    return this.http
      .delete(url + '/courses/delete/document/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  // assignments

  AddAssignment(modle: AssignmentModle) {
    return this.http
      .post(url + '/courses/addAssignment', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  // can't access student
  getAssignment(componentId) {
    return this.http
      .get(url + '/courses/getAssignmentByComponentId/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  //
  getAssignmentView(componentId) {
    return this.http
      .get(url + '/courses/get/assignment/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  updateAssignment(assignmentId, modle: AssignmentEditModle) {
    return this.http
      .post(url + '/courses/update/assignment/' + assignmentId, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  deleteAssignment(componentId) {
    return this.http
      .delete(url + '/courses/delete/assignment/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  addSubmission(modle: AddAssignmentSubModle) {
    return this.http
      .post(url + '/courses/addSubmission', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  updateSubmission(submission_id, modle: EditSubmissionModle) {
    return this.http
      .post(url + '/courses/update/submission/' + submission_id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  getSubmission(componentId) {
    return this.http
      .get(url + '/courses/getSubmissionsByAssignment/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  getSubmissionById(componentId, studentId) {
    return this.http
      .get(url + '/courses/get/submission/' + componentId + '/' + studentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  getStudents(classId) {
    return this.http
      .get(url + '/classes/get-assigned-students/' + classId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  gradeSubmission(submissionId, modle: GradeModle) {
    return this.http
      .post(url + '/courses/gradeSubmission/' + submissionId, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  gradeNotSubmited(modle: NotSubmitedModle) {
    return this.http
      .post(url + '/courses/gradeNotSubmitted', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  // forum

  addForum(modle: ForumModle) {
    return this.http
      .post(url + '/courses/addForum', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  getForum(componentId) {
    return this.http
      .get(url + '/courses/getForumByComponentId/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  updateForum(id, modle: ForumEditModle) {
    return this.http
      .post(url + '/courses/update/forum/' + id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  deleteForum(componentId) {
    return this.http
      .delete(url + '/courses/delete/forum/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  addDiscussionTopic(componentId, _description, _userId) {
    return this.http
      .post(
        url + '/courses/addDiscussion/' + componentId,
        { description: _description, user_id: _userId },
        {
          headers: this.get_header(),
        }
      )
      .pipe(map((res) => res));
  }
  getForumPosts(componentId) {
    return this.http
      .get(url + '/courses/getForumPosts/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  addReply(modle: ReplyMole) {
    return this.http
      .post(url + '/courses/addReply/' + modle.discussion_id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  // Notice
  addNotice(modle: NoticeModle) {
    return this.http
      .post(url + '/courses/addLabel', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  updateNotice(id, modle: NoticeEditModle) {
    return this.http
      .post(url + '/courses/update/label/' + id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  deleteNotice(componentId) {
    console.log('777', componentId);

    return this.http
      .delete(url + '/courses/delete/label/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  // meeting
  addMeeting(modle: MeetingModle) {
    console.log(modle);
    return this.http
      .post(url + '/courses/addMeeting', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  getMeeting(componentId) {
    return this.http
      .get(url + '/courses/getMeetingByComponentId/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  updateMeeting(id, modle: MeetingEditModle) {
    return this.http
      .post(url + '/courses/update/meeting/' + id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  deleteMeeting(componentId) {
    return this.http
      .delete(url + '/courses/delete/meeting/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  // register zoom meeting
  registerMeeting(componentId) {
    return this.http
      .get(url + '/courses/registerMeeting/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  // component
  updateComponentByField(component_id, modle) {
    return this.http
      .post(url + '/courses/update/component/' + component_id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  // deleteComponent(component_id) {
  //   return this.http
  //     .post(url + "/courses/delete/component/" + component_id, {
  //       headers: this.get_header(),
  //     })
  //     .pipe(map((res) => res));
  // }
  swapComponent(modle) {
    return this.http
      .post(url + '/courses/component/swapVerticalLoc', modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  // Message
  sendMessage(class_id, modle: MessageModle) {
    return this.http
      .post(url + '/courses/sendMessage/' + class_id, modle, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  getMessages(class_id) {
    return this.http
      .get(url + '/courses/getMessagesByClass/' + class_id, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }
  saveQuiz(quizObject: QuizModle): any {
    const saveUrl = (quizObject.mcq_id) ? '/courses/updateMCQ' : '/courses/addMCQ';
    return this.http
      .post(url + saveUrl, quizObject, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  deleteQuiz(componentId) {
    return this.http
      .delete(url + '/courses/delete/MCQComponent/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  getMCQ(componentId: string): any {
    return this.http
      .get(url + '/courses/get/MCQ/' + componentId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  getMCQById(mcqId: string): any {
    return this.http
      .get(url + '/courses/get/MCQById/' + mcqId, {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  getMCQs(): any {
    return this.http
      .get(url + '/courses/get/MCQs', {
        headers: this.get_header(),
      })
      .pipe(map((res) => res));
  }

  attemptMCQ(mcqId: string, studentId: string) {
    return this.http
      .post(url + '/courses/attemptMCQ', {
          "mcq_id": mcqId,
          "student_id": studentId
        }
        , {
          headers: this.get_header(),
        })
      .pipe(map((res) => res));
  }

  getMCQAttempts(mcqId: string, studentId: string) {
    return this.http
      .get(url + '/courses/get/MCQAttempts?student_id=' + studentId + '&mcq_id=' +mcqId
        ,{
          headers: this.get_header(),
        })
      .pipe(map((res) => res));
  }

// To do replace attempt MCQ endpoint
  submitAnswers(mcqId: string, attemptId?: string,  answers?: any, finished?: boolean) {
    return this.http
      .post(url + '/courses/MCQ/submitAnswers', {
          "mcq_id": mcqId,
          "attempt_id": attemptId,
          "finished": finished,
          "answers": answers
        }
        , {
          headers: this.get_header(),
        })
      .pipe(map((res) => res));
  }

  getMCQSubmissions(mcqId: string) {
    return this.http
      .get(url + '/courses/get/MCQSubmissions/' + mcqId
        ,{
          headers: this.get_header(),
        })
      .pipe(map((res) => res));
  }
}

