import {Component, NgZone, OnInit} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-details',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  user = { username: '', password: '' };
  recaptcha: any[];

  constructor(private auth: AuthService, private router: Router, private alertService: AlertService,
              private spinnerService: Ng4LoadingSpinnerService, private zone: NgZone) { }

  ngOnInit() {
    this.spinnerService.hide();
  }

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
  }

  login() {
    if (this.user.username !== '' && this.user.password !== '') {
      if (this.recaptcha === undefined || this.recaptcha === null) {
        this.alertService.danger('Please select reCAPTCHA');
        return;
      }

      this.spinnerService.show();
      this.auth.login(this.user.username, this.user.password, this.recaptcha).subscribe(success => {
        console.log(success);
        if (success['success']) {
          localStorage.setItem('token', success['token']);
          localStorage.setItem('role', this.getDecodedAccessToken(success['token'])['role']); // save token in localstorage
          localStorage.setItem('username', this.getDecodedAccessToken(success['token'])['username']); // save token in localstorage
          localStorage.setItem('user_id', this.getDecodedAccessToken(success['token'])['user_id']); // save token in localstorage
          localStorage.setItem('registered', this.getDecodedAccessToken(success['token'])['registered']);
          localStorage.setItem('first_name', this.getDecodedAccessToken(success['token'])['first_name']);
          this.spinnerService.hide();
          if (!this.getDecodedAccessToken(success['token'])['registered'] ) {
            this.zone.run(() => this.router.navigate(['/userInfo']));
            // this.router.navigate(['userInfo']);
          } else if (this.getDecodedAccessToken(success['token'])['role'] === 'student') {
            this.zone.run(() => this.router.navigate(['/studentDashboard']));
            // this.router.navigate(['studentDashboard']);
          } else {
            this.zone.run(() => this.router.navigate(['/dashboard']));
            // this.router.navigate(['dashboard']);
            }
        } else {
          this.spinnerService.hide();
          this.alertService.danger(success['message']);
        }
      }, error => {
        // console.log(error);
        this.alertService.danger('Something went wrong while processing your request. Please Try Again');
      });
    } else {
      this.alertService.danger('Please Fill all the fields');
    }
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  reset_password() {
    this.router.navigate(['resetPassword']);
  }
}
