import { Component, OnInit, Inject } from '@angular/core';
import { ClassService } from 'src/app/services/class.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'ngx-alerts';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators} from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-generate-reports',
  templateUrl: './generate-reports.component.html',
  styleUrls: ['./generate-reports.component.scss']
})
export class GenerateReportsComponent implements OnInit {
  focus: any;
  role = '';
  user_id = '';
  selectRole = 'TEACHER';
  allObject = {_id: '', first_name: '--ALL--', last_name: '', user_id: 'ALL'};
  selectName: any;
  selectMethod = 'ALL';
  selectClass = 'ALL';
  displayName = '';
  names = [];
  payment_details = {};
  today = new Date();
  from = '' ;
  to = '';
  classes = [];
  teachers = [];
  students = [];
  show = false;
  class_details = {};
  profile = {};
  form_group: FormGroup;

  constructor(private classSer: ClassService,
              private authSer: AuthService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private spinnerService: Ng4LoadingSpinnerService,
              private router: Router,
              private fb: FormBuilder,
              @Inject(DOCUMENT) document) { }


  ngOnInit() {
    this.selectName = this.allObject;
    this.displayName = 'ALL';
    const year = this.today.getFullYear();
    const month = this.today.getMonth() + 1;
    const date = this.today.getDate();
    let str_month = month.toString();
    let str_date = date.toString();
    if (month < 10) {
      str_month = '0' + str_month;
    }
    if (date < 10) {
      str_date = '0' + str_date;
    }
    this.from = year.toString() + '-' + str_month + '-' + str_date;
    this.to = year.toString() + '-' + str_month + '-' + str_date;
    this.form_group = this.fb.group({rowItems: this.fb.array([])});
    this.role = localStorage.getItem('role');
    this.user_id = localStorage.getItem('user_id');
    this.getClassDetails();
    if (this.role === 'teacher') {
      this.selectRole = 'TEACHER';
      this.getTeacherProfile();
      this.getTeachingClasses(this.user_id);
    } else if (this.role === 'student') {
      this.selectRole = 'STUDENT';
      this.getStudentProfile();
      this.getAttendingClasses(this.user_id);
    } else if (this.role === 'admin' || this.role === 'assistant') {
      this.getTeachers();
    }
  }
  getTeachers() {
    this.spinnerService.show();
    this.teachers = [];
    this.authSer.getTeachers().subscribe(success => {
      this.spinnerService.hide();
      for (let i = 0; i < success['data'].length; i++) {
        this.teachers.push(success['data'][i]);
      }
      this.names = this.teachers;
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error Getting Classes');
      console.log(error);
    });
  }
  getStudents() {
    this.spinnerService.show();
    this.students = [];
    this.authSer.getStudents().subscribe(success => {
      this.spinnerService.hide();
      for (let i = 0; i < success['data'].length; i++) {
        this.students.push(success['data'][i]);
      }
      this.names = this.students;
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error Getting Classes');
      console.log(error);
    });
  }
  getTeachingClasses(teacher_id) {
    this.spinnerService.show();
    this.classes = [];
    this.authSer.getAllTeachingClassIds(teacher_id).subscribe(success => {
      this.spinnerService.hide();
      for (let i = 0; i < success['data'].length; i++) {
        this.classes.push(success['data'][i]);
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error Getting Classes');
      console.log(error);
    });
  }
  getAttendingClasses(student_id) {
    this.spinnerService.show();
    this.classes = [];
    this.authSer.getAllAttendingClassIds(student_id).subscribe(success => {
      this.spinnerService.hide();
      for (let i = 0; i < success['data'].length; i++) {
        this.classes.push(success['data'][i]);
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error Getting Classes');
      console.log(error);
    });
  }
  getClassDetails() {
    this.spinnerService.show();
    this.class_details = {};
    this.classSer.getClassesMap().subscribe(success => {
      this.spinnerService.hide();
      this.class_details = success['data'];
      if (this.role === 'admin' || this.role === 'assistant'){
        this.classes = Object.keys(this.class_details);
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error Getting Classes');
      console.log(error);
    });
  }
  getTeacherProfile() {
    this.spinnerService.show();
    this.profile = {};
    this.authSer.getTeacherProfileByUser(this.user_id).subscribe(success => {
      this.spinnerService.hide();
      this.profile = success['data'];
      this.displayName = this.profile['first_name'] + ' ' + this.profile['last_name'];
      this.allObject.first_name = this.profile['first_name'] + ' ';
      this.allObject.last_name = this.profile['last_name'];
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error getting user profile');
      console.log(error);
    });
  }
  getStudentProfile() {
    this.spinnerService.show();
    this.profile = {};
    this.authSer.getStudentProfile(this.user_id).subscribe(success => {
      this.spinnerService.hide();
      this.profile = success['data'];
      this.displayName = this.profile['first_name'] + ' ' + this.profile['last_name'];
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error getting user profile');
      console.log(error);
    });
  }
  changeRole() {
    console.log(this.selectRole);
    if (this.selectRole === 'TEACHER' && this.teachers.length === 0) {
      this.getTeachers();
    } else if (this.selectRole === 'STUDENT' && this.students.length === 0) {
      this.getStudents();
    }
  }
  changeName() {
    if (this.selectName['user_id'] !== 'ALL') {
      this.displayName = this.selectName['first_name'] + ' ' + this.selectName['last_name'];
      if (this.selectRole === 'TEACHER') {
        this.getTeachingClasses(this.selectName['user_id']);
      } else if (this.selectRole === 'STUDENT') {
        this.getAttendingClasses(this.selectName['user_id']);
      }
    } else if (this.selectName['user_id'] === 'ALL') {
      this.displayName = 'All';
    }
  }
  addEntry() {
    (<FormArray>this.form_group.get('rowItems')).push(this.fb.group({
      reason: [''],
      amount: ['']
    }));
  }

  get total_deductions(): number {
    let summ = 0;
    if ((<FormArray>this.form_group.get('rowItems')) !== null) {
      const array = this.form_group.get('rowItems') as FormArray;
      for (let i = 0; i < array.length; i++) {
        summ += array.at(i).value.amount;
        // console.log(array.at(i).value.amount);
      }
    }
    return summ;
  }
  generate() {
    this.spinnerService.show();
    this.show = false;
    this.form_group = this.fb.group({rowItems: this.fb.array([])});
    this.show = true;
    console.log(this.selectRole + this.selectName['user_id'] + this.selectMethod + this.selectClass + this.from + this.to);
    this.classSer.generateReports(this.selectRole, this.selectName['user_id'], this.selectMethod, this.selectClass,
      this.from + ' GMT+0530', this.to + ' GMT+0530')
      .subscribe(success => {
      console.log(success['data']);
      this.payment_details = success['data'];
      this.spinnerService.hide();
      this.alertService.success('Report Successfully generated');
    }, error => {
        this.spinnerService.hide();
        this.alertService.danger(error['error']['message']);
        console.log(error);
    });
  }

  generatePDF() {
    // tslint:disable-next-line:max-line-length
    // const doc = new jsPDF;
    // let source = document.getElementById('receipt');
    // let specialElementHandlers = {
    //   '#elementH': function (element, renderer) {
    //     return true;
    //   }
    // };
    // doc.fromHTML(
    //   source,
    //   15,
    //   15,
    //   {
    //     'width': 180,
    //     'elementHandlers': specialElementHandlers
    //   });
    // doc.text('Income Receipt', 100, 37);
    // doc.addImage(logo, 'png', 70, 25, 30, 20)
    const doc = new jsPDF('p', 'pt', 'a4'); // A4 size page of PDF
    const data = document.getElementById('receipt');  // Id of the table
    html2canvas(data).then(canvas => {
      console.log(canvas.height);
      console.log(canvas.width);
      // Few necessary setting options
      const imgWidth = 590;
      const pageHeight = 842;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      const position = 0;
      doc.addImage(contentDataURL, 'PNG', position, position, imgWidth, imgHeight);
      doc.save('MYPdf.pdf'); // Generated PDF
      return doc;
    });
  }

  viewPDF() {

  }

  savePDF() {
    // const doc = this.generatePDF();
    // doc.output('dataurlnewwindow', 'first.pdf');
    // doc.save('first.pdf');
  }
}
