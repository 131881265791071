import { NotSubmitedModle } from "./../../../models/NotSubmitedModle";
import { CourseService } from "./../../../services/course.service";
import { AlertService } from "ngx-alerts";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "view-submission",
  templateUrl: "./view-submission.component.html",
  styleUrls: ["./view-submission.component.css"],
})
export class ViewSubmissionComponent implements OnInit {
  subject = "Information Technology";
  batch = "2020";
  group = "Theory";
  section = "June";
  session = "June 1 - June 7";
  assignment = "Assignment 1";
  componentId;
  classId;
  submitedStudents;
  studentsResponse;
  students;
  submissionType;
  searchInput: string;
  dueDate;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _location: Location
  ) {}
  p: number = 1;
  ngOnInit() {
    this.componentId = this.route.snapshot.paramMap.get("component_id");
    this.submissionType = this.route.snapshot.paramMap.get("submission_type");
    this.classId = this.route.snapshot.paramMap.get("class_id");
    this.section = this.route.snapshot.paramMap.get("section_name");
    this.session = this.route.snapshot.paramMap.get("session_name");
    this.assignment = this.route.snapshot.paramMap.get("component_name");
    this.getClassInfo();
    this.getSubmissions();
    this.getStudents();
    this.getAssignment();
  }

  search() {
    let tempArray = [];
    this.studentsResponse.forEach((element) => {
      let studentObj = element.student_id;
      if (
        studentObj.student_id == this.searchInput ||
        studentObj.first_name == this.searchInput ||
        studentObj.last_name == this.searchInput ||
        studentObj.first_name + " " + studentObj.last_name == this.searchInput
      ) {
        tempArray.push(element);
      }
    });
    if (tempArray.length != 0) {
      this.students = tempArray;
    } else {
      this.students = this.studentsResponse;
    }
  }
  gradeAssignment() {
    this.router.navigate(["assignment/grade"]);
  }
  backClicked() {
    this._location.back();
  }
  getClassInfo() {
    this.spinnerService.show();
    this.courseSer.getClassInfo(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        const responseData = response["data"];
        this.subject = responseData.subject_id.subject;
        this.batch = responseData.batch_id.year;
        this.group = responseData.group_id.group;
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger("Error occurred while getting class Info");
      }
    );
  }
  getSubmissions() {
    this.spinnerService.show();
    this.courseSer.getSubmission(this.componentId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.submitedStudents = response["data"];
        console.log(response["data"]);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while getting submission.");
      }
    );
  }
  getAssignment() {
    this.courseSer.getAssignmentView(this.componentId).subscribe(
      (response) => {
        this.dueDate = response["data"].assignment.due_date;
        console.log("tt", this.dueDate);
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger("Error occurred while getting Assignment");
      }
    );
  }
  getStudents() {
    this.spinnerService.show();
    this.courseSer.getStudents(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        this.studentsResponse = response["data"];
        this.students = this.studentsResponse;
        console.log(response["data"]);
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while getting submission.");
      }
    );
  }

  // public greaterThan(time: string) {
  //   return new Date(time) < new Date(this.dueDate);
  // }

  gradeSubmited(submitedStudent) {
    this.router.navigate([
      "assignment/grade",
      this.componentId,
      submitedStudent._id,
      submitedStudent.student_id._id,
    ]);
  }
  gradeNotSubmited(student) {
    this.router.navigate([
      "grade",
      student.student_id._id,
      this.componentId,
      this.submissionType,
    ]);
  }
}
