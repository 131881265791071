export class ReplyMole {
  component_id: string;
  description: string;
  user_id: string;
  discussion_id: string;

  constructor(componentId, discussionId) {
    this.component_id = componentId;
    this.discussion_id = discussionId;
  }
}
