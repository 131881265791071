export class DocumentModle {
  _id;
  class_id: string;
  section_id: string;
  session_id: string;
  visible_type: string;
  amount: number;
  vertical_loc: number;
  horizontal_loc: number;
  show: boolean;
  enable_from: DateObject;
  disable_from: DateObject;
  name: string;
  files: string[];

  constructor(classId, sectionId, sessionId, verticalLoc) {
    this.class_id = classId;
    this.section_id = sectionId;
    this.session_id = sessionId;
    this.vertical_loc = verticalLoc;
    this.amount = 0;
    this.horizontal_loc = 1;
    this.show = true;
    this.enable_from = new DateObject();
    this.disable_from = new DateObject();
    this.files = [];
  }
}

class DateObject {
  active: boolean;
  date: Date;
  constructor() {
    this.active = false;
    this.date = new Date();
  }
}
