import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClassService } from 'src/app/services/class.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { url } from '../../../environments/url';
import { CourseService } from './../../services/course.service';
import { ActivatedRoute } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

// import { ZoomMtg } from '@zoomus/websdk';
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();
// import { ZoomMtg } from 'zoomus-jssdk';
//
// ZoomMtg.setZoomJSLib('https://source.zoom.us/1.8.1/lib', '/av');

@Component({
  selector: 'app-course-meeting',
  templateUrl: './course-meeting.component.html',
  styleUrls: ['./course-meeting.component.css']
})
export class CourseMeetingComponent implements OnInit {

  meeting_config = {
    apiKey: 'I5XMmKbdTp-oZMYQYSHTUQ',
    meetingNumber: undefined,
    role: undefined,
    leaveUrl: '',
    userName: 'Student',
    userEmail: '',
    passWord: '',
    link: ''
  };
  componentId;
  classId;
  section;
  session;
  meeting;
  subject;
  batch;
  group;
  teacherName;
  role;
  show_nav = true;
  base_url = 'https://metaspace.edu.lk';
  // base_url = 'http://localhost:4200';
  constructor(
    @Inject(DOCUMENT) document,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private classSer: ClassService,
    private courseService: CourseService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.componentId = this.route.snapshot.queryParamMap.get('component_id');
    this.classId = this.route.snapshot.queryParamMap.get('class_id');
    this.section = this.route.snapshot.queryParamMap.get('section_name');
    this.session = this.route.snapshot.queryParamMap.get('session_name');
    this.meeting = this.route.snapshot.queryParamMap.get('component_name');
    this.getClassInfo();
    this.role = localStorage.getItem('role');
    if (this.role === 'teacher' || this.role === 'assistant' || this.role === 'admin') {
      this.meeting_config.role = 1;
      this.meeting_config.leaveUrl = this.base_url + '/classes/course/' + this.classId.toString();
    } else if (this.role === 'student') {
      this.meeting_config.role = 0;
      this.meeting_config.leaveUrl = this.base_url + '/studentDashboard/course/' + this.classId.toString();
    }
    this.meeting_config.userName =  this.getDecodedAccessToken(localStorage.getItem('token'))['username'] + '(' +
      this.getDecodedAccessToken(localStorage.getItem('token'))['first_name']  + ')';
    this.getZoomMeeting();
  }

  getClassInfo() {
    this.spinnerService.show();
    this.courseService.getClassInfo(this.classId).subscribe(
      (response) => {
        this.spinnerService.hide();
        const responseData = response['data'];
        this.subject = responseData.subject_id.subject;
        this.batch = responseData.batch_id.year;
        this.group = responseData.group_id.group;
        this.teacherName =
          responseData.teacher_id.first_name +
          ' ' +
          responseData.teacher_id.last_name;
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error occurred while getting class Info');
      }
    );
  }

  getSignature() {
    this.spinnerService.show();
    this.classSer.getZoomSignature(this.meeting_config.meetingNumber, this.meeting_config.role).subscribe(
      (data) => {
        if (data['signature']) {
          console.log(data['signature']);
          this.startMeeting(data['signature']);
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.alertService.danger('Could Not Join the Class');
          console.log(data);
        }
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Could Not Join the Class');
        console.log(error);
      }
    );
  }
  getZoomMeeting() {
    this.spinnerService.show();
    this.courseService.getMeeting(this.componentId).subscribe(
      (data) => {
        if (data['data'].meeting_id) {
          console.log(data['data'].meeting_id);
          this.meeting_config.meetingNumber = data['data'].meeting_id.meeting_id;
          this.meeting_config.passWord = data['data'].meeting_id.password;
          this.meeting_config.link = data['data'].meeting_id.link;
          console.log(this.meeting_config);
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.alertService.danger('This meeting is still not scheduled');
          console.log(data);
        }
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger('Error while retrieving meeting data');
        console.log(error);
      }
    );
  }

  startMeeting(signature) {
    this.show_nav = false;
    // let y = (document.getElementById('zmmtg-root'));
    // console.log("Hi from zoom debug");
    // console.log(y);
    // const x = (document.getElementById('zmmtg-root').style.display = 'block');
    //
    // ZoomMtg.init({
    //   leaveUrl: this.meeting_config.leaveUrl,
    //   isSupportAV: true,
    //   disableInvite: true,
    //   success: (success) => {
    //     console.log(success);
    //
    //     ZoomMtg.join({
    //       signature: signature,
    //       meetingNumber: this.meeting_config.meetingNumber,
    //       userName: this.meeting_config.userName,
    //       apiKey: this.meeting_config.apiKey,
    //       userEmail: this.meeting_config.userEmail,
    //       passWord: this.meeting_config.passWord,
    //       success: (success_rec) => {
    //         console.log(success_rec);
    //         this.alertService.success('Successfully Joining the meeting');
    //       },
    //       error: (error) => {
    //         this.alertService.danger(error.errorMessage);
    //         console.log(error);
    //       },
    //     });
    //   },
    //   error: (error) => {
    //     this.alertService.danger(error.errorMessage);
    //     console.log(error);
    //   },
    // });
  }

  joinWithLink() {
    // window.location.href = this.meeting_config.link;
    window.open(this.meeting_config.link, '_blank');
  }
  joinWithBrowser() {
    // window.location.href = this.meeting_config.link;
    window.open('https://us02web.zoom.us/j/5950455289?pwd=TllvZThVSlJ2R3p3TFJqTXF4TStNZz09', '_blank');
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

}
