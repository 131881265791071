import {Component, OnInit, SimpleChanges} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AlertService } from 'ngx-alerts';
import { Router } from '@angular/router';
import { ClassService } from 'src/app/services/class.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-search-class',
  templateUrl: './search-class.component.html',
  styleUrls: ['./search-class.component.scss']
})
export class SearchClassComponent implements OnInit {
  focus: any;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  searchTxt = '';
  session_modle: any;
  selectedValue = '';
  classes = [];
  deleteClass_: any;
  showClass_: any;
  session_class = {class_id: '', name: '', _for: ''};
  teacher = true;
  assistant = true;
  student = true;
  role = '';
  user_id = '';

  form = new FormGroup({
    sessionTitle: new FormControl('', Validators.required),
    classDate: new FormControl('', Validators.required),
  });
  constructor(private classSer: ClassService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.user_id = localStorage.getItem('user_id');
    if (this.role === 'teacher') {
      this.teacher = false;
      this.getClassesByTeacher();
    } else if (this.role === 'assistant') {
      this.assistant = false;
      this.getClassesForStaff();
    } else if (this.role === 'student') {
      this.student = false;
    } else if (this.role === 'admin'){
      this.getClassesForStaff();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sessionNameRef.setValue('');
    this.classDateRef.setValue('');
  }

  get sessionNameRef() {
    return this.form.get('sessionTitle');
  }
  get classDateRef() {
    return this.form.get('classDate');
  }
  submitSession() {
    this.session_class.name = this.sessionNameRef.value;
    let date = new Date(this.classDateRef.value);
    this.session_class._for = date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate().toString();
    this.session_modle.close();
    this.spinnerService.show();
    this.classSer.enableMarking(this.session_class).subscribe(success => {
      if (success['success']) {
        this.alertService.success('Created the session successfully');
        this.spinnerService.hide();
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while creating the session');
      console.log(error);
    });
  }
  add_session(content, class_id) {
    this.session_class.class_id = class_id;
    this.session_class.name = '';
    this.session_class._for = null;
    this.session_modle = this.modalService.open(content);
  }
  getClasses() {
    this.spinnerService.show();
    this.classes = [];
    this.classSer.getClasses().subscribe(success => {
      for (let i = 0; i < success['data'].length; i++) {
        this.classes.push(success['data'][i]);
      }
      this.spinnerService.hide();
    }, error => {
      this.alertService.danger('Error Getting Classes');
      console.log(error);
      this.spinnerService.hide();
    });
  }

  getClassesForStaff() {
    this.spinnerService.show();
    this.classes = [];
    this.classSer.getClassesForStaff().subscribe(success => {
      for (let i = 0; i < success['data'].length; i++) {
        this.classes.push(success['data'][i]);
      }
      this.spinnerService.hide();
    }, error => {
      this.alertService.danger('Error Getting Classes');
      console.log(error);
      this.spinnerService.hide();
    });
  }

  getClassesByTeacher() {
    this.spinnerService.show();
    this.classes = [];
    this.classSer.getClassesByTeacher(this.user_id).subscribe(success => {
      for (let i = 0; i < success['data'].length; i++) {
        this.classes.push(success['data'][i]);
      }
      this.spinnerService.hide();
    }, error => {
      this.alertService.danger('Error Getting Classes');
      console.log(error);
      this.spinnerService.hide();
    });
  }
  // onChange(class_, $event) {
  //   if ($event.currentValue) {
  //     this.classSer.enableMarking(class_['_id'], class_).subscribe(succes => {
  //       this.alertService.success('Enabled For Marking');
  //     }, error => {
  //       this.alertService.danger('Error Enabling Class for marking');
  //       console.log(error);
  //     });
  //   } else {
  //     this.classSer.disableMarking(class_['_id'], class_).subscribe(succes => {
  //       this.alertService.warning('Disabled For Marking');
  //     }, error => {
  //       this.alertService.danger('Error Disabling Class for marking');
  //       console.log(error);
  //     });
  //   }
  // }
  search() {
    console.log(this.selectedValue);
    // if (this.searchTxt !== '') {
    //   this.classes = [];
    //   if (this.selectedValue === 'bySub') {
    //     this.classSer.getClassesBySubject(this.searchTxt).subscribe(success => {
    //       for (let i = 0; i < success['data'].length; i++) {
    //         this.classes.push(success['data'][i]);
    //       }
    //     }, error => {
    //       console.log(error);
    //     });
    //   } else if (this.selectedValue === 'byTeacher') {
    //     this.classSer.getClassesByTeacher(this.searchTxt).subscribe(success => {
    //       for (let i = 0; i < success['data'].length; i++) {
    //         this.classes.push(success['data'][i]);
    //       }
    //     }, error => {
    //       console.log(error);
    //     });
    //   } else if (this.selectedValue === 'byBatch') {
    //     this.classSer.getClassesByBatch(this.searchTxt).subscribe(success => {
    //       for (let i = 0; i < success['data'].length; i++) {
    //         this.classes.push(success['data'][i]);
    //       }
    //     }, error => {
    //       console.log(error);
    //     });
    //   }
    // } else {
    //   this.getClasses();
    // }
  }
  deleteClass(content, class_) {
    this.deleteClass_ = class_;
    this.modalService.open(content);
  }
  showClass(content, class_) {
    this.showClass_ = class_;
    this.modalService.open(content);
  }
  deleteClassF() {
    this.classSer.deleteClass(this.deleteClass_['_id']).subscribe(success => {
      this.alertService.success('Class Deleted Successfully');
      this.getClasses();
      this.deleteClass_ = null;
      this.modalService.dismissAll();
    }, error => {
      console.log(error['error']['success']);
      if (error['error']['success'] === false) {
        this.alertService.danger(error['error']['message']);
      } else {
        this.alertService.danger('Error Occured While Deleting Class');
      }
      this.getClassesForStaff();
      this.deleteClass_ = null;
      this.modalService.dismissAll();
    });
  }
  editClasses(class_) {
    localStorage.setItem('class', JSON.stringify(class_));
    this.router.navigate(['editClass']);
  }
  assignStudents(class_) {
    localStorage.setItem('class', JSON.stringify(class_));
    this.router.navigate(['assignStudentClass']);
  }
}
