import { ReplyMole } from "./../../../models/ReplyModle";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { AlertService } from "ngx-alerts";
import { CourseService } from "./../../../services/course.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "reply-modal",
  templateUrl: "./reply-modal.component.html",
  styleUrls: ["./reply-modal.component.css"],
})
export class ReplyModalComponent implements OnInit {
  @Input() replyVal;
  @Input() replyModle: ReplyMole;
  @Output() changeReply = new EventEmitter();

  modelTitle = "Add Reply";
  addButton;
  editButton;

  form = new FormGroup({ editor: new FormControl("", Validators.required) });
  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  ngOnInit() {}

  config = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      // remove formatting button
      ["link"], // link and image, video
    ],
  };

  ngOnChanges(changes: SimpleChanges) {
    if (!this.replyVal) {
      this.modelTitle = "Add Reply";
      this.editorRef.setValue("");
      this.addButton = true;
      this.editButton = false;
    } else {
      this.modelTitle = "Edit Discussion Topic";
    }
  }
  get editorRef() {
    return this.form.get("editor");
  }

  submitForm() {
    this.spinnerService.show();
    let data;
    if (!this.replyVal) {
      this.replyModle.user_id = localStorage.getItem("user_id");
      this.replyModle.description = this.editorRef.value;
      this.courseSer.addReply(this.replyModle).subscribe(
        (response) => {
          data = response["data"];

          this.spinnerService.hide();
          this.changeReply.emit(data);
        },
        (error) => {
          data = "error";
          this.spinnerService.hide();
          this.alertService.danger("Error occurred while adding reply.");
          this.changeReply.emit(data);
        }
      );
    } else {
    }
  }
}
