export class NoticeEditModle {
  vertical_loc: number;
  horizontal_loc: number;
  show: boolean;
  name: string;

  constructor(verticalLoc, horizontalLoc, show) {
    this.vertical_loc = verticalLoc;
    this.horizontal_loc = horizontalLoc;
    this.show = show;
  }
}
