import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!args) {
      return value;
    }

    var searchText = args[0];
    var attrib = args[1];
    return value.filter((val) => {
      let rVal: boolean;

      if (attrib === 'all') {
        return true;
      }

      if (attrib === 'name') {
        rVal = (val['student']['first_name'].toLocaleLowerCase().includes(searchText) || val['student']['last_name'].toLocaleLowerCase().includes(searchText));
      } else {
        rVal = (val[attrib].toString().toLocaleLowerCase().includes(searchText));
      }
      return rVal;
    })

  }

}