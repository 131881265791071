import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AlertService } from 'ngx-alerts';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  username = '';
  mobile = '';
  password = '';
  retype_password = '';
  otp_code = '';
  otp_id = '';
  otp_button_text = 'Send OTP';
  otp_sent = false;
  reset = false;
  recaptcha: any[];

  constructor(private router: Router, private auth: AuthService, private alertService: AlertService,
              private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
  }

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
  }
  send_otp() {
    this.spinnerService.show();
    if (this.recaptcha === undefined || this.recaptcha === null) {
      this.alertService.danger('Please select reCAPTCHA');
      return;
    }
    if (this.username) {
      this.auth.get_user_by_username(this.username, this.recaptcha).subscribe(success => {
        this.otp_sent = true;
        console.log(success);
        this.otp_id = success['data']['otp_id'];
        this.mobile = success['data']['mobile'];
        this.otp_button_text = 'Send OTP Again';
        this.alertService.success(success['message']);
        this.spinnerService.hide();
      }, error => {
        this.alertService.danger('Enter Username does not exist');
        this.spinnerService.hide();
      });
    }
  }
  reset_password() {
    this.spinnerService.show();
    if (this.password !== this.retype_password) {
      this.alertService.danger('Re-type password does not match');
      this.spinnerService.hide();
    } else {
      this.auth.reset_password(this.username, this.password, this.otp_code, this.otp_id).subscribe(success => {
        console.log(success);
        this.spinnerService.hide();
        this.alertService.success('Password successfully reset');
        this.reset = true;
      }, error => {
        this.alertService.danger(error.error.message);
        this.spinnerService.hide();
      });
    }
  }

  login() {
    this.router.navigate(['login']);
  }

}
