import { DocumentEditModle } from "./../../../models/DocumentEditModle";
import { DocumentModle } from "./../../../models/DocumentModle";
import { CurrencyPipe } from "@angular/common";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { fileDetailsModle } from "./../../../models/fileDetailsModle";
import { CourseService } from "./../../../services/course.service";
import { AlertService } from "ngx-alerts";
import { SessionModle } from "./../../../models/SessionModle";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Component,
  OnInit,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  UploadEvent,
  UploadFile,
} from "ngx-file-drop";

declare var $: any;
@Component({
  selector: "course-document-modal",
  templateUrl: "./course-document-modal.component.html",
  styleUrls: ["./course-document-modal.component.css"],
})
export class CourseDocumentModalComponent implements OnInit {
  @Input() documentVal: DocumentModle;
  @Input() documentModle: DocumentModle;
  @Output() changeDocuments = new EventEmitter();

  modelTitle;
  enableStatus;
  disableStatus;
  dropFileStatus = false;
  dropFileError;
  visibleTypes = ["Paid", "Free", "Paid for Class"];
  visibleTypeValue = "Paid for Class";
  price: number;
  public files: UploadFile[] = [];
  fileRef: fileDetailsModle;
  showspinner = false;
  addButton = false;
  editButton = false;

  documentId;
  updateModle: DocumentEditModle;

  form = new FormGroup({
    documentTitle: new FormControl("", Validators.required),

    amount: new FormControl("", Validators.required),
    enableDate: new FormControl(
      { value: "", disabled: true },
      Validators.required
    ),
    enableTime: new FormControl(
      { value: "", disabled: true },
      Validators.required
    ),
    disableDate: new FormControl(
      { value: "", disabled: true },
      Validators.required
    ),
    disableTime: new FormControl(
      { value: "", disabled: true },
      Validators.required
    ),
  });
  constructor(
    private courseSer: CourseService,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.spinnerService.hide();

    if (!this.documentVal) {
      this.modelTitle = "Add Document";
      this.documentTitleRef.setValue("");
      this.fileRef = null;
      this.dropFileStatus = false;
      this.visibleTypeValue = "Paid for Class";
      this.amountRef.setValue("");
      this.amountRef.disable();
      this.enableStatus = false;
      this.enableDateRef.setValue("");
      this.enableTimeRef.setValue("");
      this.enableDateRef.disable();
      this.enableTimeRef.disable();
      this.disableStatus = false;
      this.disableDateRef.setValue("");
      this.disableTimeRef.setValue("");
      this.disableDateRef.disable();
      this.disableTimeRef.disable();
      this.addButton = true;
      this.editButton = false;
    } else {
      this.modelTitle = "Edit Document";
      this.updateModle = new DocumentEditModle(
        this.documentVal._id,
        this.documentVal.vertical_loc,
        this.documentVal.horizontal_loc,
        this.documentVal.show
      );
      this.getdocument(this.documentVal._id);
      this.documentTitleRef.setValue(this.documentVal.name);
      this.visibleTypeValue = this.documentVal.visible_type;

      this.enableStatus = this.documentVal.enable_from.active;

      if (this.enableStatus) {
        this.enableDateRef.setValue(
          new Date(this.documentVal.enable_from.date)
        );
        this.enableTimeRef.setValue(
          new Date(this.documentVal.enable_from.date)
        );
        this.enableDateRef.enable();
        this.enableTimeRef.enable();
      } else {
        this.enableDateRef.setValue("");
        this.enableTimeRef.setValue("");
        this.enableDateRef.disable();
        this.enableTimeRef.disable();
      }
      this.disableStatus = this.documentVal.disable_from.active;

      if (this.disableStatus) {
        this.disableDateRef.setValue(
          new Date(this.documentVal.disable_from.date)
        );
        this.disableTimeRef.setValue(
          new Date(this.documentVal.disable_from.date)
        );
        this.disableDateRef.enable();
        this.disableTimeRef.enable();
      } else {
        this.disableDateRef.setValue("");
        this.disableTimeRef.setValue("");
        this.disableDateRef.disable();
        this.disableTimeRef.disable();
      }
      this.dropFileStatus = true;
      this.addButton = false;
      this.editButton = true;
    }
  }

  get documentTitleRef() {
    return this.form.get("documentTitle");
  }

  get amountRef() {
    return this.form.get("amount");
  }
  get enableDateRef() {
    return this.form.get("enableDate");
  }
  get enableTimeRef() {
    return this.form.get("enableTime");
  }
  get disableDateRef() {
    return this.form.get("disableDate");
  }
  get disableTimeRef() {
    return this.form.get("disableTime");
  }

  getdocument(id) {
    this.spinnerService.show();
    this.courseSer.getDocument(id).subscribe(
      (response) => {
        this.spinnerService.hide();
        if (this.visibleTypeValue.toLowerCase() == "paid") {
          this.amountRef.setValue(response["data"].product_info.amount);
          this.amountRef.enable();
        }

        this.fileRef = response["data"].file_info[0].file_id;
        this.updateModle.prev_amount = response["data"].product_info.amount;
        this.updateModle.product_id = response["data"].product_info._id;
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.danger("Error during load.");
        $("#documentModal").modal("hide");
      }
    );
  }

  public dropped(event: UploadEvent) {
    this.files = event.files;
    if (this.files.length != 1) {
      this.alertService.danger("Don't upload multiple files.");
      this.files = [];
      this.dropFileStatus = false;
      return;
    }
    this.showspinner = true;
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.courseSer.uploadFile(file).subscribe(
            (respons) => {
              this.dropFileStatus = true;
              this.showspinner = false;
              this.fileRef = respons["data"].file;
            },
            (error) => {
              this.showspinner = false;

              this.files = [];
              this.dropFileStatus = false;
              this.alertService.danger("Error occurred while uploading.");
            }
          );
        });
      } else {
        this.showspinner = false;
        this.files = [];
        this.dropFileStatus = false;
        this.alertService.danger("empty directories are added.");
      }
    }
  }

  deleteItem(item) {
    this.spinnerService.show();
    this.courseSer.deleteFile(this.fileRef._id).subscribe(
      (response) => {
        this.spinnerService.hide();

        this.fileRef = null;
        this.dropFileStatus = false;
        this.files = [];
      },
      (error) => {
        this.spinnerService.hide();

        this.alertService.danger("Error occurred while deleting.");
      }
    );
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  changeVisibleType(item) {
    this.visibleTypeValue = item;
    if (item.toLowerCase() == "paid") {
      this.amountRef.enable();
      return true;
    } else {
      this.amountRef.setValue("");
      this.amountRef.disable();
      return false;
    }
  }

  clearAmount() {
    this.amountRef.setValue("");
  }
  transformAmount() {
    // /\d+/g
    if (this.amountRef.value.match(/^\d*\.?(?:\d*)?$/) == null) {
      this.amountRef.setValue("");
      this.price = null;
      return;
    }
    this.transform(this.amountRef.value);
  }
  transform(value) {
    this.price = value;
    const formattedAmount = this.currencyPipe.transform(value, "Rs ");
    this.amountRef.setValue(formattedAmount);
  }

  togleEnableDate() {
    if (this.enableStatus) {
      this.enableDateRef.setValue("");
      this.enableTimeRef.setValue("");
      this.enableDateRef.disable();
      this.enableTimeRef.disable();
      this.enableStatus = !this.enableStatus;
    } else {
      this.enableDateRef.enable();
      this.enableTimeRef.enable();
      this.enableStatus = !this.enableStatus;
    }
  }
  togleDisableDate() {
    if (this.disableStatus) {
      this.disableDateRef.setValue("");
      this.disableTimeRef.setValue("");
      this.disableDateRef.disable();
      this.disableTimeRef.disable();
      this.disableStatus = !this.disableStatus;
    } else {
      this.disableDateRef.enable();
      this.disableTimeRef.enable();
      this.disableStatus = !this.disableStatus;
    }
  }

  submitDocument() {
    this.spinnerService.show();
    let data;
    if (!this.documentVal) {
      this.documentModle.name = this.documentTitleRef.value;
      this.documentModle.visible_type = this.visibleTypeValue;
      if (this.visibleTypeValue.toLowerCase() == "paid") {
        this.documentModle.amount = this.price;
      }
      this.documentModle.vertical_loc += 1;

      if (this.enableStatus) {
        this.documentModle.enable_from.active = this.enableStatus;
        this.documentModle.enable_from.date = new Date(
          this.enableDateRef.value.toDateString() +
            " " +
            this.enableTimeRef.value.toLocaleTimeString() +
            " GMT+0530"
        );
      }

      if (this.disableStatus) {
        this.documentModle.disable_from.active = this.disableStatus;
        this.documentModle.disable_from.date = new Date(
          this.disableDateRef.value.toDateString() +
            " " +
            this.disableTimeRef.value.toLocaleTimeString() +
            " GMT+0530"
        );
      }
      this.documentModle.files[0] = this.fileRef._id;

      this.courseSer.uploadDocument(this.documentModle).subscribe(
        (response) => {
          data = response["data"];

          this.spinnerService.hide();
          this.changeDocuments.emit(data);
        },
        (error) => {
          this.spinnerService.hide();
          this.alertService.danger("Error occurred while Uploading Document");

          data = "error";
          this.changeDocuments.emit(data);
        }
      );
    } else {
      this.updateModle.name = this.documentTitleRef.value;
      this.updateModle.visible_type = this.visibleTypeValue;
      if (this.visibleTypeValue.toLowerCase() == "paid") {
        this.updateModle.amount = this.price;
      }

      if (this.enableStatus) {
        this.updateModle.enable_from.active = this.enableStatus;
        this.updateModle.enable_from.date = new Date(
          this.enableDateRef.value.toDateString() +
            " " +
            this.enableTimeRef.value.toLocaleTimeString() +
            " GMT+0530"
        );
      } else {
        this.updateModle.enable_from.active = this.enableStatus;
        this.updateModle.enable_from.date = new Date();
      }

      if (this.disableStatus) {
        this.updateModle.disable_from.active = this.disableStatus;
        this.updateModle.disable_from.date = new Date(
          this.disableDateRef.value.toDateString() +
            " " +
            this.disableTimeRef.value.toLocaleTimeString() +
            " GMT+0530"
        );
      } else {
        this.updateModle.disable_from.active = this.disableStatus;
        this.updateModle.disable_from.date = new Date();
      }

      this.updateModle.files[0] = this.fileRef._id;

      ///
      this.courseSer
        .updateDocument(this.documentVal._id, this.updateModle)
        .subscribe(
          (response) => {
            data = response["data"];
            this.spinnerService.hide();
            this.changeDocuments.emit(data);
          },
          (error) => {
            this.spinnerService.hide();
            this.alertService.danger("Error occurred while Uploading Document");

            data = "error";
            this.changeDocuments.emit(data);
          }
        );
    }
  }
}
