export class AssignmentEditModle {
  component_id: string;
  product_id: string;
  visible_type: string;
  prev_amount: number;
  amount: number;
  vertical_loc: Number;
  horizontal_loc: number;
  show: boolean;
  enable_from: DateObject;
  disable_from: DateObject;
  allow_date: Date;
  due_date: Date;
  submission_type: string;
  description: string;
  name: string;
  files: string[];

  constructor(componentId, verticalLoc, horizontalLoc, show) {
    this.component_id = componentId;
    this.vertical_loc = verticalLoc;
    this.amount = 0;
    this.horizontal_loc = horizontalLoc;
    this.show = show;
    this.enable_from = new DateObject();
    this.disable_from = new DateObject();
    this.files = [];
  }
}

class DateObject {
  active: boolean;
  date: Date;
  constructor() {
    this.active = false;
    this.date = new Date();
  }
}
