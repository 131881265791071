import { Component, OnInit } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { AuthService } from 'src/app/services/auth.service';
import { ClassService } from 'src/app/services/class.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss']
})
export class BasicInformationComponent implements OnInit {

  batches = [];
  subjects = [];
  teachers = [];
  streams = [];
  subjects_desc;
  role;
  user_id = '';
  student;
  info_completed = true;
  student_name = '';
  student_personal_info = {
    first_name: '',
    middle_name: '',
    last_name: '',
    birthday: '',
    gender: '',
    address: {lines: '', city: ''},
    email: ''
  };
  student_class_info = {
    admission_batch: '',
    stream: ''
  };
  teacher_personal_info = {
    firstName: '',
    lastName: '',
    gender: 'MALE',
    address: {lines: '', city: ''},
    email: '',
    birthday: '',
    nic: ''
  };
  teaching_info = {
    description: '',
    bookable: true,
    mbt: undefined,
    subjects: []
  };

  constructor(private alertService: AlertService,
              private auth: AuthService,
              private classSer: ClassService,
              private spinnerService: Ng4LoadingSpinnerService,
              private router: Router) { }

  ngOnInit() {
    this.role = this.getDecodedAccessToken(localStorage.getItem('token'))['role'];
    this.user_id = this.getDecodedAccessToken(localStorage.getItem('token'))['user_id'];
    this.getBatch();
    this.getStreams();
    this.getTeachers();
    this.getSubjects();
    this.getStudent();
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  getBatch() {
    this.batches = [];
    this.spinnerService.show();
    this.classSer.getVisibleBatches().subscribe(data => {
      this.spinnerService.hide();
      for (let i = 0; i < data['data'].length; i++) {
        this.batches.push({_id: data['data'][i]._id, value: data['data'][i].year + ' ' + data['data'][i].category});
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Batches');
    });
  }

  getStudent() {
    this.spinnerService.show();
    this.auth.getBasicStudentProfile(this.user_id).subscribe(data => {
      this.spinnerService.hide();
      this.student_personal_info.first_name = data['data']['first_name'];
      this.student_personal_info.last_name = data['data']['last_name'];
      this.student_personal_info.gender = data['data']['gender'];
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Batches');
    });
  }

  getTeachers() {
    this.teachers = [];
    this.spinnerService.show();
    this.auth.getTeachers().subscribe(data => {
      this.spinnerService.hide();
      for (let i = 0; i < data['data'].length; i++) {
        this.teachers.push({_id: data['data'][i]._id, value: data['data'][i].first_name + ' ' + data['data'][i].last_name});
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Teachers');
    });
  }
  getSubjects() {
    this.subjects = [];
    this.classSer.getSubjects().subscribe(data => {
      for (let i = 0; i < data['data'].length; i++) {
        data['data'][i].isChecked = false;
        this.subjects.push(data['data'][i]);
      }
    }, error => {
      console.log(error);
    });
  }
  getStreams() {
    this.streams = [];
    this.spinnerService.show();
    this.classSer.getStreams().subscribe(data => {
      this.spinnerService.hide();
      for (let i = 0; i < data['data'].length; i++) {
        this.streams.push({_id: data['data'][i]._id, value: data['data'][i].stream});
      }
    }, error => {
      this.spinnerService.hide();
      this.alertService.danger('Error occurred while getting Subjects');
    });
  }

  changeSelection() {
    this.teaching_info.subjects = [];
    this.subjects.forEach((value, index) => {
      if (value.isChecked) {
        this.teaching_info.subjects.push(value._id);
      }
    });
  }
  go_to_next() {
    this.spinnerService.show();
    if (this.role === 'student') {
      if (this.student_personal_info.first_name === '' || this.student_personal_info.last_name === ''
        || this.student_personal_info.birthday === '' || this.student_personal_info.gender === ''
        || this.student_class_info.admission_batch === '' || this.student_class_info.stream === ''
        || this.student_personal_info.address.lines === '' || this.student_personal_info.address.city === '') {
        this.alertService.danger('Information is Incomplete. \nPlease fill all the required fields');
        this.spinnerService.hide();
      } else {
        this.auth.updateStudentByUserId(this.student_personal_info, this.student_class_info).subscribe(success => {
          this.alertService.success('Information Successfully Updated');
          localStorage.setItem('registered', true.toString());
          this.router.navigate(['studentDashboard']);
          this.spinnerService.hide();
        }, error => {
          this.alertService.danger(error.error.message);
          this.spinnerService.hide();
        });
      }
    } else if (this.role === 'teacher') {
      if ((this.teacher_personal_info.firstName !== ''
        && this.teacher_personal_info.lastName !== '' &&
        this.teacher_personal_info.address.lines !== '' &&
        this.teacher_personal_info.address.city !== '' &&
        this.teacher_personal_info.birthday !== '' &&
        this.teacher_personal_info.nic !== '' &&
        this.subjects_desc !== '') &&
        ((this.teaching_info.bookable && this.teaching_info.mbt !== undefined) || (!this.teaching_info.bookable))) {
        this.spinnerService.show();
        this.teaching_info.description = this.teaching_info.description + '\nSubjects: ' + this.subjects_desc;
        this.auth.updateTeacherByUserId(this.teacher_personal_info, this.teaching_info).subscribe(success => {
          this.spinnerService.hide();
          this.alertService.success('Information Updated Successfully');
          this.router.navigate(['dashboard']);
        }, error => {
          this.spinnerService.hide();
          if (error.status === 401) {
            this.router.navigate(['login']);
          }
          this.alertService.danger(error.error.message);
        });
      } else {
        this.alertService.danger('Fill All the Required Fields');
      }
    }
  }
}
