import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ClassService } from "src/app/services/class.service";
import { AlertService } from "ngx-alerts";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { url } from "../../../environments/url";
// import * as jwt_decode from 'jwt-decode';
//
// import { ZoomMtg } from "@zoomus/websdk";
//
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();
//
@Component({
  selector: "app-course-page",
  templateUrl: "./course-page.component.html",
  styleUrls: ["./course-page.component.css"],
})
export class CoursePageComponent implements OnInit {
  // apiKey = "eU2lpQ8RQtuifCzVQ-_hGA";
  // meetingNumber = 5950455289
  // ;
  // role = 0;
  // leaveUrl = 'http://localhost:4200/classes/course/meetingPage'
  // // leaveUrl = url + "/classes/course/meetingPage";
  // userName = '';
  // userEmail = '';
  // passWord = '1234';
  constructor(
    @Inject(DOCUMENT) document,
    private alertService: AlertService,
    private spinnerService: Ng4LoadingSpinnerService,
    private classSer: ClassService
  ) {}

  ngOnInit() {
    // this.userName = this.getDecodedAccessToken(localStorage.getItem('token'))['first_name'];
  }
  // getDecodedAccessToken(token: string): any {
    // try {
    //   return jwt_decode(token);
    // } catch (Error) {
    //   return null;
    // }
  // }
  // getSignature() {
    // this.classSer.getZoomSignature(this.meetingNumber, this.role).subscribe(
    //   (data) => {
    //     if (data["signature"]) {
    //       console.log(data["signature"]);
    //       this.startMeeting(data["signature"]);
    //     } else {
    //       this.alertService.danger("Could Not Join the Class");
    //       console.log(data);
    //     }
    //   },
    //   (error) => {
    //     this.alertService.danger("Could Not Join the Class");
    //     console.log(error);
    //   }
    // );
  // }

  // startMeeting(signature) {
  //   let x = (document.getElementById('zmmtg-root').style.display = 'block');
  //
  //   ZoomMtg.init({
  //     leaveUrl: this.leaveUrl,
  //     isSupportAV: true,
  //     success: (success) => {
  //       console.log(success);
  //
  //       ZoomMtg.join({
  //         signature: signature,
  //         meetingNumber: this.meetingNumber,
  //         userName: this.userName,
  //         apiKey: this.apiKey,
  //         userEmail: this.userEmail,
  //         passWord: this.passWord,
  //         success: (success) => {
  //           console.log(success);
  //         },
  //         error: (error) => {
  //           this.alertService.danger(error.errorMessage);
  //           console.log(error);
  //         },
  //       });
  //     },
  //     error: (error) => {
  //       this.alertService.danger(error.errorMessage);
  //       console.log(error);
  //     },
  //   });
  // }
}
